import * as React from 'react'
import { Alert, ImageBackground, StyleSheet, View } from 'react-native'
import { AlertBox } from '../../../ui/alertbox/AlertBox'
import { ButtonSet } from '../../../ui/buttons/ButtonSet/ButtonSet'
import { ButtonSetItem } from '../../../ui/buttons/ButtonSetItem/ButtonSetItem'
import { Button } from '../../../ui/buttons/Button/Button'
import { ButtonVariant } from '../../../ui/buttons/ButtonVariant/ButtonVariant'
import { Modal } from '../../../ui/modal/Modal'
import { useTranslation } from '../../../../hooks/useTranslation'
import { Theme } from '../../../ui/themes/Theme'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { CancellationsBox } from '../../../common/CancellationsBox/CancellationsBox'
import { GameApi } from '../../../../modules/game/GameApi'
import { Loader } from '../../../common/Loader/Loader'
import { GameCancelInfo } from '../../../../modules/game/GameCancelInfo'
import { GameScreenHeader } from '../../../common/GameScreenParts/GameScreenHeader'
import { Game } from '../../../../modules/game/Game'
import { defaultTheme } from '../../../ui/themes/default/defaultTheme'

interface Props {
  visible: boolean
  gameId: string
  teamId: string
  game?: Game
  onRequestClose: () => void
  onGameCancelSuccess: () => void
}

export function CancelGameModal({
  visible,
  gameId,
  game,
  teamId,
  onRequestClose,
  onGameCancelSuccess,
}: Props) {
  const theme = defaultTheme
  const styles = getStyles(theme)
  const { trans } = useTranslation()

  const [cancelInfo, setCancelInfo] = React.useState<GameCancelInfo | null>(
    null
  )
  React.useEffect(() => {
    if (visible) {
      setCancelInfo(null)
      GameApi.getGameCancelStatus(gameId, teamId)
        .then((info: GameCancelInfo) => {
          setCancelInfo(info)
        })
        .catch((error) => {
          Alert.alert('Error', error.message)
        })
    }
  }, [gameId, teamId, visible])

  const [cancelling, setCancelling] = React.useState(false)
  const cancelGame = React.useCallback(() => {
    setCancelling(true)
    GameApi.cancelGame(gameId, teamId)
      .then((result) => {
        console.log(`GameApi.cancelGame result`, result)
        setCancelling(false)
        onGameCancelSuccess()
      })
      .catch((error) => {
        setCancelling(false)
        Alert.alert('Error', error.message, [
          { text: 'OK' },
          { text: 'Reintentar', onPress: cancelGame },
        ])
      })
  }, [gameId, onGameCancelSuccess, teamId])

  return (
    <Modal
      style={{
        justifyContent: 'center',
        backgroundColor: theme.colors.greenDark,
      }}
      visible={visible}
      onRequestClose={onRequestClose}
    >
      {cancelInfo === null ? (
        <View style={styles.containerLoading}>
          <Loader />
        </View>
      ) : (
        <View>
          <ImageBackground
            style={{
              aspectRatio: 830 / 1792,
            }}
            source={require('../../../../assets/images/background.png')}
          >
            {game ? (
              <GameScreenHeader
                club={game.club}
                date={game.date}
                team1={game.team1}
                team2={game.team2}
              />
            ) : null}
            <View style={styles.alertBox}>
              <AlertBox
                type={'warning'}
                title={trans('screens.gameScreen.cancel_modal_title')}
                description={cancelInfo.message}
              />
            </View>

            <View style={styles.cancellations}>
              <CancellationsBox
                count={cancelInfo.cancellationsCount}
                max={cancelInfo.maxCancellations}
              />
            </View>

            <ButtonSet>
              {cancelling ? (
                <Loader />
              ) : (
                <View
                  style={{
                    flexDirection: 'row',
                  }}
                >
                  <ButtonSetItem>
                    <Button variant={ButtonVariant.cancel} onPress={cancelGame}>
                      {trans('screens.gameScreen.Cancel game')}
                    </Button>
                  </ButtonSetItem>

                  <ButtonSetItem>
                    <Button
                      variant={ButtonVariant.default}
                      onPress={onRequestClose}
                    >
                      {trans('general.Go back')}
                    </Button>
                  </ButtonSetItem>
                </View>
              )}
            </ButtonSet>
          </ImageBackground>
        </View>
      )}
    </Modal>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    containerLoading: {
      height: 480,
    },
    alertBox: {
      backgroundColor: theme.colors.greenDark,
      borderColor: '#FC7258',
      borderWidth: 2,
      borderRadius: 25,
    },
    cancellations: {
      marginBottom: 0,
    },
  })
