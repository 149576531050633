import * as React from 'react'
import { TextInput, TextInputProps } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { ForwardedRef } from 'react'
import { defaultTheme } from '../themes/default/defaultTheme'

export enum InputTypes {
  text = 'text',
  password = 'password',
  email = 'email',
}

interface InputPropsType extends TextInputProps {
  type?: InputTypes
  center?: boolean
}

export const CustomTextInput = React.forwardRef(
  (props: InputPropsType, ref: ForwardedRef<TextInput>) => {
    const { type, center } = props

    // let newValue: string

    const theme = defaultTheme
    const fontSize = theme.input.fontSize

    return (
      <TextInput
        {...props}
        ref={ref}
        secureTextEntry={type === InputTypes.password}
        placeholderTextColor={theme.input.placeholderColor}
        style={{
          // If we update this style we may also need to update PasswordTextInput styles too
          backgroundColor: theme.input.backgroundColor,
          color: theme.input.color,
          paddingVertical: fontSize,
          paddingHorizontal: fontSize * 2,
          width: '100%',
          fontSize: fontSize,
          fontWeight: 'bold',
          borderWidth: 1,
          borderColor: theme.input.borderColor,
          borderRadius: fontSize * 2,
          textAlign: center ? 'center' : 'auto',
          // ...validate.styleCheckValueInit(value)
        }}
        // onChange={event => {
        //   newValue = event.nativeEvent.text
        // }}
        // onEndEditing={() => onChangeText ? onChangeText(newValue || '') : undefined}
      />
    )
  }
)
