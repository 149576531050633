import * as React from 'react'
import { SafeAreaView, ScrollView, StyleSheet } from 'react-native'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { Accordion, Container } from '../../ui/ui'
import faqsContent from '../../../data/dummy/faqs.json'
import { ScreenTitle } from '../../common/ScreenTitle/ScreenTitle'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'

translationService.addTranslations(
  'screens.faqs',
  require('./FaqsScreen.i18n.json')
)

type Props = MainStackScreenProps<'FaqsScreen'>

export function FaqsScreen({}: Props) {
  const { trans } = useTranslation()

  return (
    <SafeAreaView>
      <ScrollView>
        <Container style={styles.container}>
          <ScreenTitle
            title="FAQ'S"
            subtitle={trans('screens.faqs.Frequent asked questions')}
          />

          <Accordion sections={faqsContent} />
        </Container>
      </ScrollView>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
  },
})
