import * as React from 'react'
import {
  ColorValue,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { MainStackScreenNavigationProp } from '../../../navigation/MainStackNavigator'
import { Game } from '../../../modules/game/Game'
import { Theme } from '../../ui/themes/Theme'
import { Team } from '../../../modules/team/Team'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { TeamsVersus } from '../TeamsVersus/TeamsVersus'
import { Button, H1, P } from '../../ui/ui'
import { useNavigation } from '@react-navigation/native'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { TeamScore } from '../TeamScore/TeamScore'
import { dateToHuman, timeToHuman } from '../../../support/dates'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { MeContext } from '../../../contexts/me/MeContext'
import { ClubImage } from '../ClubImage/ClubImage'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations('gameCard', require('./GameCard.i18n.json'))

interface Props {
  game: Game
  highlightedTeamId?: string
}

export const GameCard = ({ game, highlightedTeamId }: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme, game, highlightedTeamId)
  console.log('styles', styles)
  const { navigate } =
    useNavigation<MainStackScreenNavigationProp<'MyGamesScreen'>>()

  const { trans } = useTranslation()

  const defineColorForTeamScore = (team?: Team): ColorValue | undefined => {
    let colorToApply = theme.colors.grey
    if (highlightedTeamId && team && team.id === highlightedTeamId) {
      colorToApply =
        game.winner()?.id === highlightedTeamId
          ? theme.colors.win
          : theme.colors.lose
    }
    return colorToApply
  }

  return (
    <TouchableOpacity
      style={styles.container}
      activeOpacity={0.5}
      onPress={() => navigate('GameScreen', { gameId: game.id })}
    >
      <View />
      <View style={styles.top}>
        {!game.isToday() && (
          <H1
            style={{
              color: theme.colors.greenDark,
              fontSize: 20,
              flex: 1,
              textAlign: 'center',
            }}
          >
            {dateToHuman(game.date)} - {timeToHuman(game.date)}
          </H1>
        )}
        {game.isToday() && (
          <H1
            style={{
              color: theme.colors.greenDark,
              fontSize: 20,
              flex: 1,
              textAlign: 'center',
            }}
          >
            {trans('gameCard.Today')} {timeToHuman(game.date)}
          </H1>
        )}
      </View>

      <ClubImage style={{ bottom: 5 }} size={50} club={game.club} />

      <View style={styles.teamVersusContainer}>
        <TeamsVersus team1={game.team1} team2={game.team2} showNames />
      </View>

      {game.isPast() && game.score && (
        <View style={styles.score}>
          <TeamScore
            score={game.score.team1}
            fontSize={32}
            color={defineColorForTeamScore(game.team1)}
          />
          <TeamScore
            score={game.score.team2}
            fontSize={32}
            color={defineColorForTeamScore(game.team2)}
          />
        </View>
      )}

      {!game.isPast() && (
        <View style={styles.bottom}>
          {/*<P style={styles.club}>{game.club}</P>*/}
          <View style={styles.preview}>
            <P
              center
              style={{ paddingVertical: 10, color: theme.colors.primary }}
            >
              {game.isCancelled()
                ? trans('gameCard.Cancelled').toUpperCase()
                : trans('gameCard.Preview').toUpperCase()}
            </P>
          </View>
        </View>
      )}
    </TouchableOpacity>
  )
}

const getStyles = (theme: Theme, game: Game, highlightedTeamId?: string) =>
  StyleSheet.create({
    container: {
      borderColor: theme.colors.primary,
      borderWidth: 2,
      paddingVertical: 15,
      paddingHorizontal: 17,
      borderRadius: 24,
      backgroundColor: theme.colors.greenDark,
    },
    top: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor:
        game.isPast() && game.winner() && highlightedTeamId
          ? game.winner()!.id === highlightedTeamId
            ? theme.colors.win
            : theme.colors.lose
          : game.isCancelled()
          ? theme.colors.lose
          : theme.colors.primary,
      borderBottomWidth: 2,
      borderColor: theme.colors.primary,
      margin: -18,
      borderTopLeftRadius: 24,
      borderTopRightRadius: 24,
      marginBottom: 20,
      padding: 10,
      paddingTop: 15,
      paddingBottom: 0,
    },
    date: {
      color: theme.colors.greenDark,
      fontSize: 18,
      textAlign: 'center',
      flex: 1,
      fontFamily: 'Syne_800ExtraBold',
    },
    teamVersusContainer: {
      marginBottom: 15,
    },
    score: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginHorizontal: 20,
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 5,
    },
    preview: {
      flex: 1,
      borderRadius: 25,
      borderColor: theme.colors.primary,
      borderWidth: 1,
    },
    club: {
      color: theme.text.colorGrey,
    },
  })
