import * as React from 'react'
import {
  GestureResponderEvent,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import { ButtonVariant } from '../ButtonVariant/ButtonVariant'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { Loader } from '../../../common/Loader/Loader'
import { defaultTheme } from '../../themes/default/defaultTheme'

interface Props {
  variant?: ButtonVariant
  children: React.ReactNode
  onPress: (event: GestureResponderEvent) => void
  size?: 'sm' | 'md'
  width?: number | string
  disabled?: boolean
  loading?: boolean
  opacity?: number
}

export const Button = ({
  variant = ButtonVariant.default,
  onPress,
  children,
  size = 'md',
  width,
  disabled = false,
  loading,
  opacity,
}: Props) => {
  const theme = defaultTheme

  const fontSize =
    size === 'sm' ? theme.button.fontSize * 0.8 : theme.button.fontSize

  const disabledCalc: boolean = disabled === true || loading === true

  return (
    <TouchableOpacity
      activeOpacity={opacity ?? disabledCalc ? 1 : 0.5}
      onPress={disabledCalc ? undefined : onPress}
      disabled={disabled}
    >
      <View
        style={{
          backgroundColor: theme.button[variant].backgroundColor,
          paddingVertical: size === 'sm' ? fontSize * 0.5 : fontSize * 1.1,
          paddingHorizontal: fontSize * 1.5,
          width: width || 'auto',
          minWidth: 130,
          borderColor: theme.button[variant].borderColor,
          borderRadius: fontSize * 2,
          borderWidth: 1,
          alignSelf: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          opacity: opacity ?? disabledCalc ? 0.7 : 1,
        }}
      >
        <Text
          style={{
            fontFamily: theme.text.fontPrimaryBold,
            alignSelf: 'center',
            fontSize: fontSize,
            color: theme.button[variant].color,
          }}
        >
          {children}
        </Text>
        {loading === true && (
          <View style={{ marginLeft: fontSize * 0.7 }}>
            <Loader color={theme.button[variant].color} size={fontSize} />
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}
