import * as React from 'react'
import {
  Dimensions,
  Image,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { Theme } from '../../ui/themes/Theme'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { TeamsVersus } from '../TeamsVersus/TeamsVersus'
import { Button, H1 } from '../../ui/ui'
import { MainStackScreenNavigationProp } from '../../../navigation/MainStackNavigator'
import { useNavigation } from '@react-navigation/native'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { dateToHuman, isToday, timeToHuman } from '../../../support/dates'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { parseISO } from 'date-fns'
import { LastMinute } from '../../../modules/lastMinuteMatch/LastMinute'
import { ClubImage } from '../ClubImage/ClubImage'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'lastMinuteMatchCard',
  require('./LastMinuteMatchCard.i18n.json')
)

interface Props {
  lastMinuteMatch: LastMinute
}

export const LastMinuteMatchCard = ({ lastMinuteMatch }: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  const { navigate } =
    useNavigation<MainStackScreenNavigationProp<'LastMinuteMatchScreen'>>()

  const { trans } = useTranslation()

  return (
    <TouchableOpacity
      style={styles.container}
      activeOpacity={0.5}
      onPress={() =>
        navigate('LastMinuteMatchScreen', {
          lastMinuteMatchId: lastMinuteMatch.id,
        })
      }
    >
      <View style={styles.top}>
        {!isToday(parseISO(lastMinuteMatch.scheduled_on)) && (
          <H1 style={styles.date}>
            {dateToHuman(parseISO(lastMinuteMatch.scheduled_on))} -{' '}
            {timeToHuman(parseISO(lastMinuteMatch.scheduled_on))}
          </H1>
        )}
        {isToday(parseISO(lastMinuteMatch.scheduled_on)) && (
          <H1 style={{ ...styles.date }}>
            {trans('gameCard.Today')}{' '}
            {timeToHuman(parseISO(lastMinuteMatch.scheduled_on))}
          </H1>
        )}
      </View>

      <ClubImage style={{}} size={50} club={lastMinuteMatch.club} />

      <View style={styles.teamVersusContainer}>
        <TeamsVersus
          team1={undefined}
          team2={
            lastMinuteMatch?.first_team ??
            lastMinuteMatch?.second_team ??
            undefined
          }
          showNames
        />
      </View>

      <View style={styles.bottom}>
        <Button
          width={Dimensions.get('window').width - 80}
          onPress={() =>
            navigate('LastMinuteMatchScreen', {
              lastMinuteMatchId: lastMinuteMatch.id,
            })
          }
          variant={ButtonVariant.primary}
          size="md"
        >
          ¡ME APUNTO!
        </Button>
      </View>
    </TouchableOpacity>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      borderWidth: 2,
      paddingVertical: 15,
      paddingHorizontal: 17,
      borderRadius: 25,
      backgroundColor: theme.colors.greenDark,
      borderColor: theme.colors.primary,
    },
    top: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderBottomWidth: 2,
      borderColor: theme.colors.primary,
      margin: -18,
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
      marginBottom: 25,
      padding: 10,
      paddingBottom: -10,
      paddingTop: 15,
      backgroundColor: theme.colors.primary,
    },
    date: {
      color: theme.colors.greenDark,
      fontSize: 20,
      textAlign: 'center',
      flex: 1,
    },
    teamVersusContainer: {
      marginBottom: 30,
    },
    score: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginHorizontal: 20,
    },
    bottom: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    site: {
      color: theme.text.colorGrey,
    },
  })
