import * as React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { LeagueGroup } from '../../../modules/league/LeagueGroup'
import { Container } from '../../ui/ui'
import { RankingTable } from './partials/RankingTable'
import { useLeagueGroupRanking } from '../../../modules/league/useLeagueGroupRanking'
import { LeagueGroupRanking } from '../../../modules/league/LeagueGroupRanking'
import { LoadIndicator } from '../../common/Loader/Loader'
import { Theme } from '../../ui/themes/Theme'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { MeContext } from '../../../contexts/me/MeContext'
import { LeagueGroupSelector } from '../../common/LeagueGroupSelector/LeagueGroupSelector'
import { NoTeamSelectedAlert } from '../../common/NoTeamSelectedAlert/NoTeamSelectedAlert'
import { Team } from '../../../modules/team/Team'
import { LinearGradient } from 'expo-linear-gradient'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

type Props = MainStackScreenProps<'RankingScreen'>

export function RankingScreen({}: Props) {
  const { currentTeam, teams: myTeams } = React.useContext(MeContext)
  const theme = defaultTheme

  if (!currentTeam || !myTeams) {
    return (
      <View style={{ flex: 1, justifyContent: 'center' }}>
        <LinearGradient
          colors={[
            'white',
            'white',
            'white',
            theme.background.color.toString(),
            theme.background.color.toString(),
            theme.background.color.toString(),
          ]}
          style={{
            position: 'absolute',
            height: '120%',
            width: '120%',
          }}
        />
        <NoTeamSelectedAlert />
      </View>
    )
  }

  console.log(`RankingScreen - currentTeam`, currentTeam)

  return <ScreenContent currentTeam={currentTeam} myTeams={myTeams} />
}

type ScreenContentProps = {
  currentTeam: Team
  myTeams: Team[]
}

// POST /groups response:
// "league": {
//   "id": "c4dc3e1d-20f8-44ae-92d3-aa2cd6605b2f",
//   "name": "Liga Otoño 21",
//   "categories": [
//     {
//       "id": "40f3ec5e-ef0c-11eb-ae27-0242ac130003",
//       "name": "masculino",
//       "groups": [
//         {
//           "id": "0d5b0c9d-a37a-4624-b26b-156dada047df",
//           "name": "A1"
//         },

function ScreenContent({ currentTeam, myTeams }: ScreenContentProps) {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const [dropdownVisible, setDropdownVisible] = React.useState(false)

  const [selectedGroup, setSelectedGroup] = React.useState<LeagueGroup>(
    currentTeam.leagueGroup
  )
  const [selectedCategoryName, setSelectedCategoryName] =
    React.useState<string>(currentTeam.leagueCategory.name)

  const leagueGroupRanking: 'loading' | LeagueGroupRanking =
    useLeagueGroupRanking(selectedGroup.id)

  if (leagueGroupRanking === 'loading') {
    return (
      <View style={styles.loading}>
        <LoadIndicator color={theme.colors.greenDark} />
      </View>
    )
  }

  return (
    <ScrollView style={{ backgroundColor: 'white' }}>
      <LinearGradient
        colors={[
          'white',
          'white',
          'white',
          theme.background.color.toString(),
          theme.background.color.toString(),
          theme.background.color.toString(),
        ]}
        style={{
          position: 'absolute',
          height: '120%',
          width: '120%',
        }}
      />
      <View style={styles.container}>
        <Container>
          <LeagueGroupSelector
            selectedGroup={selectedGroup}
            selectedCategoryName={selectedCategoryName}
            setSelectedGroup={setSelectedGroup}
            setSelectedCategoryName={setSelectedCategoryName}
            onRequestClose={() => setDropdownVisible(false)}
          />
        </Container>

        <RankingTable
          ranking={leagueGroupRanking}
          myTeamsIds={myTeams.map((team) => team.id)}
        />
      </View>
    </ScrollView>
  )
}

const getStyles = (_theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingTop: 30,
      paddingBottom: 100,
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: 20,
      backgroundColor: _theme.colors.primary,
    },
    loading: {
      flex: 1,
      justifyContent: 'center',
      alignContent: 'center',
      backgroundColor: 'white',
    },
  })
