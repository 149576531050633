import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { Theme } from '../../ui/themes/Theme'
import { shadeColor } from '../../../support/shadeColor'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { IconPlus } from '../../ui/icons/icons'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  size?: number
  inverse?: boolean
}

export const TeamAvatarsPlaceholder = ({
  size = 48,
  inverse = false,
}: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme, size)

  return (
    <View style={styles.container}>
      <View style={{ zIndex: inverse ? 0 : 1 }}>
        <View style={styles.playerEmpty}>
          <IconPlus color={theme.colors.primary} size={size * 0.25} />
        </View>
      </View>
      <View style={{ marginLeft: -size / 4, zIndex: inverse ? 1 : 0 }}>
        <View style={styles.playerEmpty}>
          <IconPlus color={theme.colors.primary} size={size * 0.25} />
        </View>
      </View>
    </View>
  )
}
const getStyles = (theme: Theme, size: number) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
    },
    playerEmpty: {
      height: size,
      width: size,
      borderColor: theme.colors.primary,
      borderWidth: 1,
      backgroundColor: shadeColor(theme.background.color.toString(), 60),
      borderRadius: size / 2,
      borderStyle: 'dashed',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
