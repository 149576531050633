import * as React from 'react'
import Svg, { G, Path } from 'react-native-svg'
import { defaultColor, defaultSize, IconProps } from './iconsSettings'

export const IconUser = ({
  size = defaultSize,
  color = defaultColor,
}: IconProps) => {
  return (
    <Svg width={(size * 19) / 21} height={size} viewBox="0 0 19 21" fill="none">
      <G
        id="Page-1"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
        stroke-dasharray="0,0"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <G
          id="Profile"
          transform="translate(-44.000000, -353.000000)"
          stroke="#A3FC6F"
          stroke-width="2"
        >
          <G id="Settings-List" transform="translate(24.000000, 336.000000)">
            <G id="Group" transform="translate(21.000000, 18.000000)">
              <Path
                d="M14.8653411,14.1096583 C13.5644888,13.0768932 11.3082386,11.8030303 8.09659091,11.8030303 C4.88494324,11.8030303 2.62869323,13.0768932 1.32784097,14.1096583 C0.476799324,14.7843743 3.55271368e-15,15.8189402 3.55271368e-15,16.9056826 L3.55271368e-15,19 L16.1931818,19 L16.1931818,16.9056826 C16.1931818,15.8189402 15.7163827,14.7843743 14.8653411,14.1096583 Z"
                id="Vector"
                fill-rule="nonzero"
              />
              <Path
                d="M8.09659091,8.99621212 C5.61235546,8.99621212 3.59848485,6.98234151 3.59848485,4.49810606 C3.59848485,2.01387061 5.61235546,-8.43769499e-14 8.09659091,-8.43769499e-14 C10.5808264,-8.43769499e-14 12.594697,2.01387061 12.594697,4.49810606 C12.594697,6.98234151 10.5808264,8.99621212 8.09659091,8.99621212 Z"
                id="Vector"
              />
            </G>
          </G>
        </G>
      </G>
    </Svg>
  )
}
