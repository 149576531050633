import * as React from 'react'
import {
  Button,
  ButtonSet,
  ButtonSetItem,
  Modal,
  TextError,
} from '../../../ui/ui'
import { useTranslation } from '../../../../hooks/useTranslation'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { Theme } from '../../../ui/themes/Theme'
import {
  StyleSheet,
  View,
  Text,
  Keyboard,
  TouchableOpacity,
} from 'react-native'
import { ButtonVariant } from '../../../ui/buttons/ButtonVariant/ButtonVariant'
import { Scoreboard } from '../../../common/Scoreboard/Scoreboard'
import { Game, GameScore } from '../../../../modules/game/Game'
import { GameApi } from '../../../../modules/game/GameApi'
import { defaultTheme } from '../../../ui/themes/default/defaultTheme'

interface Props {
  game: Game
  visible: boolean
  onRequestClose: () => void
}

export const EnterScoreModal = ({ game, visible, onRequestClose }: Props) => {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const { trans } = useTranslation()

  const [gameScore, setGameScore] = React.useState<GameScore>(
    game.score ?? GameScore.empty()
  )
  // console.log(`EnterScoreModal gameScore`, gameScore)

  const [showError, setShowError] = React.useState(false)

  const submitScore = () => {
    console.log('EnterScoreModal submit', gameScore)
    if (!gameScore.isValid()) {
      setShowError(true)
      return
    }

    // TODO /api/games/status to get the status
    GameApi.setGameScore(
      game.id,
      gameScore.toJson(game.team1.id, game.team2.id),
      'STATUS_FINISHED'
    )
      .then((result) => {
        console.log(`GameApi.setGameScore result`, result)
        game.score = gameScore
        closeModal()
      })
      .catch((error) => {
        console.log(`GameApi.setGameScore error`, error)
      })
  }

  const closeModal = () => {
    setShowError(false)
    onRequestClose()
  }

  return (
    <Modal visible={visible} onRequestClose={closeModal} style={styles.modal}>
      <View style={{ width: '100%' }}>
        <View style={styles.scoreContainer}>
          <Text style={styles.title}>
            {trans('screens.gameScreen.Which it was the result?')}
          </Text>
          <Scoreboard
            game={game}
            gameScore={gameScore}
            onChangeGameScore={(newGameScore) => {
              setShowError(false)
              setGameScore(newGameScore)
            }}
            color={theme.colors.grey}
            editable
          />
        </View>

        <View style={styles.scoreValidation}>
          {showError && <TextError>{trans('validation.score')}</TextError>}
        </View>

        <ButtonSet>
          <ButtonSetItem>
            <Button variant={ButtonVariant.default} onPress={closeModal}>
              {trans('general.Go back')}
            </Button>
          </ButtonSetItem>

          <ButtonSetItem>
            <Button
              variant={ButtonVariant.primary}
              onPress={(_) => {
                submitScore()
              }}
            >
              {trans('general.Confirm')}
            </Button>
          </ButtonSetItem>
        </ButtonSet>
      </View>
    </Modal>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    modal: {
      justifyContent: 'flex-start',
      backgroundColor: theme.colors.greenDark,
    },
    scoreContainer: {
      backgroundColor: theme.dark ? '#FFFFFF17' : '#000000AA',
      marginBottom: 18,
      borderRadius: 16,
      width: '100%',
      paddingVertical: 20,
    },
    title: {
      fontFamily: theme.text.fontPrimaryBold,
      color: theme.colors.white,
      textAlign: 'center',
      fontSize: 18,
      marginBottom: 30,
    },
    scoreValidation: {
      height: 40,
    },
  })
