import * as React from 'react'
import {
  Animated,
  View,
  StyleSheet,
  ScrollView,
  useWindowDimensions,
  ImageBackground,
} from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { Theme } from '../../../ui/themes/Theme'
import { defaultTheme } from '../../../ui/themes/default/defaultTheme'

interface Props {
  children: React.ReactNodeArray
}

const FIXED_BAR_WIDTH = 280
const BAR_SPACE = 10

export const PlayoffsCarousel = ({ children }: Props) => {
  const numItems = children.length
  const itemWidth = FIXED_BAR_WIDTH / numItems - (numItems - 1) * BAR_SPACE
  const animVal = new Animated.Value(0)
  const barArray: any = []

  const theme = defaultTheme

  const { width: deviceWidth, height: deviceHeight } = useWindowDimensions()

  const styles = getStyles(theme, deviceWidth, deviceHeight)
  children
    .filter((child) => React.isValidElement(child))
    .forEach((playoffsCarouselItem, i) => {
      const scrollBarVal = animVal.interpolate({
        inputRange: [deviceWidth * (i - 1), deviceWidth * (i + 1)],
        outputRange: [-itemWidth, itemWidth],
        extrapolate: 'clamp',
      })

      const thisBar = (
        <View
          key={`bar${i}`}
          style={[
            styles.track,
            {
              width: itemWidth,
              marginLeft: i === 0 ? 0 : BAR_SPACE,
            },
          ]}
        >
          <Animated.View
            style={[
              styles.bar,
              {
                width: itemWidth,
                transform: [{ translateX: scrollBarVal }],
              },
            ]}
          />
        </View>
      )
      barArray.push(thisBar)
    })

  return (
    <View style={styles.container}>
      <View style={styles.barContainer}>{barArray}</View>

      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={10}
        pagingEnabled
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { x: animVal } } }],
          { useNativeDriver: false }
        )}
      >
        <ImageBackground
          style={{
            width: '100%',
            position: 'absolute',
            aspectRatio: theme.playoffs.imageSources.backgroundAspectRatio,
            left: barArray.length === 4 ? 60 : undefined,
          }}
          source={theme.playoffs.imageSources.background}
          imageStyle={{
            resizeMode: 'stretch',
          }}
        />
        {children}
      </ScrollView>
    </View>
  )
}

const getStyles = (theme: Theme, deviceWidth: number, deviceHeight: number) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
      justifyContent: 'center',
    },
    barContainer: {
      zIndex: 2,
      top: deviceHeight - 195,
      flexDirection: 'row',
    },
    track: {
      backgroundColor: theme.dark ? '#FFFFFF55' : '#00000055',
      overflow: 'hidden',
      height: 4,
    },
    bar: {
      backgroundColor: theme.colors.primary,
      height: 4,
      position: 'absolute',
      left: 0,
      top: 0,
    },
  })
