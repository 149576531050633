import * as React from 'react'
import { StyleSheet, View, Text, ColorValue } from 'react-native'
import { P, SpacerVertical } from '../../ui/ui'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { PlayoffGame, PlayoffScore } from '../../../modules/playoff/Playoff'
import { TeamSimple } from '../../../modules/team/TeamSimple'
import { TeamScore1Set } from '../TeamScore/TeamScore1Set'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  game: PlayoffGame
  gameScore: PlayoffScore
  onChangeGameScore?: (gameScore: PlayoffScore) => void
  color?: ColorValue
  editable?: boolean
  teamToHighlight?: TeamSimple
  highlightWinner?: boolean
}

export const PlayoffScoreboard = ({
  game,
  gameScore,
  onChangeGameScore,
  color,
  editable = false,
  teamToHighlight,
  highlightWinner = false,
}: Props) => {
  const theme = defaultTheme
  const styles = getStyles(theme, editable)

  const defineColorForTeamScore = (
    team?: TeamSimple
  ): ColorValue | undefined => {
    let colorToApply = color

    if (teamToHighlight) {
      if (team === teamToHighlight) {
        colorToApply =
          game.winner() === team ? theme.colors.win : theme.colors.lose
      }
    } else if (highlightWinner) {
      if (game.winner() === team) {
        colorToApply = theme.colors.win
      }
    }

    return colorToApply
  }

  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <View style={styles.col1} />
        <View style={styles.setNumbers}>
          <Text style={{ ...styles.setNumber, color }} />
          <Text style={{ ...styles.setNumber, color }}>1</Text>
        </View>
      </View>

      <View style={styles.row}>
        <View style={styles.col1}>
          <P style={styles.team} center>
            {game.team1ShortNames()}
          </P>
        </View>
        <View style={styles.teamScore}>
          <TeamScore1Set
            fontSize={45}
            color={defineColorForTeamScore(game.first_team)}
            score={
              gameScore
                ? (gameScore[game.first_team.id]?.set_1 as unknown as string)
                : undefined
            }
            editable={editable}
            onChange={(newTeam1Score) => {
              if (onChangeGameScore && editable) {
                let secondScore = { set_1: '' }

                if (gameScore) {
                  secondScore = {
                    set_1: gameScore[game.second_team.id]?.set_1,
                  }
                }

                const scorePicked = {
                  [game.first_team.id]: newTeam1Score,
                  [game.second_team.id]: secondScore,
                }
                onChangeGameScore(scorePicked)
              }
            }}
          />
        </View>
      </View>

      {editable && <SpacerVertical size={10} />}

      <View style={styles.row}>
        <View style={styles.col1}>
          <P style={styles.team} center>
            {game.team2ShortNames()}
          </P>
        </View>
        <View style={styles.teamScore}>
          <TeamScore1Set
            fontSize={45}
            color={defineColorForTeamScore(game.second_team)}
            score={
              gameScore
                ? (gameScore[game.second_team.id]?.set_1 as unknown as string)
                : undefined
            }
            editable={editable}
            onChange={(newTeam2Score) => {
              if (onChangeGameScore && editable) {
                let firstScore = { set_1: '' }

                if (gameScore) {
                  firstScore = {
                    set_1: gameScore[game.first_team.id]?.set_1,
                  }
                }

                const scorePicked = {
                  [game.second_team.id]: newTeam2Score,
                  [game.first_team.id]: firstScore,
                }
                onChangeGameScore(scorePicked)
              }
            }}
          />
        </View>
      </View>
    </View>
  )
}

const getStyles = (theme: Theme, editable: boolean) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
    },
    setNumbers: {
      flexDirection: 'row',
      alignItems: 'center',
      marginRight: 10,
      marginBottom: 3,
    },
    setNumber: {
      width: 45 * 0.5,
      marginRight: 45 * 0.6,
      textAlign: 'center',
      fontFamily: theme.text.fontPrimaryBold,
      lineHeight: 14,
      height: 14,
      fontSize: 14,
      top: -5,
    },
    row: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    col1: {
      flex: 1,
    },
    team: {
      fontSize: 20,
    },
    teamScore: {
      paddingHorizontal: 10,
      paddingVertical: 5,
      backgroundColor: editable ? theme.background.color : 'transparent',
    },
  })
