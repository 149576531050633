import { Theme } from '../../components/ui/themes/Theme'

export type NotificationType = 'game.modified' | 'game.cancelled.byTeam' | 'game.lastMinute.created' | 'game.cancelled.butLastMinute' | "game.manually_created"

export class NotificationLink {
  text: string
  entityRelated: string
  meta: object

  constructor(text: string, entityRelated: string, meta: object) {
    this.text = text
    this.entityRelated = entityRelated
    this.meta = meta
  }
}

export class Notification {
  id: string
  type: NotificationType
  title: string
  message: string
  isRead: boolean
  resourceId: string | null

  constructor(
    id: string,
    type: NotificationType,
    title: string,
    message: string,
    isRead: boolean,
    resourceId: string | null
  ) {
    this.id = id
    this.type = type
    this.title = title
    this.message = message
    this.isRead = isRead
    this.resourceId = resourceId
  }

  themeNotificationType(): keyof Theme['notification'] {
    return this.type === 'game.modified'
      ? 'danger'
      : this.type === 'game.cancelled.byTeam'
      ? 'danger'
      : 'info'
  }
}
