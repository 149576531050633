import { KeyboardAvoidingView, Platform, StyleSheet } from 'react-native'
import * as React from 'react'

export const KeyboardView = (props: any) => (
  <KeyboardAvoidingView
    style={styles.container}
    behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    keyboardVerticalOffset={80}
  >
    {props.children}
  </KeyboardAvoidingView>
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
