import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import NetworkLogger from 'react-native-network-logger'

// https://github.com/alexbrazier/react-native-network-logger
// startNetworkLogging() is called in index.js, where you can configure the
// logging options.

export function NetworkLoggerScreen() {
  return (
    <View style={styles.container}>
      <NetworkLogger />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
