import { translations as deafultTranslations } from '../i18n/translations'
import { config } from '../config/config'
import { AppLanguage } from '../i18n/AppLanguage'

class TranslationService {
  translations: any
  language: AppLanguage

  constructor() {
    this.translations = deafultTranslations
    this.language = config.language || 'es'
  }

  getLanguage() {
    return this.language
  }

  setLanguage(language: AppLanguage) {
    this.language = language
  }

  /**
   * Returns translate by key. Accepts subkey
   * key example: calendar.remove_one_date
   * if there is not translation, returns last segment
   */
  translate(key: string, variables?: { key: string; value: string }) {
    const keys = key.split('.')
    let result

    if (keys) {
      result = this.translations[this.language.toString()]
      for (let i = 0; i < keys.length; i++) {
        if (result.hasOwnProperty(keys[i])) {
          result = result[keys[i]]
        } else {
          result = key
          break
        }
      }

      if (result == key && keys.length > 0) {
        result = keys[keys.length - 1]
      }
    } else {
      result = key
    }

    // variables assign
    if (variables) {
      for (const [name, value] of Object.entries(variables)) {
        result = result.replaceAll(':' + name, value)
      }
    }

    return result
  }

  /**
   *
   * @param domain
   *      example: 'concert.technical'
   * @param translations
   */
  addTranslations(domain: string, translations: any) {
    for (const language in translations) {
      if (!this.translations.hasOwnProperty(language)) {
        this.translations[language] = {}
      }

      const keyPath = domain.split('.')

      const lastKeyIndex = keyPath.length - 1

      let obj = this.translations[language]

      for (let i = 0; i < lastKeyIndex; ++i) {
        const key = keyPath[i]
        if (!(key in obj)) {
          obj[key] = {}
        }
        obj = obj[key]
      }
      obj[keyPath[lastKeyIndex]] = translations[language]
    }
  }
}

export const translationService = new TranslationService()
export const trans = (
  key: string,
  variables?: { key: string; value: string }
): string => translationService.translate(key, variables)
