import * as React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { P } from '../ui/ui'
import { ThemeContext } from '../../contexts/theme/ThemeContext'
import { defaultTheme } from '../ui/themes/default/defaultTheme'

interface Props {
  visible: boolean
  text?: string
}

export function ProgressModal({ visible, text }: Props) {
  const theme = defaultTheme
  if (!visible) {
    return null
  }
  return (
    <View style={styles.fullScreenContainer}>
      <View
        style={[styles.background, { backgroundColor: theme.background.color }]}
      >
        <ActivityIndicator color={theme.colors.greenDark} size={'large'} />
        {text && (
          <P noPadding={true} style={styles.text}>
            {text}
          </P>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  fullScreenContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  background: {
    padding: 35,
    opacity: 1,
    zIndex: 2,
    borderRadius: 10,
  },
  text: {
    marginTop: 14,
  },
})
