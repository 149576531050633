import * as React from 'react'
import { User } from '../../modules/user/User'
import { AuthStatus } from './AuthStatus'
import { AuthApi } from '../../modules/auth/AuthApi'
import { LocalStorage } from '../../data/LocalStorage'
import { UserApi } from '../../modules/user/UserApi'
import { extractApiError } from '../../support/apiError'

// EXTERNAL VALUE

type AuthContextValue = {
  user: User | null
  authToken: string | null
  authStatus: AuthStatus
  login: (
    email: string,
    password: string,
    fcmToken: string
  ) => Promise<
    | { kind: 'success' }
    | { kind: 'bad-credentials' }
    | { kind: 'bad-email' }
    | { kind: 'unknown-error'; errorMessage: string }
  >
  logout: () => Promise<void>
  setUserImage: (serverImageUrl: string) => void
}

export const AuthContext = React.createContext<AuthContextValue>(
  {} as AuthContextValue
)

export const useAuthContext = () => React.useContext(AuthContext)

// INTERNAL STATE

type AuthState = {
  authStatus: AuthStatus
  user: User | null
  authToken: string | null
  refreshToken: string | null
}

type AuthAction =
  | {
      type: 'login'
      payload: {
        user: User
        authToken: string
        refreshToken: string
      }
    }
  | { type: 'logout' }
  | {
      type: 'setUser'
      payload: {
        user: User
      }
    }
  | {
      type: 'setUserImage'
      payload: {
        serverImageUrl: string
      }
    }

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'login':
      return {
        authStatus: AuthStatus.authenticated,
        user: action.payload.user,
        authToken: action.payload.authToken,
        refreshToken: action.payload.refreshToken,
      }
    case 'logout':
      return {
        authStatus: AuthStatus.notAuthenticated,
        user: null,
        authToken: null,
        refreshToken: null,
      }
    case 'setUser':
      return {
        ...state,
        user: action.payload.user,
      }
    case 'setUserImage':
      if (state.user) {
        state.user.image = action.payload.serverImageUrl
        return {
          ...state,
          user: state.user,
        }
      } else {
        return state
      }
  }
}

const initialAuthState: AuthState = {
  authStatus: AuthStatus.checking,
  user: null,
  authToken: null,
  refreshToken: null,
}

export function AuthContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [authState, dispatch] = React.useReducer(authReducer, initialAuthState)

  /*
  const checkToken = async () => {
    const storedToken = await LocalStorage.getAuthToken()

    // No token
    if (!storedToken) return dispatch({ type: 'notAuthenticated' })

    // Validate token
    const {
      player: user,
      authToken,
      refreshToken,
    } = await AuthApi.checkToken(storedToken)
    // TODO: Si el token esta mal (status !== 200), entonces
    // dispatch({type: 'notAuthenticated'})

    // Login
    dispatch({ type: 'login', payload: { user, authToken, refreshToken } })

    await LocalStorage.setAuthToken(authToken, refreshToken)
  }

  React.useEffect(() => {
    checkToken()
  }, [])
  */

  const updateLocalUserWithLatestServerData = React.useCallback(() => {
    // Update the user stored in LocalStorage and in-memory with the latest, most up-to-date user data from the server.
    UserApi.getUser()
      .then((user) => {
        LocalStorage.setUser(user)
        dispatch({ type: 'setUser', payload: { user } })
        console.log(`refreshUser success`)
      })
      .catch((error) => {
        console.log(`refreshUser error`, error)
      })
  }, [])

  const initializeUser = React.useCallback(() => {
    console.log(`AuthContext initializeUser`)
    LocalStorage.getAuth()
      .then(([user, authToken, refreshToken]) => {
        console.log(
          `AuthContext initializeUser - LocalStorage.getAuth() success user`,
          user,
          'authToken',
          authToken,
          'refreshToken',
          refreshToken
        )
        if (user && authToken && refreshToken) {
          dispatch({
            type: 'login',
            payload: { user, authToken, refreshToken },
          })
          updateLocalUserWithLatestServerData()
        } else {
          dispatch({ type: 'logout' })
        }
      })
      .catch((error) => {
        console.log(
          `AuthContext initializeUser - LocalStorage.getAuth() error`,
          error
        )
        dispatch({ type: 'logout' })
      })
  }, [updateLocalUserWithLatestServerData])

  React.useEffect(() => {
    // Every time the app starts we try to log in with the user from LocalStorage, if there is one, and refresh this
    // user data with the latest data from the server. If there's no user in LocalStorage, it means that the user has
    // not logged in yet. In this case, we do a logout, which shows the LoginScreen (see App).
    initializeUser()
  }, [initializeUser])

  const login: AuthContextValue['login'] = React.useCallback(
    async (
      email: string,
      password: string,
      fcmToken: string
    ): Promise<
      | { kind: 'success' }
      | { kind: 'bad-credentials' }
      | { kind: 'bad-email' }
      | { kind: 'unknown-error'; errorMessage: string }
    > => {
      try {
        const { user, authToken, refreshToken } = await AuthApi.login(
          email,
          password,
          fcmToken
        )
        await LocalStorage.setAuth(user, authToken, refreshToken)
        dispatch({ type: 'login', payload: { user, authToken, refreshToken } })
        return { kind: 'success' }
      } catch (error: any) {
        console.log('AuthContext login() error', error)
        if (error.response && error.response.status === 401) {
          return { kind: 'bad-credentials' }
        }
        if (error.response && error.response.status === 400) {
          return { kind: 'bad-email' }
        }
        return { kind: 'unknown-error', errorMessage: error.message }
      }
    },
    []
  )

  const logout = React.useCallback(async () => {
    LocalStorage.clearAll() // We don't want to wait here
    await AuthApi.logout()
      .then(() => {
        console.log(`AuthApi.logout success`)
      })
      .catch((error) => {
        console.log(`AuthApi.logout error`, extractApiError(error))
      })
    dispatch({ type: 'logout' })
  }, [])

  const setUserImage = React.useCallback(
    (serverImageUrl: string) => {
      // Update in-memory user
      // While updating the in-memory user seems useless if we do it again below too, it updates the UI in ProfileScreen
      // much quicker since we don't have to wait for the request to finish.
      dispatch({ type: 'setUserImage', payload: { serverImageUrl } })
      // We also need to update the LocalStorage user too. This is not super important because we don't use the user
      // from LocalStorage at the UI (we use the in-memory one), and the LocalStorage user is refreshed every time the
      // app starts.
      // There are various ways to refresh the LocalStorage user. We could just update the image field there, or refresh
      // the whole user object with server data. Let's update everything with server data, just because it's simpler.
      updateLocalUserWithLatestServerData()
    },
    [updateLocalUserWithLatestServerData]
  )

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        login,
        logout,
        setUserImage,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
