import * as React from 'react'
import { ColorValue, StyleSheet, TouchableOpacity } from 'react-native'
import { Theme } from '../../ui/themes/Theme'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { IconMenuBack } from '../../ui/icons/icons'
import { View } from 'react-native'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  size?: number
  onPress?: () => void
  color?: ColorValue
}

export const HeaderBackButton = ({ size = 15, onPress, color }: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme, size)

  return (
    <TouchableOpacity
      onPress={onPress}
      style={styles.container}
      activeOpacity={0.5}
    >
      <View style={styles.inner}>
        <IconMenuBack size={size} color={color} />
      </View>
    </TouchableOpacity>
  )
}

const getStyles = (theme: Theme, size: number) =>
  StyleSheet.create({
    container: {
      marginLeft: theme.grid.gap * 2,
      borderColor: theme.colors.primary,
      borderRadius: size / 2,
      width: size * 2,
      height: size * 2,
      alignItems: 'center',
      justifyContent: 'center',
    },
    inner: {},
  })
