import * as React from 'react'
import {
  Dimensions,
  Image,
  Linking,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { Col, Container, P, Row } from '../../ui/ui'
import { HomeTile } from './HomeTile'
import { DrawerOpenButton } from '../../common/DrawerOpenButton/DrawerOpenButton'
import { useNavigation } from '@react-navigation/native'
import {
  MainStackScreenNavigationProp,
  MainStackScreenProps,
} from '../../../navigation/MainStackNavigator'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { ScreenTitle } from '../../common/ScreenTitle/ScreenTitle'
import { AuthContext } from '../../../contexts/auth/AuthContext'
import { MeContext } from '../../../contexts/me/MeContext'
import { useTranslation } from '../../../hooks/useTranslation'
import { translationService } from '../../../services/translationService'
import { IconCloud } from '../../ui/icons/IconCloud'
import { capitalize } from '../../../support/string'
import { LinearGradient } from 'expo-linear-gradient'
import { UserAvatar } from '../../common/UserAvatar/UserAvatar'
import { HomeTileCarousel } from './HomeTileCarousel'
import { config } from '../../../config/config'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'
import { LoadingScreen } from '../LoadingScreen/LoadingScreen'

translationService.addTranslations(
  'screens.home',
  require('./HomeScreen.i18n.json')
)

type Props = MainStackScreenProps<'HomeScreen'>

export const HomeScreen = ({ navigation }: Props) => {
  const theme = defaultTheme
  const { user } = React.useContext(AuthContext)
  const { currentTeam } = React.useContext(MeContext)
  const { trans } = useTranslation()
  const hasPlayoff = currentTeam?.hasPlayoff
  const styles = getStyles(theme)

  React.useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <View style={{ flex: 1, justifyContent: 'center', marginTop: 0 }}>
          {/*@ts-ignore*/}
          <DrawerOpenButton navigation={navigation} />
        </View>
      ),
      headerLeft: () => (
        <View style={{ flex: 1, justifyContent: 'center', marginTop: 10 }}>
          {headerLeft()}
        </View>
      ),
      headerStyle: {
        backgroundColor: 'white',
        height: 110,
      },
      headerTitleAlign: 'center',
    })
  }, [currentTeam, navigation])

  const headerLeft = () => {
    return (
      <View style={styles.team}>
        <UserAvatar
          imageSource={{
            uri: user?.image,
            width: 40,
            height: 40,
          }}
          size={40}
        />
        <View style={{ marginLeft: 15 }}>
          <P
            numberOfLines={1}
            style={{ fontSize: 18, color: theme.colors.greenDark }}
          >
            {user ? user.fullName() : ''}
          </P>
          <P
            numberOfLines={1}
            style={{ fontSize: 14, color: theme.colors.grey }}
          >
            {currentTeam?.leagueCategory
              ? trans('screens.home.Group') +
                currentTeam.leagueGroup.name +
                ' - ' +
                capitalize(currentTeam.leagueCategory.name)
              : ''}
          </P>
        </View>
      </View>
    )
  }

  return (
    <View style={{ backgroundColor: 'white' }}>
      <Container style={styles.container}>
        <LinearGradient
          colors={[
            'white',
            'white',
            'white',
            theme.background.color.toString(),
            theme.background.color.toString(),
            theme.background.color.toString(),
          ]}
          style={{
            position: 'absolute',
            height: '120%',
            width: '120%',
          }}
        />
        <ScreenTitle
          title={`WELCOME\nTO LA LIGUITA!`}
          // subtitle={currentTeam.league.name}
        />

        <Row>
          <Col width={1 / 2}>
            <HomeTile
              title={trans('screens.home.My matches')}
              description={trans('screens.home.my_matches_description')}
              icon={require('../../../assets/images/homeIcons/myGames.png')}
              onPress={() => {
                // There's no point in navigating to MyGamesScreen without currentTeam because the call `POST /my-games`
                // requires a teamId, and if we don't have currentTeam MyGamesScreen shows the progress indefinitely.
                if (currentTeam) {
                  navigation.navigate('MyGamesScreen')
                }
              }}
            />

            <HomeTile
              title={trans('screens.home.Ranking')}
              description={trans('screens.home.ranking_description')}
              onPress={() => navigation.navigate('RankingScreen')}
              themeType={'dark'}
              icon={require('../../../assets/images/homeIcons/ranking.png')}
            />

            <HomeTile
              title={trans('screens.home.Stats')}
              description={trans('screens.home.stats_description')}
              onPress={() => {
                if (currentTeam) {
                  navigation.navigate('TeamStatsScreen', {
                    teamId: currentTeam?.id,
                  })
                }
              }}
              themeType={'light'}
              icon={require('../../../assets/images/homeIcons/stats.png')}
            />

            {/*<ClubImage*/}
            {/*  imageSource={require('../../../assets/sampleImages/ads/.png')}*/}
            {/*  url="https://www.viwa.es"*/}
            {/*  height={190}*/}
            {/*  imageWidth={useWindowDimensions().width / 2.5}*/}
            {/*/>*/}
          </Col>

          <Col width={1 / 2}>
            <HomeTile
              title={trans('screens.home.Last_minute')}
              description={trans('screens.home.last_minute_description')}
              onPress={() => navigation.navigate('LastMinuteScreen')}
              showNotificationsCount={'LastMinute'}
              themeType={'light'}
              icon={require('../../../assets/images/homeIcons/open.png')}
              extraPadding={185}
            />

            <HomeTile
              title={trans('screens.home.Notifications')}
              description={trans('screens.home.notifications_description')}
              onPress={() => navigation.navigate('NotificationsScreen')}
              showNotificationsCount={'Notifications'}
              icon={require('../../../assets/images/homeIcons/notification.png')}
            />

            {/*{!hasPlayoff ? (*/}
            {/*  <HomeTile*/}
            {/*    title={trans('screens.home.Playoffs')}*/}
            {/*    description={trans('screens.home.playoffs_description')}*/}
            {/*    onPress={() => navigation.navigate('PlayoffsScreen')}*/}
            {/*    themeType={'dark'}*/}
            {/*  />*/}
            {/*) : null}*/}

            {/*<ClubImage*/}
            {/*  imageSource={require('../../../assets/sampleImages/ads/babolat.png')}*/}
            {/*  height={140}*/}
            {/*  imageWidth={useWindowDimensions().width / 2.7}*/}
            {/*/>*/}

            {/*
            <HomeTile
              imageSource={theme.home.imageSources.lastMinuteMatches}
              title={trans('screens.home.Last Minute matches')}
              description={trans(
                'screens.home.last_minute_matches_description'
              )}
              onPress={() => navigation.navigate('LastMinuteScreen')}
              notificationsCount={3}
            />

            <ClubImage
              imageSource={require('../../../assets/sampleImages/ads/bullpadel.png')}
              url="https://www.bullpadel.com/"
            />

            <HomeTile
              imageSource={theme.home.imageSources.upcomingTournaments}
              title={trans('screens.home.Upcoming Tournaments')}
              description={trans(
                'screens.home.upcoming_tournaments_description'
              )}
              onPress={() => navigation.navigate('TournamentsScreen')}
              notificationsCount={2}
            />
            */}
          </Col>
        </Row>

        {/*TODO se necesita traer las imagenes del carousel desde el backffice, se oculta por ahora*/}
        {/*<HomeTileCarousel*/}
        {/*  title={trans('screens.home.Upcoming Tournaments')}*/}
        {/*  description={trans('screens.home.upcoming_tournaments_description')}*/}
        {/*  onPress={() => navigation.navigate('NotificationsScreen')}*/}
        {/*  images={[*/}
        {/*    require('../../../../src/components/ui/themes/default/images/home/racket-lilac.png'),*/}
        {/*    require('../../../../src/components/ui/themes/default/images/home/cup-blue.png'),*/}
        {/*    require('../../../../src/components/ui/themes/default/images/home/cup-green.png'),*/}
        {/*    require('../../../assets/images/app-logo.png'),*/}
        {/*  ]}*/}
        {/*/>*/}

        <View style={styles.socialMediasContainer}>
          <View
            style={{
              width: '47%',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <TouchableOpacity
              onPress={() => {
                Linking.openURL(`https://wa.me/0034747405304`)
              }}
              style={[styles.socialMedias, { width: '46%' }]}
            >
              <Image
                style={{ height: '42%', width: '46%' }}
                resizeMode={'contain'}
                source={require('../../../assets/images/whatsapp.png')}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                Linking.openURL('https://www.instagram.com/laliguitadepadel/')
              }}
              style={[styles.socialMedias, { width: '46%' }]}
            >
              <Image
                style={{ height: '42%', width: '46%' }}
                resizeMode={'contain'}
                source={require('../../../assets/images/instagram.png')}
              />
            </TouchableOpacity>
          </View>
          <TouchableOpacity
            onPress={() => Linking.openURL('https://www.laliguitadepadel.com')}
            style={[styles.socialMedias, { width: '47%' }]}
          >
            <Image
              style={{ height: '70%', width: '70%' }}
              source={require('../../../assets/images/app-logo-row.png')}
              resizeMode={'contain'}
            />
          </TouchableOpacity>
        </View>

        <View>
          <Image
            resizeMode="contain"
            style={{
              width: '40%',
              height: 200,
              alignSelf: 'center',
              marginTop: 20,
              marginBottom: -20,
            }}
            source={require('../../../assets/sampleImages/ads/bullpadel.png')}
          />
          <P center style={{ fontSize: 18 }}>
            Patrocinador oficial de La Liguita
          </P>
          <P
            center
            style={{
              fontSize: 18,
              marginTop: 40,
              fontFamily: 'Syne_800ExtraBold',
            }}
          >
            {`¿Qué haces aquí?\n A jugar, que no llegas…`}
          </P>
        </View>
        <View style={{ height: 100 }}>
          <Image
            resizeMode="contain"
            style={{
              alignSelf: 'center',
              width: 300,
              bottom: 50,
            }}
            source={require('../../../assets/images/ball.png')}
          />
        </View>
      </Container>
    </View>
  )
}

function NetworkLoggerScreenButton() {
  const theme = defaultTheme
  const styles = getStyles(theme)
  const navigation =
    useNavigation<MainStackScreenNavigationProp<'HomeScreen'>>()
  return (
    <Pressable
      onPress={() => {
        navigation.navigate('NetworkLoggerScreen')
      }}
      style={styles.networkLoggerButton}
    >
      <IconCloud size={28} color={'#444444'} />
    </Pressable>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingBottom: 50,
      backgroundColor: 'white',
      marginTop: 10,
    },
    team: {
      flexDirection: 'row',
      marginHorizontal: 20,
      width: Dimensions.get('window').width * 0.7,
      alignItems: 'center',
      marginBottom: 10,
    },
    title: {
      marginBottom: -5,
      fontSize: 48,
    },
    subtitle: {
      fontSize: 18,
      color: theme.text.colorMuted,
      marginBottom: 20,
    },
    networkLoggerButton: {
      marginLeft: theme.grid.gap * 2,
    },
    socialMedias: {
      backgroundColor: '#18362E',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 25,
    },
    socialMediasContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 80,
    },
  })
