import { apiClient } from '../../support/apiClient'
import { Uuid } from '../../support/types'
import { AxiosResponse } from 'axios'
import {
  LeagueGroupRanking,
  LeagueGroupRankingItem,
} from './LeagueGroupRanking'
import { League } from './League'
import { LeagueCategory } from './LeagueCategory'
import { LeagueGroup } from './LeagueGroup'

type GroupStandingsResponse = {
  standings: {
    order: number
    team_id: string
    /**  'Alex / Ignasi' */
    name: string
    points: number
    played: number
    won: number
    draw: number
    lost: number
    games_won: number
    games_lost: number
    games_difference: number
    sets_total: number
    sets_won: number
  }[]
}

type LeagueGroupsResponse = {
  league: {
    id: string
    /** Liga Otoño 21 */
    name: string
    categories: Array<{
      id: string
      /** masculino */
      name: string
      groups: Array<{
        id: string
        /** A1 */
        name: string
      }>
    }>
  }
}

export class LeagueApi {
  static getGroupStandings(leagueGroupId: Uuid): Promise<LeagueGroupRanking> {
    return apiClient
      .get(`/group/${leagueGroupId}/standings`)
      .then((response: AxiosResponse<GroupStandingsResponse>) => {
        return response.data.standings.map((standing) => {
          const item: LeagueGroupRankingItem = {
            position: standing.order,
            teamId: standing.team_id,
            teamPlayerNames: standing.name,
            points: standing.points,
            played: standing.played,
            won: standing.won,
            lost: standing.lost,
            draw: standing.draw,
            // TODO gameAverage
            gameAverage: 0,
          }
          return item
        })
      })
  }

  static getLeagueGroups(teamId: string): Promise<League> {
    return apiClient
      .post(`/groups`, {
        team_id: teamId,
      })
      .then((response: AxiosResponse<LeagueGroupsResponse>) => {
        const league = response.data.league
        const categories: LeagueCategory[] = league.categories.map((cat) => {
          const groups = cat.groups.map((group) => LeagueGroup.fromJson(group))
          return new LeagueCategory(cat.id, cat.name, groups)
        })
        return new League(league.id, league.name, categories)
      })
  }
}
