import * as React from 'react'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { H1, H2, SpacerVertical } from '../../ui/ui'
import { FlatList, Image, StyleSheet, View } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { useMyLastMinuteMatches } from '../../../modules/lastMinuteMatch/useMyLastMinuteMatches'
import { Loader } from '../../common/Loader/Loader'
import { LastMinuteMatchCard } from '../../common/LastMinuteMatchCard/LastMinuteMatchCard'
import { ErrorMessage } from '../../common/ErrorMessage'
import { LastMinute } from '../../../modules/lastMinuteMatch/LastMinute'
import { LinearGradient } from 'expo-linear-gradient'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'screens.lastMinute',
  require('./LastMinuteScreen.i18n.json')
)

export const LastMinuteScreen = () => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  const { trans } = useTranslation()

  const myLastMinuteMatches = useMyLastMinuteMatches()
  // const renderHeader = () => (
  //   <ScreenTitle title={trans('screens.lastMinute.title')} />
  // )
  const renderLastMinuteMatch = (lastMinuteMatch: LastMinute) => (
    <View key={lastMinuteMatch.id}>
      <LastMinuteMatchCard lastMinuteMatch={lastMinuteMatch} />
    </View>
  )

  if (myLastMinuteMatches === 'loading') return <Loader />

  if (myLastMinuteMatches instanceof Error) {
    return <ErrorMessage text={myLastMinuteMatches.message} />
  }

  const RenderEmptyComponent = () => {
    return (
      <View style={styles.container}>
        <Image
          resizeMode="contain"
          style={{
            alignSelf: 'center',
            height: 60,
            width: 60,
            marginBottom: 20,
          }}
          source={require('../../../assets/images/small-logo.png')}
        />

        <H1
          center
          style={{
            color: '#A8A8A8',
            fontSize: 22,
            fontFamily: 'Syne_800ExtraBold',
          }}
        >
          {trans('screens.lastMinute.no_result_alert_title')}
        </H1>
        <H2 center style={{ fontSize: 18, color: '#A8A8A8' }}>
          Recibirás una notificación cuando tengamos partidos disponibles
        </H2>
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <LinearGradient
        colors={[
          'white',
          'white',
          'white',
          theme.background.color.toString(),
          theme.background.color.toString(),
          theme.background.color.toString(),
        ]}
        style={{
          position: 'absolute',
          height: '120%',
          width: '120%',
        }}
      />
      <FlatList
        data={myLastMinuteMatches}
        renderItem={({ item }) => renderLastMinuteMatch(item)}
        ItemSeparatorComponent={() => <SpacerVertical size={20} />}
        // ListHeaderComponent={() => renderHeader()}
        ListEmptyComponent={RenderEmptyComponent}
        keyExtractor={(lastMinuteMatch) => lastMinuteMatch.id}
        contentContainerStyle={styles.container}
      />
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingVertical: 30,
      paddingHorizontal: theme.grid.gap * 2,
    },
    noResultContainer: {
      marginTop: 80,
    },
  })
