import React, { useEffect, useRef } from 'react'
import { View, StyleSheet, Animated } from 'react-native'
import { ThemeContext } from '../../contexts/theme/ThemeContext'
import { Theme } from '../ui/themes/Theme'
import { defaultTheme } from '../ui/themes/default/defaultTheme'

const ProgressBar = () => {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const progressAnim = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(progressAnim, {
      toValue: 65,
      duration: 1300,
      useNativeDriver: false,
    }).start(() => {
      Animated.timing(progressAnim, {
        toValue: 97,
        duration: 5000,
        useNativeDriver: false,
      }).start()
    })
  }, [])

  return (
    <View style={styles.container}>
      <Animated.View
        style={{
          ...styles.filled,
          width: progressAnim.interpolate({
            inputRange: [0, 100],
            outputRange: ['0%', '100%'],
          }),
        }}
      />
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      height: 10,
      borderRadius: 20,
      overflow: 'hidden',
      borderColor: theme.colors.primary,
      borderWidth: 2,
      marginHorizontal: 20,
    },
    filled: {
      height: 5,
      backgroundColor: '#CEFFB4',
    },
  })

export default ProgressBar
