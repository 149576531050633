import { Game } from './Game'
import myUpcomingGamesDummy from '../../data/dummy/myUpcomingGames.json'
import myPlayedGamesDummy from '../../data/dummy/myPlayedGames.json'
import gameDummy from '../../data/dummy/game.json'
import { ApiGameAdapter } from './ApiGameAdapter'
import { apiClient } from '../../support/apiClient'
import { AxiosResponse } from 'axios'
import { GameJson } from './GameJson'
import { MyGames } from './MyGames'
import { GameCancelInfo } from './GameCancelInfo'
import { GameScoreJson } from './GameScoreJson'
import { GameStatus } from './GameStatus'

// const apiGameAdapter = new ApiGameAdapter()
const mockData: { next: GameJson[]; played: GameJson[] } = {
  next: [
    {
      id: 'd2dac4a4-f9bc-4b87-b66e-9607b8a6c0f3',
      scheduled_on: '2021-07-17T22:00:00',
      club: 'Real Club de Polo',
      resultCanBeSent: null,
      canBeCancelled: null,
      first_team: {
        id: '6eed5c7f-0fec-4d71-b886-9974780ffdba',
        name: '',
        rank: 2,
        rankingTrend: 'down',
        player1: {
          id: 'dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
          name: 'Natxo',
          picture:
            'https://i.pravatar.cc/300?dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
        },
        player2: {
          id: '981eaa12-0a4f-4911-b179-5d41e920710c',
          name: 'Sergio',
          picture:
            'https://i.pravatar.cc/300?981eaa12-0a4f-4911-b179-5d41e920710c',
        },
      },
      second_team: {
        id: '123456789', // No se proporcionó un ID para el segundo equipo en el juego.
        name: '',
        rank: 8,
        rankingTrend: 'up',
        player1: {
          id: '61fab585-8af9-41b1-a5d1-60f6922c50db',
          name: 'Manel',
          picture:
            'https://i.pravatar.cc/300?61fab585-8af9-41b1-a5d1-60f6922c50db',
        },
        player2: {
          id: '85b6d368-2d92-4077-acd2-e5310b85f4fd',
          name: 'Carles',
          picture:
            'https://i.pravatar.cc/300?85b6d368-2d92-4077-acd2-e5310b85f4fd',
        },
      },
      status: 'STATUS_FINISHED', // Esto es un supuesto. Cambiar según necesidad.
      score: {
        '6eed5c7f-0fec-4d71-b886-9974780ffdba': {
          set_1: 4,
          set_2: 2,
          set_3: 1,
        },
        '123456789': {
          set_1: 4,
          set_2: 6,
          set_3: 7,
        },
      },
    },
    {
      id: 'd2dac4a4-f9bc-4b87-b66e-9607b8a6c0f3',
      scheduled_on: '2021-07-17T22:00:00',
      club: 'Real Club de Polo',
      resultCanBeSent: null,
      canBeCancelled: null,
      first_team: {
        id: '6eed5c7f-0fec-4d71-b886-9974780ffdba',
        name: '',
        rank: 2,
        rankingTrend: 'down',
        player1: {
          id: 'dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
          name: 'Natxo',
          picture:
            'https://i.pravatar.cc/300?dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
        },
        player2: {
          id: '981eaa12-0a4f-4911-b179-5d41e920710c',
          name: 'Sergio',
          picture:
            'https://i.pravatar.cc/300?981eaa12-0a4f-4911-b179-5d41e920710c',
        },
      },
      second_team: {
        id: '123456789', // No se proporcionó un ID para el segundo equipo en el juego.
        name: '',
        rank: 8,
        rankingTrend: 'up',
        player1: {
          id: '61fab585-8af9-41b1-a5d1-60f6922c50db',
          name: 'Manel',
          picture:
            'https://i.pravatar.cc/300?61fab585-8af9-41b1-a5d1-60f6922c50db',
        },
        player2: {
          id: '85b6d368-2d92-4077-acd2-e5310b85f4fd',
          name: 'Carles',
          picture:
            'https://i.pravatar.cc/300?85b6d368-2d92-4077-acd2-e5310b85f4fd',
        },
      },
      status: 'STATUS_FINISHED', // Esto es un supuesto. Cambiar según necesidad.
      score: {
        '6eed5c7f-0fec-4d71-b886-9974780ffdba': {
          set_1: 4,
          set_2: 2,
          set_3: 1,
        },
        '123456789': {
          set_1: 4,
          set_2: 6,
          set_3: 7,
        },
      },
    },
    {
      id: 'd2dac4a4-f9bc-4b87-b66e-9607b8a6c0f3',
      scheduled_on: '2021-07-17T22:00:00',
      club: 'Real Club de Polo',
      resultCanBeSent: null,
      canBeCancelled: null,
      first_team: {
        id: '6eed5c7f-0fec-4d71-b886-9974780ffdba',
        name: '',
        rank: 2,
        rankingTrend: 'down',
        player1: {
          id: 'dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
          name: 'Natxo',
          picture:
            'https://i.pravatar.cc/300?dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
        },
        player2: {
          id: '981eaa12-0a4f-4911-b179-5d41e920710c',
          name: 'Sergio',
          picture:
            'https://i.pravatar.cc/300?981eaa12-0a4f-4911-b179-5d41e920710c',
        },
      },
      second_team: {
        id: '123456789', // No se proporcionó un ID para el segundo equipo en el juego.
        name: '',
        rank: 8,
        rankingTrend: 'up',
        player1: {
          id: '61fab585-8af9-41b1-a5d1-60f6922c50db',
          name: 'Manel',
          picture:
            'https://i.pravatar.cc/300?61fab585-8af9-41b1-a5d1-60f6922c50db',
        },
        player2: {
          id: '85b6d368-2d92-4077-acd2-e5310b85f4fd',
          name: 'Carles',
          picture:
            'https://i.pravatar.cc/300?85b6d368-2d92-4077-acd2-e5310b85f4fd',
        },
      },
      status: 'STATUS_FINISHED', // Esto es un supuesto. Cambiar según necesidad.
      score: {
        '6eed5c7f-0fec-4d71-b886-9974780ffdba': {
          set_1: 4,
          set_2: 2,
          set_3: 1,
        },
        '123456789': {
          set_1: 4,
          set_2: 6,
          set_3: 7,
        },
      },
    },
    {
      id: 'd2dac4a4-f9bc-4b87-b66e-9607b8a6c0f3',
      scheduled_on: '2021-07-17T22:00:00',
      club: 'Real Club de Polo',
      resultCanBeSent: null,
      canBeCancelled: null,
      first_team: {
        id: '6eed5c7f-0fec-4d71-b886-9974780ffdba',
        name: '',
        rank: 2,
        rankingTrend: 'down',
        player1: {
          id: 'dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
          name: 'Natxo',
          picture:
            'https://i.pravatar.cc/300?dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
        },
        player2: {
          id: '981eaa12-0a4f-4911-b179-5d41e920710c',
          name: 'Sergio',
          picture:
            'https://i.pravatar.cc/300?981eaa12-0a4f-4911-b179-5d41e920710c',
        },
      },
      second_team: {
        id: '123456789', // No se proporcionó un ID para el segundo equipo en el juego.
        name: '',
        rank: 8,
        rankingTrend: 'up',
        player1: {
          id: '61fab585-8af9-41b1-a5d1-60f6922c50db',
          name: 'Manel',
          picture:
            'https://i.pravatar.cc/300?61fab585-8af9-41b1-a5d1-60f6922c50db',
        },
        player2: {
          id: '85b6d368-2d92-4077-acd2-e5310b85f4fd',
          name: 'Carles',
          picture:
            'https://i.pravatar.cc/300?85b6d368-2d92-4077-acd2-e5310b85f4fd',
        },
      },
      status: 'STATUS_FINISHED', // Esto es un supuesto. Cambiar según necesidad.
      score: {
        '6eed5c7f-0fec-4d71-b886-9974780ffdba': {
          set_1: 4,
          set_2: 2,
          set_3: 1,
        },
        '123456789': {
          set_1: 4,
          set_2: 6,
          set_3: 7,
        },
      },
    },
  ],
  played: [
    {
      id: 'd2dac4a4-f9bc-4b87-b66e-9607b8a6c0f3',
      scheduled_on: '2021-07-17T22:00:00',
      club: 'Real Club de Polo',
      resultCanBeSent: null,
      canBeCancelled: null,
      first_team: {
        id: '6eed5c7f-0fec-4d71-b886-9974780ffdba',
        name: '',
        rank: 2,
        rankingTrend: 'down',
        player1: {
          id: 'dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
          name: 'Natxo',
          picture:
            'https://i.pravatar.cc/300?dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
        },
        player2: {
          id: '981eaa12-0a4f-4911-b179-5d41e920710c',
          name: 'Sergio',
          picture:
            'https://i.pravatar.cc/300?981eaa12-0a4f-4911-b179-5d41e920710c',
        },
      },
      second_team: {
        id: '123456789', // No se proporcionó un ID para el segundo equipo en el juego.
        name: '',
        rank: 8,
        rankingTrend: 'up',
        player1: {
          id: '61fab585-8af9-41b1-a5d1-60f6922c50db',
          name: 'Manel',
          picture:
            'https://i.pravatar.cc/300?61fab585-8af9-41b1-a5d1-60f6922c50db',
        },
        player2: {
          id: '85b6d368-2d92-4077-acd2-e5310b85f4fd',
          name: 'Carles',
          picture:
            'https://i.pravatar.cc/300?85b6d368-2d92-4077-acd2-e5310b85f4fd',
        },
      },
      status: 'STATUS_FINISHED', // Esto es un supuesto. Cambiar según necesidad.
      score: {
        '6eed5c7f-0fec-4d71-b886-9974780ffdba': {
          set_1: 4,
          set_2: 2,
          set_3: 1,
        },
        '123456789': {
          set_1: 4,
          set_2: 6,
          set_3: 7,
        },
      },
    },
  ],
}

export class GameApi {
  static getGame(gameId: string): Promise<Game> {
    return apiClient
      .get(`/game/${gameId}`)
      .then((response: AxiosResponse<GameJson>) => {
        const gameJson = response.data
        return Game.fromJson(gameJson)
      })
    /*
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        // @ts-ignore
        const game = apiGameAdapter.adapt(gameDummy)

        resolve(game)
      }, 500)
    })
    */
  }

  static getMyGames(teamId: string): Promise<MyGames> {
    console.log(`GameApi.getMyGames - POST /my-games - teamId`, teamId)

    //    return new Promise((resolve) => {
    //       setTimeout(() => {
    //         const response: AxiosResponse<{
    //           next: GameJson[]
    //           played: GameJson[]
    //         }> = {
    //           data: mockData,
    //           status: 200,
    //           statusText: 'OK',
    //           config: {},
    //           headers: {},
    //         }
    //
    //         const data = {
    //           next: response.data.next.map((gameJson) => Game.fromJson(gameJson)),
    //           played: response.data.played.map((gameJson) =>
    //             Game.fromJson(gameJson)
    //           ),
    //         }
    //
    //         resolve(data)
    //       }, 1000)
    //     })
    return apiClient.post(`/my-games`, { team_id: teamId }).then(
      (
        response: AxiosResponse<{
          next: GameJson[]
          played: GameJson[]
        }>
      ) => {
        return {
          next: response.data.next.map((gameJson) => Game.fromJson(gameJson)),
          played: response.data.played.map((gameJson) =>
            Game.fromJson(gameJson)
          ),
        }
      }
    )
  }

  static getMyNextGames(teamId: string): Promise<Game[]> {
    console.log(`GameApi.getMyNextGames - POST /my-games - teamId`, teamId)
    return apiClient.post(`/my-games`, { team_id: teamId }).then(
      (
        response: AxiosResponse<{
          next: GameJson[]
          played: GameJson[]
        }>
      ) => {
        const nextGamesJson = response.data.next
        return nextGamesJson.map((gameJson) => Game.fromJson(gameJson))
      }
    )

    /*
      .catch((error) => {
        console.log(`/my-games error`, error)
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log('/my-games error.response.data', error.response.data)
          console.log('/my-games error.response.status', error.response.status)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest
          console.log('/my-games error.request', error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('/my-games error.message', error.message)
        }
        console.log('/my-games error.config', error.config)
      })
    */
  }

  static getMyPlayedGames(teamId: string): Promise<Game[]> {
    console.log(`GameApi.getMyPlayedGames - POST /my-games - teamId`, teamId)
    return apiClient.post(`/my-games`, { team_id: teamId }).then(
      (
        response: AxiosResponse<{
          next: GameJson[]
          played: GameJson[]
        }>
      ) => {
        const playedGamesJson = response.data.played
        return playedGamesJson.map((gameJson) => Game.fromJson(gameJson))
      }
    )
  }

  static setGameScore(
    gameId: string,
    score: GameScoreJson,
    status: GameStatus
  ): Promise<{ status: string }> {
    const data = { game_id: gameId, score, status }
    return apiClient.post(`/game/score`, data).then(
      (
        response: AxiosResponse<{
          status: string
        }>
      ) => {
        return response.data
      }
    )
  }

  static getGameCancelStatus(
    gameId: string,
    teamId: string
  ): Promise<GameCancelInfo> {
    return apiClient
      .post(`/game/cancel/status`, { game_id: gameId, team_id: teamId })
      .then(
        (
          response: AxiosResponse<{
            cancellations_count: number
            max_cancellations: number
            message: string
          }>
        ) => {
          return {
            cancellationsCount: response.data.cancellations_count,
            maxCancellations: response.data.max_cancellations,
            message: response.data.message,
          }
        }
      )
  }

  static cancelGame(
    gameId: string,
    teamId: string
  ): Promise<{
    status: string
  }> {
    return apiClient
      .post(`/game/cancel`, { game_id: gameId, team_id: teamId })
      .then(
        (
          response: AxiosResponse<{
            status: string
          }>
        ) => {
          return response.data
        }
      )
  }

  static getOtherTeamGames(teamId: string): Promise<MyGames> {
    console.log(`GameApi.getMyGames - POST /my-games - teamId`, teamId)
    return apiClient.post(`/team/${teamId}/games`).then(
      (
        response: AxiosResponse<{
          next: GameJson[]
          played: GameJson[]
        }>
      ) => {
        return {
          next: response.data.next.map((gameJson) => Game.fromJson(gameJson)),
          played: response.data.played.map((gameJson) =>
            Game.fromJson(gameJson)
          ),
        }
      }
    )
  }
}
