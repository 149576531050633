import * as React from 'react'
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { useMyGames } from '../../../modules/game/useMyGames'
import { Loader } from '../../common/Loader/Loader'
import { Game } from '../../../modules/game/Game'
import { GameCard } from '../../common/GameCard/GameCard'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import {
  Button,
  ButtonSet,
  ButtonSetItem,
  H2,
  P,
  SpacerVertical,
} from '../../ui/ui'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { MeContext } from '../../../contexts/me/MeContext'
import { EmptyList } from '../../common/EmptyList'
import { ErrorMessage } from '../../common/ErrorMessage'
import { isSuccess } from '../../../support/isSuccess'
import { LinearGradient } from 'expo-linear-gradient'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'screens.myGames',
  require('./MyGamesScreen.i18n.json')
)

type Props = MainStackScreenProps<'MyGamesScreen'>

export function MyGamesScreen({}: Props) {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const { trans } = useTranslation()

  const { currentTeam } = React.useContext(MeContext)

  const [filter, setFilter] = React.useState<'upcoming' | 'played'>('upcoming')

  const myGames = useMyGames()
  // TODO MIS PARTIDOS SCREEN

  const Header = () => (
    <View style={styles.headerRow}>
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginHorizontal: -20,
        }}
      >
        <View
          style={{
            backgroundColor:
              filter === 'upcoming' ? theme.colors.primary : 'transparent',
            flex: 1,
            alignItems: 'center',
            paddingVertical: 10,
            borderTopEndRadius: 15,
            borderBottomEndRadius: 15,
            marginRight: 5,
          }}
        >
          <TouchableOpacity onPress={() => setFilter('upcoming')}>
            <P
              style={{
                fontFamily: 'Syne_800ExtraBold',
                fontSize: 16,
                color: filter === 'upcoming' ? '#18362E' : '#A8A8A8',
              }}
            >
              {trans('screens.myGames.Upcoming').toUpperCase()}
            </P>
          </TouchableOpacity>
        </View>

        <View
          style={{
            backgroundColor:
              filter === 'played' ? theme.colors.primary : 'transparent',
            flex: 1,
            alignItems: 'center',
            paddingVertical: 10,
            borderBottomLeftRadius: 15,
            borderTopLeftRadius: 15,
            marginLeft: 5,
          }}
        >
          <TouchableOpacity onPress={() => setFilter('played')}>
            <P
              style={{
                fontFamily: 'Syne_800ExtraBold',
                fontSize: 16,
                color: filter === 'played' ? '#18362E' : '#A8A8A8',
              }}
            >
              {trans('screens.myGames.Played').toUpperCase()}
            </P>
          </TouchableOpacity>
        </View>
      </View>

      <View style={styles.loader}>
        {myGames === 'loading' && <Loader size="md" />}
      </View>
    </View>
  )

  const renderItem = ({ item }: { item: Game }) => (
    <GameCard game={item} highlightedTeamId={currentTeam?.id} />
  )

  console.log(
    `MyGamesScreen myGames`,
    myGames === 'loading'
      ? 'loading'
      : myGames instanceof Error
      ? 'error'
      : 'success',
    isSuccess(myGames)
      ? `myGames.next.length ${myGames.next.length}, myGames.played.length ${myGames.played.length}`
      : ''
  )

  return (
    <View style={{ flex: 1 }}>
      <LinearGradient
        colors={[
          'white',
          'white',
          'white',
          theme.background.color.toString(),
          theme.background.color.toString(),
          theme.background.color.toString(),
        ]}
        style={{
          position: 'absolute',
          height: '120%',
          width: '120%',
        }}
      />
      <FlatList<Game>
        data={
          myGames === 'loading' || myGames instanceof Error
            ? undefined
            : filter === 'played'
            ? myGames.played
            : myGames.next
        }
        renderItem={renderItem}
        ItemSeparatorComponent={() => <SpacerVertical size={20} />}
        ListHeaderComponent={Header}
        ListEmptyComponent={() => {
          if (myGames === 'loading') {
            return null
          } else if (myGames instanceof Error) {
            return (
              <ErrorMessage
                text={trans('screens.myGames.Error')}
                text2={myGames.message}
              />
            )
          } else {
            return <EmptyList text={trans('screens.myGames.EmptyList')} />
          }
        }}
        keyExtractor={(game) => game.id}
        contentContainerStyle={styles.container}
      />
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingVertical: 30,
      paddingHorizontal: theme.grid.gap * 2,
    },
    headerRow: {
      position: 'relative',
    },
    loader: {
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
