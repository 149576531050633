import { parseISO } from 'date-fns'
import { ModelAdapter } from '../../support/interfaces'
import { Tournament, TournamentContentSection } from './Tournament'

export class ApiTournamentAdapter implements ModelAdapter<Tournament> {
  adapt(item: any): Tournament {
    return new Tournament(
      item.id,
      item.name,
      item.image,
      item.site,
      parseISO(item.dateFrom),
      parseISO(item.dateTo),
      item.schedule,
      item.content
    )
  }
}
