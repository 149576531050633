import * as React from 'react'
import { Image, StyleSheet, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { MainStackScreenNavigationProp } from '../../../navigation/MainStackNavigator'
import { Tournament } from '../../../modules/tournament/Tournament'
import { Theme } from '../../ui/themes/Theme'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import * as Animatable from 'react-native-animatable'
import { View } from 'react-native'
import { Button, P } from '../../ui/ui'
import { dateIntervalToHuman } from '../../../support/dates'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { useTranslation } from '../../../hooks/useTranslation'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  tournament: Tournament
  textIsVisible?: boolean
  clickable?: boolean
}

export const TournamentCard = ({
  tournament,
  textIsVisible = true,
  clickable = false,
}: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  const { trans } = useTranslation()

  const { navigate } =
    useNavigation<MainStackScreenNavigationProp<'TournamentScreen'>>()

  return (
    <View style={styles.container}>
      {tournament.image && clickable && (
        <TouchableOpacity
          activeOpacity={0.5}
          onPress={() =>
            navigate('TournamentScreen', { tournamentId: tournament.id })
          }
        >
          <Image style={styles.image} source={{ uri: tournament.image }} />
        </TouchableOpacity>
      )}
      {tournament.image && !clickable && (
        <Image style={styles.image} source={{ uri: tournament.image }} />
      )}
      <Animatable.View
        style={{ opacity: textIsVisible ? 1 : 0 }}
        transition="opacity"
      >
        <P style={styles.name}>{tournament.name}</P>
        <P style={styles.site}>{tournament.site}</P>
        <P style={styles.dates}>
          {dateIntervalToHuman(tournament.dateFrom, tournament.dateTo)}
        </P>

        {clickable && (
          <View style={styles.buttonContainer}>
            <Button
              onPress={() =>
                navigate('TournamentScreen', { tournamentId: tournament.id })
              }
              variant={ButtonVariant.primary}
            >
              {trans('general.Show more')}
            </Button>
          </View>
        )}
      </Animatable.View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {},
    image: {
      width: '100%',
      aspectRatio: 273 / 387,
      borderRadius: 10,
      marginBottom: 20,
    },
    name: {
      fontSize: 18,
      fontFamily: theme.text.fontPrimaryBold,
    },
    site: {
      fontSize: 16,
      color: theme.text.colorGrey,
      fontFamily: theme.text.fontPrimaryBold,
    },
    dates: {
      color: theme.text.colorGrey,
    },
    buttonContainer: {
      marginTop: 10,
      flexDirection: 'row',
    },
  })
