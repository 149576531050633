import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { UserAvatar } from '../UserAvatar/UserAvatar'

interface Props {
  image1?: string | null
  image2?: string | null
  size?: number
  inverse?: boolean
}

export const TeamAvatars = ({
  image1,
  image2,
  size = 48,
  inverse = false,
}: Props) => {
  return (
    <View style={styles.container}>
      <View style={{ zIndex: inverse ? 0 : 1, bottom: inverse ? 30 : 0 }}>
        <UserAvatar
          imageSource={image1 ? { uri: image1 } : undefined}
          size={size}
        />
      </View>

      <View
        style={{
          marginLeft: -size / 4,
          zIndex: inverse ? 1 : 0,
          bottom: inverse ? 0 : 30,
        }}
      >
        <UserAvatar
          imageSource={image2 ? { uri: image2 } : undefined}
          size={size}
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
})
