import * as React from 'react'
import { createStackNavigator, StackScreenProps } from '@react-navigation/stack'
import { ThemeContext } from '../contexts/theme/ThemeContext'
import { stackNavigationOptions } from './stackNavigationOptions'
import { LoginScreen } from '../components/screens/LoginScreen/LoginScreen'
import { PasswordRecoveryScreen } from '../components/screens/PasswordRecoveryScreen/PasswordRecoveryScreen'
import { defaultTheme } from '../components/ui/themes/default/defaultTheme'

type AuthStackParamList = {
  LoginScreen: undefined
  PasswordRecoveryScreen: undefined
}

/**
 * Usage:
 * ```
 * type Props = AuthStackScreenProps<'LoginScreen'>
 *
 * export function LoginScreen({ navigation }: Props) {
 *   ...
 * }
 * ```
 */
export type AuthStackScreenProps<Screen extends keyof AuthStackParamList> =
  StackScreenProps<AuthStackParamList, Screen>

const Stack = createStackNavigator<AuthStackParamList>()

export const AuthStackNavigator = () => {
  const theme = defaultTheme

  return (
    <Stack.Navigator screenOptions={stackNavigationOptions(theme)}>
      <Stack.Screen
        name="LoginScreen"
        component={LoginScreen}
        options={{ title: '' }}
      />
      <Stack.Screen
        name="PasswordRecoveryScreen"
        component={PasswordRecoveryScreen}
        options={{ title: '' }}
      />
    </Stack.Navigator>
  )
}
