import * as React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import * as ExpoImagePicker from 'expo-image-picker'
import * as ImageManipulator from 'expo-image-manipulator'

export const IMAGE_RESIZE_SIZE = 800

interface Props {
  onImageSelected: (localImageUri: string) => void
  aspect?: [number, number]
}

// https://docs.expo.dev/tutorial/image-picker/
// https://docs.expo.dev/versions/latest/sdk/imagepicker/
// https://www.npmjs.com/package/expo-image-picker
export const ImagePicker = ({ onImageSelected, aspect = [1, 1] }: Props) => {
  // According to the docs it's not required to ask for permissions in order to use launchImageLibraryAsync. At
  // https://docs.expo.dev/versions/latest/sdk/imagepicker/#usage it says "No permissions request is necessary for
  // launching the image library", and this comment is placed above the call ImagePicker.launchImageLibraryAsync().
  // The method docs also says "Requires Permissions.MEDIA_LIBRARY on iOS 10 only".
  /*
  React.useEffect(() => {
    const requestPermissions = async () => {
      if (Platform.OS !== 'web') {
        const { status } =
          await ExpoImagePicker.requestMediaLibraryPermissionsAsync()
        if (status !== 'granted') {
          Alert.alert(
            'Se requieren permisos',
            'Necesitamos permisos para acceder a la galería de imágenes'
          )
        }
      }
    }
    requestPermissions()
  }, [])
  */

  const pickImage = React.useCallback(async () => {
    const pickResult = await ExpoImagePicker.launchImageLibraryAsync({
      mediaTypes: ExpoImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: aspect,
      quality: 1,
    })
    if (!pickResult.cancelled) {
      const resizeResult = await ImageManipulator.manipulateAsync(
        pickResult.uri,
        [{ resize: { height: IMAGE_RESIZE_SIZE } }]
      )
      onImageSelected(resizeResult.uri)
    }
  }, [aspect, onImageSelected])

  return <TouchableOpacity style={styles.touchable} onPress={pickImage} />
}

const styles = StyleSheet.create({
  touchable: { position: 'absolute', width: '100%', height: '100%' },
})
