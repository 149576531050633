import * as React from 'react'
import { LeagueApi } from './LeagueApi'
import { LeagueGroupRanking } from './LeagueGroupRanking'
import { Uuid } from '../../support/types'

export function useLeagueGroupRanking(
  leagueGroupId: Uuid
): 'loading' | LeagueGroupRanking {
  const [leagueGroupRanking, setLeagueGroupRanking] = React.useState<
    'loading' | LeagueGroupRanking
  >('loading')

  const find = async (groupId: Uuid) => {
    setLeagueGroupRanking('loading')
    try {
      const groupStandings = await LeagueApi.getGroupStandings(groupId)
      setLeagueGroupRanking(groupStandings)
    } catch (e) {
      console.log(
        `useLeagueGroupRanking - LeagueApi.getGroupStandings error`,
        e
      )
      // TODO return error
    }
  }

  React.useEffect(() => {
    find(leagueGroupId)
  }, [leagueGroupId])

  console.log(`useLeagueGroupRanking league group ID`, leagueGroupId)

  return leagueGroupRanking
}
