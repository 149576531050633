import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { defaultColor, defaultSize, IconProps } from './iconsSettings'

export const IconCheck = ({
  size = defaultSize,
  color = defaultColor,
}: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 511.985 511.985" fill="none">
      <Path
        d="M500.088,83.681c-15.841-15.862-41.564-15.852-57.426,0L184.205,342.148L69.332,227.276
			c-15.862-15.862-41.574-15.862-57.436,0c-15.862,15.862-15.862,41.574,0,57.436l143.585,143.585
			c7.926,7.926,18.319,11.899,28.713,11.899c10.394,0,20.797-3.963,28.723-11.899l287.171-287.181
			C515.95,125.265,515.95,99.542,500.088,83.681z"
        fill={color.toString()}
      />
    </Svg>
  )
}
