import * as React from 'react'
import { Container, H1, LinkButton, P } from '../../ui/ui'
import { View, StyleSheet, Linking } from 'react-native'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { UserAvatar } from '../../common/UserAvatar/UserAvatar'
import { TermsBottomLink } from '../../common/TermsBottomLink/TermsBottomLink'
import { ProfileField } from '../../common/ProfileField/ProfileField'
import {
  IconBall,
  IconPhone,
  IconSpeaker,
  IconUser,
  IconUserId,
} from '../../ui/icons/icons'
import { Expand } from '../../ui/layout/Expand'
import { FullHeightScrollView } from '../../ui/FullHeightScrollView'
import { AuthContext } from '../../../contexts/auth/AuthContext'
import { useThemeContext } from '../../../contexts/theme/ThemeContext'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { ImagePicker } from '../../ui/ui'
import { PictureApi } from '../../../modules/picture/PictureApi'
import { ProgressModal } from '../../common/ProgressModal'
import { IconCamera } from '../../ui/icons/IconCamera'
import { Theme } from '../../ui/themes/Theme'
import { config } from '../../../config/config'
import { HeaderBackButtonProps } from '@react-navigation/elements'
import { HeaderBackButton } from '../../common/HeaderBackButton/HeaderBackButton'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'screens.profile',
  require('./ProfileScreen.i18n.json')
)

const AVATAR_SIZE = 140
const url: string = config.termsUrl

type Props = MainStackScreenProps<'TeamStatsScreen'>

export const ProfileScreen = ({ navigation }: Props) => {
    const theme = defaultTheme
  const { trans } = useTranslation()
  const styles = getStyles(theme)

  const [notificationsEnabled, setNotificationsEnabled] = React.useState(true)
  const [loading, setLoading] = React.useState(false)

  const notificationsOnChange = (value: boolean) => {
    setNotificationsEnabled(value)
  }

  const { user, setUserImage } = React.useContext(AuthContext)

  console.log(`Profile screen user`, user)

  const uploadImage = React.useCallback(
    (localImageUri: string) => {
      setLoading(true)
      PictureApi.storePicture(localImageUri)
        .then((response) => {
          console.log(`ProfileScreen PictureApi.storePicture success`, response)
          const serverImageUrl = response.file_url
          setUserImage(serverImageUrl)
          setLoading(false)
        })
        .catch((error) => {
          console.log(`ProfileScreen PictureApi.storePicture error`, error)
          setLoading(false)
        })
    },
    [setUserImage]
  )

  if (!user) {
    return null
  }
  navigation.setOptions({
    headerLeft: ({ onPress, canGoBack }: HeaderBackButtonProps) =>
      canGoBack && (
        <HeaderBackButton color={theme.colors.greenDark} onPress={onPress} />
      ),
    headerStyle: {
      backgroundColor: theme.colors.primary,
    },
    headerTitleStyle: {
      color: theme.colors.greenDark,
    },
  })

  return (
    <FullHeightScrollView style={{ backgroundColor: theme.colors.primary }}>
      <Container style={styles.container}>
        <View style={styles.avatarContainer}>
          {user.image ? (
            <UserAvatar
              imageSource={{
                uri: user.image,
                width: AVATAR_SIZE,
                height: AVATAR_SIZE,
              }}
              size={AVATAR_SIZE}
            />
          ) : (
            <View
              style={[
                styles.avatarPlaceholder,
                // By using a different background color than the one used by UserAvatar, we can distinguish between
                // the user not having set a profile image yet VS the profile image not loading due to eg a network
                // error or backend failure.
                { backgroundColor: theme.button.default.color },
              ]}
            >
              <IconCamera size={32} />
            </View>
          )}
          <ImagePicker
            onImageSelected={(localImageUri) => {
              console.log(
                `ProfileScreen ImagePicker localImageUri`,
                localImageUri
              )
              uploadImage(localImageUri)
            }}
          />
        </View>

        <P style={{ color: '#62827A' }}>Jugador/a</P>
        <P style={styles.userName}>{user.fullName()}</P>

        <ProfileField
          icon={
            <View style={styles.iconContainer}>
              <IconUser size={19} />
            </View>
          }
          text={user.email}
        />

        <ProfileField
          icon={
            <View style={styles.iconContainer}>
              <IconUser size={19} />
            </View>
          }
          text={user.phone}
        />

        {/*<ProfileField*/}
        {/*  icon={*/}
        {/*    <View style={styles.iconContainer}>*/}
        {/*      <IconUser size={19} />*/}
        {/*    </View>*/}
        {/*  }*/}
        {/*  text={'Liga otoño 2021'}*/}
        {/*/>*/}

        {/*<ProfileField*/}
        {/*  icon={*/}
        {/*    <View style={styles.iconContainer}>*/}
        {/*      <IconUser size={19} />*/}
        {/*    </View>*/}
        {/*  }*/}
        {/*  text={trans('screens.profile.Notifications')}*/}
        {/*  switchValue={notificationsEnabled}*/}
        {/*  switchOnChange={notificationsOnChange}*/}
        {/*/>*/}
        <Expand />
        <P>LaLiguita app</P>
        <LinkButton
          onPress={() => {
            Linking.canOpenURL(url).then((supported) => {
              if (supported) {
                Linking.openURL(url)
              }
            })
          }}
          style={styles.link}
        >
          {trans('termsBottomLink.agree_notice_2')}
        </LinkButton>
      </Container>
      <ProgressModal visible={loading} />
    </FullHeightScrollView>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingTop: 50,
      paddingBottom: 45,
      alignItems: 'center',
    },
    avatarContainer: {
      marginBottom: 30,
    },
    avatarPlaceholder: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      borderRadius: AVATAR_SIZE / 2,
      justifyContent: 'center',
      alignItems: 'center',
    },
    userName: {
      fontSize: 36,
    },
    iconContainer: {
      padding: 10,
      borderRadius: 10,
      marginRight: 10,
      backgroundColor: theme.colors.greenDark,
    },
    link: {
      color: '#18362E',
      textDecorationLine: 'none',
      textAlign: 'center',
    },
  })
