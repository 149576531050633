import * as React from 'react'
import { StyleSheet, View, Text, ColorValue } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../themes/Theme'
import { shadeColor } from '../../../support/shadeColor'
import { IconAlert, IconSuccess } from '../icons/icons'
import { defaultTheme } from '../themes/default/defaultTheme'

type AlertBoxType = 'success' | 'warning' | 'error'

interface Props {
  type: AlertBoxType
  title: string
  description?: string
  children?: React.ReactNode
}

interface TypeStyle {
  color: ColorValue
  icon: React.ReactNode
}

type TypeStyles = {
  warning: TypeStyle
  success: TypeStyle
  error: TypeStyle
}

export const AlertBox = ({ type, title, description, children }: Props) => {
  const theme = defaultTheme

  const typeStyles: TypeStyles = {
    warning: {
      color: theme.colors.warning,
      icon: <IconAlert color={theme.colors.warning} size={45} />,
    },
    success: {
      color: theme.colors.success,
      icon: <IconSuccess color={theme.colors.success} size={45} />,
    },
    error: {
      color: theme.colors.error,
      icon: <IconAlert color={theme.colors.error} size={45} />,
    },
  }

  const styles = getStyles(theme, type, typeStyles)

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>

      <View style={styles.icon}>{typeStyles[type].icon}</View>

      {styles.description && (
        <Text style={styles.description}>{description}</Text>
      )}

      {children}
    </View>
  )
}

const getStyles = (theme: Theme, type: AlertBoxType, typeStyles: TypeStyles) =>
  StyleSheet.create({
    container: {
      width: '100%',
      padding: 30,
      borderRadius: 16,
      alignItems: 'center',
    },
    title: {
      color: '#FC7258',
      textAlign: 'center',
      fontSize: 22,
      fontFamily: theme.text.fontPrimaryBold,
      marginBottom: 14,
      lineHeight: 26,
    },
    description: {
      color: '#62827A',
      textAlign: 'center',
      fontSize: 14,
      lineHeight: 20,
    },
    icon: {
      marginBottom: 14,
    },
  })
