import * as React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { BackHandler } from 'react-native'
import { Category } from '../../../modules/playoff/Playoff'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'leagueGroupSelector',
  require('./LeagueGroupSelector.i18n.json')
)

export interface PlayoffSimple {
  id: string
  name: string
}

interface Props {
  categories: Category[]
  onSelect: (leagueGroup: PlayoffSimple, leagueCategory: Category) => void
  onRequestClose?: () => void
}

export function PlayoffGroupSelector({
  categories,
  onSelect,
  onRequestClose,
}: Props) {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const { trans } = useTranslation()

  BackHandler.addEventListener('hardwareBackPress', () => {
    if (onRequestClose) {
      onRequestClose()
    }
    return true
  })

  if (!categories) {
    return null
  }

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>
          {trans('leagueGroupSelector.Select Playoff')}
        </Text>
      </View>
      {categories.map((category) => (
        <View key={category.id}>
          <View style={styles.league}>
            <View style={styles.leagueLine} />
            <Text style={styles.leagueName}>{category.name}</Text>
            <View style={styles.leagueLine} />
          </View>

          {category.playoffs?.map((playoff) => (
            <View style={styles.leagueGroup} key={playoff.id}>
              <TouchableOpacity onPress={() => onSelect(playoff, category)}>
                <Text style={styles.leagueGroupText}>{playoff.name}</Text>
              </TouchableOpacity>
            </View>
          ))}
        </View>
      ))}
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.background.color,
    },
    header: {
      paddingVertical: 10,
    },
    title: {
      color: theme.colors.primary,
      fontSize: 18,
      fontFamily: theme.text.fontPrimary,
      textAlign: 'center',
    },
    league: {
      flexDirection: 'row',
      alignItems: 'center',
      marginVertical: 20,
    },
    leagueLine: {
      height: 1,
      backgroundColor: theme.colors.primary,
      flex: 1,
    },
    leagueName: {
      color: theme.colors.primary,
      paddingHorizontal: 8,
      fontSize: 16,
      lineHeight: 16,
    },
    leagueGroup: {
      marginVertical: 10,
      alignItems: 'center',
    },
    leagueGroupText: {
      color: theme.text.color,
      fontFamily: theme.text.fontSecondary,
      fontSize: 18,
    },
  })
