import * as React from 'react'
import { tournamentRepo } from '../../services/services'
import { Tournament } from './Tournament'

export const useUpcomingTournaments = () => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [upcomingTournaments, setUpcomingTournaments] = React.useState<
    Tournament[]
  >([])

  const findUpcomingTournaments = async () => {
    setIsLoading(true)

    const upcomingTournaments = await tournamentRepo.upcomingTournaments()

    setUpcomingTournaments(upcomingTournaments)
    setIsLoading(false)
  }

  React.useEffect(() => {
    findUpcomingTournaments()
  }, [])

  return {
    upcomingTournaments,
    isLoading,
  }
}
