import { TeamSimpleJson } from './TeamSimpleJson'
import { TeamSimple } from './TeamSimple'
import { Player } from '../player/Player'

export function teamSimpleMapper(json: TeamSimpleJson): TeamSimple {
  return {
    id: json.id,
    name: json.name,
    rank: json.rank,
    rankingTrend: json.rankingTrend,
    player1: Player.fromJson(json.player1),
    player2: Player.fromJson(json.player2),
  }
}
