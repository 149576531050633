import * as React from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

interface Props {
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
}

export const ButtonSetItem = ({ children, style }: Props) => {
  const theme = defaultTheme

  const combinedStyle = {
    paddingHorizontal: theme.grid.gap / 2,
    ...(style instanceof Object ? style : {}),
  }

  return <View style={combinedStyle}>{children}</View>
}
