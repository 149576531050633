import * as React from 'react'
import {
  Image,
  ImageBackground,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
} from 'react-native'
import { ScoreboardWithBackground } from '../../../common/ScoreboardWithBackground/ScoreboardWithBackground'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { Theme } from '../../../ui/themes/Theme'
import { Sponsors } from '../../../common/Sponsors/Sponsors'
import { dayOfTheWeekName, timeToHuman } from '../../../../support/dates'
import { PlayoffGame } from '../../../../modules/playoff/Playoff'
import { PlayoffScoreboard } from '../../../common/Scoreboard/PlayoffScoreboard'
import { defaultTheme } from '../../../ui/themes/default/defaultTheme'

interface Props {
  game: PlayoffGame
  onPress: () => void
}

export const PlayoffsFinal = ({ game, onPress }: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  if (!game) return null

  return (
    <View style={styles.finalContainer}>
      <View style={styles.mainSponsor}>
        <Image
          style={styles.mainSponsorImage}
          resizeMode="contain"
          source={require('../../../../assets/sampleImages/ads/bullpadel.png')}
        />
      </View>

      <View style={styles.scoreBoardContainer}>
        <TouchableOpacity onPress={onPress}>
          <ScoreboardWithBackground>
            <PlayoffScoreboard
              game={game}
              gameScore={game.score ?? null}
              color="#716F86"
              highlightWinner
            />
          </ScoreboardWithBackground>
        </TouchableOpacity>

        {game.scheduled_on && !game.winner() && (
          <View style={styles.dateContainer}>
            <ImageBackground
              source={require('../images/final-date-bg.png')}
              style={styles.dateContainer}
            >
              <View style={styles.dateInner}>
                <View style={styles.dateText}>
                  <Text style={styles.day}>
                    {new Date(game.scheduled_on).getDate()}{' '}
                    {dayOfTheWeekName(
                      new Date(game.scheduled_on)
                    ).toUpperCase()}
                  </Text>
                  <Text style={styles.time}>
                    {timeToHuman(new Date(game.scheduled_on))}h
                  </Text>
                </View>
              </View>
            </ImageBackground>
          </View>
        )}

        {game.winner() && (
          <View style={styles.confettiContainer}>
            <Image
              style={styles.confetti}
              source={require('../images/confetti.png')}
            />
          </View>
        )}

        {game.winner() && (
          <View style={styles.cupContainer}>
            <Image style={styles.cup} source={require('../images/cup.png')} />
          </View>
        )}

        {game.winner() && (
          <View style={styles.flareContainer}>
            <Image
              style={styles.flare}
              source={require('../images/cup-flare.png')}
            />
          </View>
        )}
      </View>

      {!game.winner() && (
        <View style={styles.sponsors}>{/*<Sponsors />*/}</View>
      )}
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    finalContainer: {
      alignItems: 'center',
      flex: 1,
      marginTop: 100,
    },
    mainSponsor: {
      marginBottom: 30,
    },
    mainSponsorImage: {
      height: 80,
      top: -60,
      width: 200,
    },
    scoreBoardContainer: {
      zIndex: 1,
    },
    dateContainer: {
      width: 220,
      height: 45,
      marginTop: -5,
      zIndex: 0,
      position: 'absolute',
      bottom: -18,
      left: 20,
    },
    dateInner: {
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    dateText: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    day: {
      fontSize: 25,
      color: theme.text.color,
      fontFamily: theme.text.fontFeatured,
      marginRight: 10,
    },
    time: {
      color: theme.text.colorGrey,
    },
    cupContainer: {
      position: 'absolute',
      bottom: -300,
      left: 120,
    },
    cup: {
      aspectRatio: 180 / 376,
      height: 160,
    },
    flareContainer: {
      position: 'absolute',
      bottom: -110,
      left: -20,
      zIndex: -1,
    },
    flare: {
      aspectRatio: 314 / 184,
      height: 200,
    },
    confettiContainer: {
      position: 'absolute',
      bottom: -110,
      left: -100,
      zIndex: -1,
    },
    confetti: {
      aspectRatio: 457 / 548,
      height: 548,
    },
    sponsors: {
      marginTop: 70,
      position: 'absolute',
      top: 400,
    },
  })
