import * as React from 'react'
import { StyleSheet, View, Text, TextInput, Alert } from 'react-native'
import { ColorValue } from 'react-native'
import { TeamScore as TeamScoreInterface } from '../../../modules/game/Game'
import { Theme } from '../../ui/themes/Theme'
import { SetInput } from './partials/SetInput'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  score: TeamScoreInterface
  color?: ColorValue
  fontSize?: number
  editable?: boolean
  onChange?: (score: TeamScoreInterface) => void
}

export const TeamScore = ({
  score,
  color,
  fontSize,
  editable,
  onChange,
}: Props) => {
  const theme = defaultTheme

  const [set1, setSet1] = React.useState(score.set_1)
  const [set2, setSet2] = React.useState(score.set_2)
  const [set3, setSet3] = React.useState(score.set_3)
  const [value, setValue] = React.useState('')
  console.log('sets', set1, set2, set3)
  React.useEffect(() => {
    setSet1(score.set_1)
  }, [score.set_1])

  React.useEffect(() => {
    setSet2(score.set_2)
  }, [score.set_2])

  React.useEffect(() => {
    setSet3(score.set_3)
  }, [score.set_3])

  color = color || theme.text.color

  fontSize = fontSize || theme.text.fontSize
  const styles = getStyles(theme, fontSize, color)

  return (
    <View style={styles.container}>
      <View style={styles.set}>
        <View style={styles.setTextContainer}>
          <Text style={styles.setText}>{set1 !== null ? set1 : '-'}</Text>
        </View>
        {editable && (
          <View style={styles.setInputContainer}>
            <SetInput
              style={{
                fontFamily: theme.text.fontFeatured,
                fontSize: fontSize,
                width: fontSize * 0.9,
                textAlign: 'center',
                lineHeight: fontSize * 1.2,
              }}
              onChange={(value) => {
                setSet1(value)
                if (onChange) {
                  onChange({ set_1: value, set_2: set2, set_3: set3 })
                }
              }}
            />
          </View>
        )}
      </View>

      <View style={styles.set}>
        <View style={styles.setTextContainer}>
          <Text style={styles.setText}>{set2 !== null ? set2 : '-'}</Text>
        </View>
        {editable && (
          <View style={styles.setInputContainer}>
            <SetInput
              style={styles.setInput}
              onChange={(value) => {
                setSet2(value)

                if (onChange) {
                  onChange({ set_1: set1, set_2: value, set_3: set3 })
                }
              }}
            />
          </View>
        )}
      </View>

      <View style={styles.set}>
        <View style={styles.setTextContainer}>
          <Text style={styles.setText}>{set3 !== null ? set3 : '-'}</Text>
        </View>
        {editable && (
          <View style={styles.setInputContainer}>
            <SetInput
              style={styles.setInput}
              onChange={(value) => {
                setSet3(value)

                if (onChange) {
                  onChange({ set_1: set1, set_2: set2, set_3: value })
                }
              }}
            />
          </View>
        )}
      </View>
    </View>
  )
}

const getStyles = (theme: Theme, fontSize: number, color: ColorValue) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      alignItems: 'center',
      top: fontSize * 0.15,
    },
    set: {
      fontSize: fontSize,
      marginRight: fontSize * 0.6,
      position: 'relative',
      height: fontSize * 1.2,
      width: fontSize * 0.5,
    },
    setTextContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    setText: {
      fontFamily: theme.text.fontFeatured,
      fontSize: fontSize,
      color: color,
      width: fontSize * 0.5,
      textAlign: 'center',
      lineHeight: fontSize * 1.2,
    },
    setInputContainer: {
      position: 'absolute',
      top: -fontSize * 0.2,
      left: -fontSize * 0.2,
      opacity: 0,
      width: fontSize * 0.9,
    },
    setInput: {
      fontFamily: theme.text.fontFeatured,
      fontSize: fontSize,
      width: fontSize * 0.9,
      textAlign: 'center',
      lineHeight: fontSize * 1.2,
    },
  })
