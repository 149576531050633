import { Tournament } from './Tournament'
import tournamentsDummy from '../../data/dummy/tournaments.json'
import tournamentDummy from '../../data/dummy/tournament.json'
import { ApiTournamentAdapter } from './ApiTournamentAdapter'
import { Uuid } from '../../support/types'

const apiTournamentAdapter = new ApiTournamentAdapter()

export class ApiTournamentRepo {
  baseUrl: string

  constructor() {
    this.baseUrl = '/tournaments'
  }

  async findById(tournamentId: Uuid): Promise<Tournament> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        // @ts-ignore
        const tournament = apiTournamentAdapter.adapt(tournamentDummy)

        resolve(tournament)
      }, 500)
    })
  }

  async upcomingTournaments(): Promise<Tournament[]> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        // @ts-ignore
        const tournaments = tournamentsDummy.map((item: any) =>
          apiTournamentAdapter.adapt(item)
        )

        resolve(tournaments)
      }, 500)
    })
  }
}
