import { TeamSimple } from '../team/TeamSimple'
import { Uuid } from '../../support/types'
import { GameJson } from './GameJson'
import { teamSimpleMapper } from '../team/teamSimpleMapper'
import { parseISO } from 'date-fns'
import { GameScoreJson } from './GameScoreJson'
import { GameStatus } from './GameStatus'

export interface TeamScore {
  set_1: number | null
  set_2: number | null
  set_3: number | null
}

export class GameScore {
  team1: TeamScore
  team2: TeamScore

  constructor(team1: TeamScore, team2: TeamScore) {
    this.team1 = team1
    this.team2 = team2
  }

  isValid(): boolean {
    const minimumSetsFilled =
      this.team1.set_1 !== null &&
      this.team1.set_2 !== null &&
      this.team2.set_1 !== null &&
      this.team2.set_2 !== null

    const thirdSetIsInBothOrNoOne =
      (this.team1.set_3 === null && this.team2.set_3 === null) ||
      (this.team1.set_3 !== null && this.team2.set_3 !== null)

    return minimumSetsFilled && thirdSetIsInBothOrNoOne
  }

  toJson(team1id: string, team2id: string): GameScoreJson {
    return {
      [team1id]: this.team1,
      [team2id]: this.team2,
    }
  }

  static empty(): GameScore {
    return new GameScore(
      {
        set_1: null,
        set_2: null,
        set_3: null,
      },
      {
        set_1: null,
        set_2: null,
        set_3: null,
      }
    )
  }
}

export class Game {
  id: Uuid
  date: Date
  club: string
  team1: TeamSimple
  team2: TeamSimple
  score: GameScore | null
  status: GameStatus
  resultCanBeSent: boolean
  canBeCancelled: boolean

  constructor(
    id: Uuid,
    date: Date,
    club: string,
    team1: TeamSimple,
    team2: TeamSimple,
    score: GameScore | null,
    status: GameStatus,
    resultCanBeSent: boolean,
    canBeCancelled: boolean
  ) {
    this.id = id
    this.date = date
    this.club = club
    this.team1 = team1
    this.team2 = team2
    this.score = score
    this.status = status
    this.resultCanBeSent = resultCanBeSent
    this.canBeCancelled = canBeCancelled
  }

  static fromJson(json: GameJson): Game {
    const gameScore = json.score
      ? new GameScore(
          json.score[json.first_team.id],
          json.score[json.second_team.id]
        )
      : null
    return new Game(
      json.id,
      parseISO(json.scheduled_on),
      json.club,
      teamSimpleMapper(json.first_team),
      teamSimpleMapper(json.second_team),
      gameScore,
      json.status,
      !!json.resultCanBeSent,
      !!json.canBeCancelled
    )
  }

  winner(): TeamSimple | undefined {
    if (!this.score) {
      return undefined
    }

    const team1SetsWon =
      ((this.score.team1.set_1 || 0) > (this.score.team2.set_1 || 0) ? 1 : 0) +
      ((this.score.team1.set_2 || 0) > (this.score.team2.set_2 || 0) ? 1 : 0) +
      ((this.score.team1.set_3 || 0) > (this.score.team2.set_3 || 0) ? 1 : 0)
    const team2SetsWon =
      ((this.score.team1.set_1 || 0) < (this.score.team2.set_1 || 0) ? 1 : 0) +
      ((this.score.team1.set_2 || 0) < (this.score.team2.set_2 || 0) ? 1 : 0) +
      ((this.score.team1.set_3 || 0) < (this.score.team2.set_3 || 0) ? 1 : 0)

    return team1SetsWon > team2SetsWon ? this.team1 : this.team2
  }

  isPast(): boolean {
    return (
      new Date(this.date).setHours(0, 0, 0, 0) <=
      new Date().setHours(0, 0, 0, 0)
    )
  }

  isToday(): boolean {
    const today = new Date()
    return (
      this.date.getDate() === today.getDate() &&
      this.date.getMonth() === today.getMonth() &&
      this.date.getFullYear() === today.getFullYear()
    )
  }

  isCancelled(): boolean {
    return this.status === 'STATUS_CANCELLED'
  }

  team1ShortNames(): string | undefined {
    if (!this.team1) {
      return undefined
    }
    return `${this.team1.player1.name
      .substr(0, 3)
      .toUpperCase()} / ${this.team1.player2.name.substr(0, 3).toUpperCase()}`
  }

  team2ShortNames(): string | undefined {
    if (!this.team2) {
      return undefined
    }
    return `${this.team2.player1.name
      .substr(0, 3)
      .toUpperCase()} / ${this.team2.player2.name.substr(0, 3).toUpperCase()}`
  }
}
