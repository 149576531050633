import * as React from 'react'
import { TextPropTypes } from '../TextPropTypesInterface'
import {
  defaultTextStyles,
  defaultTextStylesByFontSize,
} from '../defaultTextStyles'
import { Text } from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

export const H1 = (props: TextPropTypes) => {
  const theme = defaultTheme

  const fontSize = props.style?.fontSize || 45

  return (
    <Text
      {...props}
      style={{
        ...defaultTextStylesByFontSize(theme, fontSize),
        fontFamily: theme.text.fontFeatured,
        marginBottom: fontSize * defaultTextStyles(theme).marginEm,
        lineHeight: fontSize * 1.2,
        textTransform: 'uppercase',
        textAlign: props.center ? 'center' : 'auto',
        ...props.style,
      }}
    >
      {props.children}
    </Text>
  )
}
