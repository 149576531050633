import * as React from 'react'
import { H1 } from '../../ui/texts/H1/H1'
import { H2 } from '../../ui/texts/H2/H2'
import { Theme } from '../../ui/themes/Theme'
import { StyleSheet, View } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  title: string
  subtitle?: string
  subtitle2?: string
  marginBottom?: number
}

export const ScreenTitle = ({
  title,
  subtitle,
  subtitle2,
  marginBottom = 30,
}: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  return (
    <View style={[styles.container, { marginBottom }]}>
      <H1 style={styles.title}>{title}</H1>
      {!!subtitle && <H2 style={styles.subtitle}>{subtitle}</H2>}
      {!!subtitle2 && <H2 style={styles.subtitle}>{subtitle2}</H2>}
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {},
    title: {
      fontSize: 24,
      marginBottom: 0,
      fontFamily: 'Syne_800ExtraBold',
    },
    subtitle: {
      marginTop: -5,
      fontSize: 18,
      color: theme.text.colorMuted,
    },
  })
