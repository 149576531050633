import * as React from 'react'
import { StyleProp, View, ViewStyle, Image, Text } from 'react-native'
// import Image from 'react-native-scalable-image'

interface Props {
  club: string
  style?: StyleProp<ViewStyle> | undefined
  size?: number
}

export const ClubImage = ({ club, style, size }: Props) => {
  const clubImageSource = getClubImage(club)
  console.log('debug clubImageSource', clubImageSource)
  return (
    <View style={style}>
      <Image
        style={{
          alignSelf: 'center',
          height: 70,
          width: 150,
        }}
        resizeMode="cover"
        source={clubImageSource}
      />
    </View>
  )
}

const getClubImage = (clubName: string) => {
  const clubImages: { [key: string]: any } = {
    'Indoor Hospitalet': require('../../../assets/images/indoor.png'),
    Polo: require('../../../assets/images/polo.png'),
    Xaloc: require('../../../assets/images/xaloc.png'),
    Uno: require('../../../assets/images/uno.png'),
  }

  return (
    clubImages[clubName.trim()] ||
    require('../../../assets/images/questionMark.png')
  )
}
