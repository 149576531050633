import { Team } from '../team/Team'
import { Uuid } from '../../support/types'

export class LastMinuteMatch {
  id: Uuid
  date: Date
  site: string
  team: Team

  constructor(id: Uuid, date: Date, site: string, team: Team) {
    this.id = id
    this.date = date
    this.site = site
    this.team = team
  }

  isToday(): boolean {
    const today = new Date()
    return (
      this.date.getDate() == today.getDate() &&
      this.date.getMonth() == today.getMonth() &&
      this.date.getFullYear() == today.getFullYear()
    )
  }
}
