import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { shadeColor } from '../../../support/shadeColor'
import { P } from '../../ui/texts/P/P'
import { dateToHuman, timeToHuman } from '../../../support/dates'
import { TeamsVersus } from '../TeamsVersus/TeamsVersus'
import { SpacerVertical } from '../../ui/layout/SpacerVertical/SpacerVertical'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Team } from '../../../modules/team/Team'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  club: string
  date?: Date
  team1?: Team
  team2?: Team
}

export const GameScreenHeader = ({ club, date, team1, team2 }: Props) => {
  const theme = defaultTheme

  return (
    <View style={styles.hero}>
      {/*<LinearGradient*/}
      {/*  colors={[*/}
      {/*    'transparent',*/}
      {/*    shadeColor(theme.background.color.toString(), 120),*/}
      {/*  ]}*/}
      {/*  style={styles.heroGradient}*/}
      {/*/>*/}

      {/*{club && <P style={styles.club}>{club}</P>}*/}

      {/*{date && (*/}
      {/*  <P style={styles.date}>*/}
      {/*    {dateToHuman(date)} {timeToHuman(date)}*/}
      {/*  </P>*/}
      {/*)}*/}

      <View style={{ width: '100%' }}>
        <TeamsVersus showRank team1={team1} team2={team2} />
      </View>
      <SpacerVertical size={40} />
    </View>
  )
}

const styles = StyleSheet.create({
  hero: {
    alignItems: 'center',
  },
  heroGradient: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  club: {
    color: '#858890',
    marginTop: 20,
    marginBottom: 5,
  },
  date: {
    color: '#E2E2E2',
    marginBottom: 20,
  },
})
