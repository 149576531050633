import * as React from 'react'
import { Theme } from '../../ui/themes/Theme'
import { StyleSheet, View, Text } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { H1 } from '../../ui/ui'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'cancellationsBox',
  require('./CancellationsBox.i18n.json')
)

interface Props {
  count: number
  max: number
}

export const CancellationsBox = ({ count, max }: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  const { trans } = useTranslation()

  return (
    <View style={styles.container}>
      {/*<H1 style={styles.title}>{trans('cancellationsBox.Cancellations')}</H1>*/}
      <View style={styles.right}>
        <View style={styles.down}>
          {/*<View style={styles.barContainer}>*/}
          {/*  <View*/}
          {/*    style={[*/}
          {/*      styles.bar,*/}
          {/*      { width: `${((count > max ? max : count) / max) * 100}%` },*/}
          {/*    ]}*/}
          {/*  />*/}
          {/*</View>*/}
          <View style={styles.quantity}>
            <Text style={styles.quantityText}>
              {count}/{max}
            </Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      padding: 22,
      alignItems: 'center',
      borderRadius: 12,
    },
    image: {
      backgroundColor: theme.colors.error,
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: 20,
      width: 44,
      height: 44,
      borderRadius: 22,
    },
    imageRectangle: {
      backgroundColor: theme.dark ? '#FFFFFF' : '#000000',
      height: 7,
      width: 30,
      borderRadius: 2,
    },
    right: {},
    title: {
      color: theme.colors.primary,
      fontSize: 22,
      fontFamily: 'Syne_800ExtraBold',
    },
    down: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    barContainer: {
      flexDirection: 'row',
      backgroundColor: theme.dark ? '#FFFFFF22' : '#00000022',
      height: 4,
      borderRadius: 2,
      width: '75%',
      marginRight: 10,
      position: 'relative',
    },
    bar: {
      backgroundColor: theme.colors.error,
      height: 4,
      borderRadius: 2,
    },
    quantity: {},
    quantityText: {
      color: theme.colors.primary,
      fontSize: 50,
    },
  })
