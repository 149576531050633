import { apiClient } from '../../support/apiClient'
import { Platform } from 'react-native'
import { AxiosResponse } from 'axios'

type StorePictureResponse = {
  /** "1b63558a-feeb-4d89-9615-ce2025dc6ef4" */
  id: string
  /** "fa0c0018ef9c06c9945a9490249ce2ce.jpg" */
  file_name: string
  /** "https://prod-api-laliguita.s3.eu-central-1.amazonaws.com/uploads/users/1b63558a-feeb-4d89-9615-ce2025dc6ef4/fa0c0018ef9c06c9945a9490249ce2ce.jpg" */
  file_url: string
  mime_type: 'image/jpeg'
  /** 507731 */
  size: number
  conversions: {
    /** "https://prod-api-laliguita.s3.eu-central-1.amazonaws.com/uploads/users/1b63558a-feeb-4d89-9615-ce2025dc6ef4/conversions/medium_thumbnail.jpg" */
    medium_thumbnail: string
    /** "https://prod-api-laliguita.s3.eu-central-1.amazonaws.com/uploads/users/1b63558a-feeb-4d89-9615-ce2025dc6ef4/conversions/small_thumbnail.jpg */
    small_thumbnail: string
  }
  custom_properties: []
}

export class PictureApi {
  static async storePicture(fileUri: string): Promise<StorePictureResponse> {
    const requestBody = new FormData()
    const response = await fetch(fileUri)
    const blob = await response.blob()
    requestBody.append('file', blob, 'profile')

    return apiClient
      .post(`/picture/store`, requestBody, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response: AxiosResponse<StorePictureResponse>) => {
        return response.data
      })
  }
}
