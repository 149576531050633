import * as React from 'react'
import { Loader } from '../../common/Loader/Loader'
import { View } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

export const LoadingScreen = () => {
  const theme = defaultTheme

  return (
    <View
      style={{
        backgroundColor: theme.background.color,
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader />
    </View>
  )
}
