import { apiClient } from '../../support/apiClient'
import { AxiosResponse } from 'axios'
import { TeamStats } from './TeamStats'
import { TeamStatsJson } from './TeamStatsJson'

export class StatisticsApi {
  static getTeamStatistics(teamId: string): Promise<TeamStats> {
    return apiClient.post(`/statistics`, { team_id: teamId }).then(
      (
        response: AxiosResponse<{
          statistics: TeamStatsJson
        }>
      ) => {
        const teamStatsJson = response.data.statistics
        return TeamStats.fromJson(teamId, teamStatsJson)
      }
    )
  }
}
