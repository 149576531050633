import * as React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

type Props = {
  style?: StyleProp<ViewStyle>
  children: React.ReactNode
}

export const Container = ({ style, children }: Props) => {
  const theme = defaultTheme

  return (
    <View
      style={[
        {
          paddingHorizontal: theme.grid.gap * 2,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}
