import * as React from 'react'
import Svg, { Defs, LinearGradient, Stop, Path } from 'react-native-svg'
import { defaultColor, defaultSize, IconProps } from './iconsSettings'

export const IconAlert = ({
  size = defaultSize,
  color = defaultColor,
}: IconProps) => (
  <Svg width={23} height={61}>
    <Defs>
      <LinearGradient id="a" x1="50%" x2="50%" y1="0%" y2="100%">
        <Stop offset="0%" stopColor="#FEAA91" stopOpacity={0} />
        <Stop offset="100%" stopColor="#FC7258" />
      </LinearGradient>
    </Defs>
    <Path
      fill="url(#a)"
      fillRule="nonzero"
      stroke="#FC7258"
      strokeDasharray="0,0"
      d="M218.125 562.66v-41.31h-21.25v41.31h21.25Zm.17 17.34v-12.835h-21.59V580h21.59Z"
      transform="translate(-196 -520)"
    />
  </Svg>
)
