import * as React from 'react'
import { Playoff, PlayoffGame, PlayoffLeague } from './Playoff'
import { Uuid } from '../../support/types'
import { extractApiError } from '../../support/apiError'
import { PlayoffApi } from './PlayoffApi'
import { Game } from '../game/Game'

export const usePlayoffByTeamId = (teamId?: Uuid) => {
  const [playoff, setPlayoff] = React.useState<Playoff | 'loading' | Error>(
    'loading'
  )

  const findByTeamId = async (playoffId: Uuid) => {
    PlayoffApi.getPlayoff(playoffId)
      .then((playoffData: Playoff) => {
        console.log('hay playoffs', playoffData)
        setPlayoff(playoffData)
      })
      .catch((error) => {
        console.log(
          `usePlayoffByLeagueGroupId findByLeagueGroupId.getPlayoff error`,
          error
        )
        setPlayoff(extractApiError(error))
      })
  }

  React.useEffect(() => {
    if (!teamId) return

    findByTeamId(teamId)
  }, [teamId])

  return playoff
}

export const usePlayoffsLeagues = (teamId?: Uuid) => {
  const [playoff, setPlayoff] = React.useState<
    PlayoffLeague | 'loading' | Error
  >('loading')

  const findByTeamId = async (playoffId: Uuid) => {
    PlayoffApi.getPlayoffs(playoffId)
      .then((playoffData: PlayoffLeague) => {
        setPlayoff(playoffData)
      })
      .catch((error) => {
        console.log(`usePlayoffsLeagues findByTeamId.getPlayoff error`, error)
        setPlayoff(extractApiError(error))
      })
  }

  React.useEffect(() => {
    if (!teamId) return

    findByTeamId(teamId)
  }, [teamId])

  return playoff
}

export const useShowPlayoff = (playoffId?: Uuid) => {
  const [playoff, setPlayoff] = React.useState<Playoff | 'loading' | Error>()

  const findByPlayoffId = async (id: Uuid) => {
    setPlayoff('loading')
    PlayoffApi.showPlayoff(id)
      .then((playoffData: Playoff) => {
        setPlayoff(playoffData)
      })
      .catch((error) => {
        console.log(`usePlayoffs findByTeamId.getPlayoff error`, error)
        setPlayoff(extractApiError(error))
      })
  }

  React.useEffect(() => {
    if (!playoffId) return

    findByPlayoffId(playoffId)
  }, [playoffId])

  return playoff
}

export const useGetPlayoffGame = (playoffId?: Uuid) => {
  console.log('useGetPlayoffGame calling....')
  const [playoff, setPlayoff] = React.useState<
    { game: PlayoffGame; reloadGame: () => void } | 'loading' | Error
  >()

  const getGame = (id: Uuid) => {
    setPlayoff('loading')
    PlayoffApi.getGamePlayoff(id)
      .then((playoffData: PlayoffGame) => {
        setPlayoff({ game: playoffData, reloadGame: () => getGame(id) })
      })
      .catch((error) => {
        console.log(`useGetPlayoffGame.findByPlayoffId error`, error)
        setPlayoff(extractApiError(error))
      })
  }

  React.useEffect(() => {
    if (!playoffId) return

    getGame(playoffId)
  }, [playoffId])

  return playoff
}
