import React, { useEffect, useRef } from 'react'
import { View, StyleSheet, Animated, TextStyle } from 'react-native'
import { ThemeContext } from '../../contexts/theme/ThemeContext'
import { Theme } from '../ui/themes/Theme'

type ProgressBarColumnsProps = {
  percentage: number
  width: number
}
const array = Array.from({ length: 10 }, (_, index) => index + 1)

const ProgressBarColumns = (props: ProgressBarColumnsProps) => {
  const percentage = props.percentage === 0 ? 1 : props.percentage * 10
  const progressAnim = useRef(new Animated.Value(0)).current
  const progressAnim2 = useRef(new Animated.Value(0)).current

  useEffect(() => {
    Animated.timing(progressAnim, {
      toValue: percentage,
      duration: 1300,
      useNativeDriver: false,
    }).start()

    setTimeout(() => {
      Animated.timing(progressAnim2, {
        toValue: percentage,
        duration: 1300,
        useNativeDriver: false,
      }).start()
    }, 700)
  }, [percentage])

  return (
    <View style={{ flexDirection: 'row', width: `${props.width}%` }}>
      {array.map((item) => {
        let bgColor = progressAnim2.interpolate({
          inputRange: [item - 1, item],
          outputRange: ['#D8D8D8', '#A3FC6F'],
          extrapolate: 'clamp',
        })

        if (props.percentage === 0) {
          // @ts-ignore
          bgColor = '#D8D8D8'
        }

        const widthValue = progressAnim.interpolate({
          inputRange: [0, 1],
          outputRange: ['0%', '7%'],
          extrapolate: 'clamp',
        })

        return (
          <Animated.View
            style={{
              height: 80,
              marginRight: '1.5%',
              width: widthValue,
              borderRadius: 10,
              backgroundColor: bgColor,
            }}
          />
        )
      })}
    </View>
  )
}

export default ProgressBarColumns
