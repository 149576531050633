import * as React from 'react'
import { GestureResponderEvent, TouchableOpacity, View } from 'react-native'
import { IconDropdownCaret } from '../../icons/icons'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

interface Props {
  isActive: boolean
  onPress: (event: GestureResponderEvent) => void
}

export const DropdownButton = ({ isActive, onPress }: Props) => {
  const theme = defaultTheme

  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          borderWidth: 2,
          borderColor: theme.dark ? '#FFFFFF88' : '#000000AA',
          padding: 7,
          borderRadius: 8,
        }}
      >
        <View
          style={{
            transform: [{ rotate: `${isActive ? 180 : 0}deg` }],
            // rotation: isActive ? 180 : 0
          }}
        >
          <IconDropdownCaret />
        </View>
      </View>
    </TouchableOpacity>
  )
}
