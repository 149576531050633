import * as React from 'react'
import { createDrawerNavigator } from '@react-navigation/drawer'

import { MainStackNavigator } from './MainStackNavigator'
import { AppDrawerNavigationContent } from './AppDrawerNavigatorContent'
import { NotificationsContextProvider } from '../contexts/notifications/NotificationsContext'
import { MeContextProvider } from '../contexts/me/MeContext'
import { usePushNotifications } from '../support/push-notifications'

const Drawer = createDrawerNavigator()

export const AppDrawerNavigator = () => {
  // Push setup needs to be placed here, not in App. If we put it in App we get the error
  // "Error: Couldn't find a navigation object. Is your component inside NavigationContainer?"
  // because of the usage of useNavigation in usePushNotifications.
  // usePushNotifications()

  return (
    // We place the MeContextProvider and NotificationsContextProvider here instead of App because the calls
    // 'GET /my-teams' and 'GET /notifications' require a token, which we don't have when the app starts from a fresh
    // install. In addition, the context is destroyed when we log out and re-created when logging in, so we don't have
    // to worry about the user changing inside the context.
    <MeContextProvider>
      <NotificationsContextProvider>
        <Drawer.Navigator
          screenOptions={{
            headerShown: false,
            drawerPosition: 'right',
            drawerType: 'front',
            drawerStyle: { width: '100%' },
          }}
          drawerContent={(props) => <AppDrawerNavigationContent {...props} />}
        >
          <Drawer.Screen
            name="MainStackNavigator"
            component={MainStackNavigator}
          />
        </Drawer.Navigator>
      </NotificationsContextProvider>
    </MeContextProvider>
  )
}
