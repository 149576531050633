import { LeagueCategory } from './LeagueCategory'

/*
Response of POST /groups {"team_id": "..."}:
{
  "league": {
    "id": "c4dc3e1d-20f8-44ae-92d3-aa2cd6605b2f",
    "name": "Liga Otoño 21",
    "categories": [
      {
        "id": "40f3ec5e-ef0c-11eb-ae27-0242ac130003",
        "name": "masculino",
        "groups": [
          {
            "id": "0d5b0c9d-a37a-4624-b26b-156dada047df",
            "name": "A1"
          },
          {
            "id": "90e00b69-3e05-4f6e-bd7c-077a02e7911b",
            "name": "B1"
          },
          ...
*/

export class League {
  id: string
  /** Liga Otoño 21 */
  name: string
  categories: LeagueCategory[]

  constructor(id: string, name: string, categories: LeagueCategory[]) {
    this.id = id
    this.name = name
    this.categories = categories
  }
}
