import * as React from 'react'
import { StyleSheet, View } from 'react-native'
import { ThemeContext } from '../../contexts/theme/ThemeContext'
import { P } from '../ui/texts/P/P'
import { Theme } from '../ui/themes/Theme'
import { defaultTheme } from '../ui/themes/default/defaultTheme'

type Props = {
  text: string
  text2?: string
}

export function ErrorMessage({ text, text2 }: Props) {
  const theme = defaultTheme
  const styles = getStyles(theme)

  return (
    <View style={styles.container}>
      <P style={styles.text}>{text}</P>
      {text2 && <P style={styles.text}>{text2}</P>}
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      alignItems: 'center',
    },
    text: {
      color: theme.colors.error,
    },
  })
