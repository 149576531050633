import * as React from 'react'
import Svg, { G, Path } from 'react-native-svg'
import { defaultColor, defaultSize, IconProps } from './iconsSettings'

export const IconPlus = ({
  size = defaultSize,
  color = defaultColor,
}: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 512 512">
      <G id="_03_Login" data-name="03 Login">
        <Path
          d="m256 512a25 25 0 0 1 -25-25v-462a25 25 0 0 1 50 0v462a25 25 0 0 1 -25 25z"
          fill={color.toString()}
        />
        <Path
          d="m487 281h-462a25 25 0 0 1 0-50h462a25 25 0 0 1 0 50z"
          fill={color.toString()}
        />
      </G>
    </Svg>
  )
}
