import * as React from 'react'
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { MainStackScreenNavigationProp } from '../../../../navigation/MainStackNavigator'
import { useNavigation } from '@react-navigation/native'
import { LeagueGroupRanking } from '../../../../modules/league/LeagueGroupRanking'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { Theme } from '../../../ui/themes/Theme'
import { shadeColor } from '../../../../support/shadeColor'
import { Container } from '../../../ui/ui'
import { Uuid } from '../../../../support/types'
import { defaultTheme } from '../../../ui/themes/default/defaultTheme'

interface Props {
  ranking: LeagueGroupRanking
  myTeamsIds?: Uuid[]
}

export const RankingTable = ({ ranking }: Props) => {
  const theme = defaultTheme
  const styles = getStyles(theme)
  const { navigate } =
    useNavigation<MainStackScreenNavigationProp<'RankingScreen'>>()

  return (
    <View style={styles.container}>
      <Container style={styles.headerRow}>
        <View>
          <Text style={[styles.text, styles.textColorGrey]}>#</Text>
        </View>
        <View style={styles.colTeam}>
          <Text style={[styles.text, styles.textColorGrey]}>Pareja</Text>
        </View>
        <View style={styles.colNumber}>
          <Text style={[styles.text, styles.textColorGrey]}>E</Text>
        </View>
        <View style={styles.colNumber}>
          <Text style={[styles.text, styles.textColorGrey]}>PJ</Text>
        </View>
        <View style={styles.colNumber}>
          <Text style={[styles.text, styles.textColorGrey]}>PG</Text>
        </View>
        <View style={styles.colNumber}>
          <Text style={[styles.text, styles.textColorGrey]}>PP</Text>
        </View>
        <View style={styles.colNumber}>
          <Text style={[styles.text, styles.textColorGrey]}>PTS</Text>
        </View>
      </Container>
      {ranking.map((position, index) => (
        <TouchableOpacity
          onPress={() =>
            navigate('TeamStatsScreen', { teamId: position.teamId })
          }
          key={index}
        >
          <Container
            style={[
              styles.bodyRow,
              // index % 2 === 0 ? styles.bodyRowEven : styles.bodyRowOdd,
            ]}
          >
            <View>
              <Text style={[styles.text, styles.textColor]}>
                {position.position}
              </Text>
            </View>
            <View style={styles.colTeam}>
              <Text style={[styles.text, styles.textColor]}>
                {position.teamPlayerNames}
              </Text>
            </View>

            <View style={index === 0 ? styles.colHighlighted : {}} />

            <View style={[styles.colPoints]}>
              <Text style={[styles.text, styles.textColorGrey]}>
                {position.draw}
              </Text>
            </View>
            <View style={styles.colNumber}>
              <Text style={[styles.text, styles.textColorGrey]}>
                {position.played}
              </Text>
            </View>
            <View style={styles.colNumber}>
              <Text style={[styles.text, styles.textColorGrey]}>
                {position.won}
              </Text>
            </View>
            <View style={styles.colNumber}>
              <Text style={[styles.text, styles.textColorGrey]}>
                {position.lost}
              </Text>
            </View>
            <View style={styles.colNumber}>
              <Text style={[styles.text, styles.textColor]}>
                {position.points}
              </Text>
            </View>
          </Container>
        </TouchableOpacity>
      ))}
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      marginHorizontal: 10,
    },
    headerRow: {
      flexDirection: 'row',
      height: 30,
      alignItems: 'center',
      backgroundColor: '#18362E',
      borderRadius: 20,
      marginBottom: 10,
    },
    colTeam: {
      flex: 1,
      paddingLeft: 10,
    },
    colNumber: {
      width: 33,
      alignItems: 'center',
    },
    colPoints: {
      width: 33,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'stretch',
    },
    colHighlighted: {
      backgroundColor: theme.colors.primary,
      position: 'absolute',
      height: 30,
      width: 75,
      zIndex: -1,
      right: -20,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
    },
    bodyRow: {
      flexDirection: 'row',
      width: '100%',
      alignItems: 'center',
      height: 44,
    },
    bodyRowEven: {
      backgroundColor: shadeColor(theme.background.color.toString(), 70),
    },
    bodyRowOdd: {
      backgroundColor: shadeColor(theme.background.color.toString(), 30),
    },
    text: {
      fontFamily: theme.text.fontPrimarySemiBold,
      fontSize: 14,
    },
    textColor: {
      color: theme.text.color,
    },
    textColorGrey: {
      color: theme.text.colorGrey,
    },
    textBackgroundColor: {
      color: theme.background.color,
    },
  })
