import * as React from 'react'
import { Team } from '../../modules/team/Team'
import { meReducer } from './meReducer'
import { AuthContext } from '../auth/AuthContext'
import { meRepo } from '../../services/services'
import { TeamApi } from '../../modules/team/TeamApi'
import { Alert } from 'react-native'

interface MeContextProps {
  teams?: Team[]
  currentTeam: Team | undefined
  setTeams: (teams: Team[]) => void
  setCurrentTeam: (team: Team) => void
}

export const MeContext = React.createContext({} as MeContextProps)

export const useMeContext = () => React.useContext(MeContext)

export const MeContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [me, dispatch] = React.useReducer(
    meReducer,
    {
      teams: undefined,
      currentTeam: undefined,
    },
    (initialState) => {
      console.log(`MeContextProvider initialized`)
      return initialState
    }
  )

  const { user } = React.useContext(AuthContext)

  const setTeams = (teams: Team[]) => {
    dispatch({ type: 'setTeams', payload: teams })
  }

  const setCurrentTeam = (team: Team) => {
    dispatch({ type: 'setCurrentTeam', payload: team })
  }

  /*
  React.useEffect(() => {
    meRepo.findMe().then((me) => {
      setTeams(me.teams)
    })
  }, [user])
  */

  React.useEffect(() => {
    // It's important to check the user id, not the whole user, because in AuthContext we refresh the user data when the
    // app starts, and this refresh of the user there would trigger this hook again and perform an extra useless call to
    // get my teams again.
    if (user?.id) {
      const getMyTeams = () => {
        TeamApi.getMyTeams()
          .then((teams) => {
            console.log(`MeContext setTeams`, teams)
            setTeams(teams)
          })
          .catch((error) => {
            console.log(`getMyTeams error`, error)
            Alert.alert('Error al obtener datos requeridos', undefined, [
              { text: 'OK' },
              {
                text: 'Reintentar',
                onPress: getMyTeams,
              },
            ])
          })
      }
      getMyTeams()
    }
  }, [user?.id])

  return (
    <MeContext.Provider
      value={{
        ...me,
        setTeams,
        setCurrentTeam,
      }}
    >
      {children}
    </MeContext.Provider>
  )
}
