import * as React from 'react'
import { FlatList, StyleSheet, View } from 'react-native'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { Loader } from '../../common/Loader/Loader'
import { Game } from '../../../modules/game/Game'
import { GameCard } from '../../common/GameCard/GameCard'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { Button, ButtonSet, ButtonSetItem, SpacerVertical } from '../../ui/ui'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { MeContext } from '../../../contexts/me/MeContext'
import { EmptyList } from '../../common/EmptyList'
import { ErrorMessage } from '../../common/ErrorMessage'
import { isSuccess } from '../../../support/isSuccess'
import { useOtherTeamGames } from '../../../modules/game/useOtherTeamGames'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'screens.myGames',
  require('./OtherTeamGamesScreen.i18n.json')
)

type Props = MainStackScreenProps<'OtherTeamGamesScreen'>

export function OtherTeamGamesScreen({ route }: Props) {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const { trans } = useTranslation()

  const currentTeam = route.params.teamId

  const [filter, setFilter] = React.useState<'upcoming' | 'played'>('upcoming')

  const myGames = useOtherTeamGames(currentTeam)

  const Header = () => (
    <View style={styles.headerRow}>
      <ButtonSet>
        <ButtonSetItem>
          <Button
            variant={
              filter === 'upcoming'
                ? ButtonVariant.primary
                : ButtonVariant.transparent
            }
            onPress={() => setFilter('upcoming')}
          >
            {trans('screens.myGames.Upcoming')}
          </Button>
        </ButtonSetItem>
        <ButtonSetItem>
          <Button
            variant={
              filter === 'played'
                ? ButtonVariant.primary
                : ButtonVariant.transparent
            }
            onPress={() => setFilter('played')}
          >
            {trans('screens.myGames.Played')}
          </Button>
        </ButtonSetItem>
      </ButtonSet>

      <View style={styles.loader}>
        {myGames === 'loading' && <Loader size="md" />}
      </View>
    </View>
  )

  const renderItem = ({ item }: { item: Game }) => (
    <GameCard game={item} highlightedTeamId={currentTeam} />
  )

  console.log(
    `MyGamesScreen myGames`,
    myGames === 'loading'
      ? 'loading'
      : myGames instanceof Error
      ? 'error'
      : 'success',
    isSuccess(myGames)
      ? `myGames.next.length ${myGames.next.length}, myGames.played.length ${myGames.played.length}`
      : ''
  )

  return (
    <FlatList<Game>
      data={
        myGames === 'loading' || myGames instanceof Error
          ? undefined
          : filter === 'played'
          ? myGames.played
          : myGames.next
      }
      renderItem={renderItem}
      ItemSeparatorComponent={() => <SpacerVertical size={20} />}
      ListHeaderComponent={Header}
      ListEmptyComponent={() => {
        if (myGames === 'loading') {
          return null
        } else if (myGames instanceof Error) {
          return (
            <ErrorMessage
              text={trans('screens.myGames.Error')}
              text2={myGames.message}
            />
          )
        } else {
          return <EmptyList text={trans('screens.myGames.EmptyList')} />
        }
      }}
      keyExtractor={(game) => game.id}
      contentContainerStyle={styles.container}
    />
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingVertical: 30,
      paddingHorizontal: theme.grid.gap * 2,
    },
    headerRow: {
      position: 'relative',
    },
    loader: {
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
