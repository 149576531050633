import { apiClient } from '../../support/apiClient'
import { AxiosResponse } from 'axios'
import { LastMinute } from './LastMinute'
import { GameJson } from '../game/GameJson'

export class LastMinuteApi {
  static confirmGameLastMinute(id: string, teamId: string): Promise<GameJson> {
    return apiClient
      .post(`/game-last-minute/${id}/confirm`, { team_id: teamId })
      .then((response: AxiosResponse<GameJson>) => {
        return response.data
      })
  }

  static getLastMinuteMatches(teamId: string): Promise<LastMinute[]> {
    console.log(
      `LastMinuteApi.getLastMinuteMatches - POST /last-minute - teamId`,
      teamId
    )
    return apiClient.post(`/last-minute`, { team_id: teamId }).then(
      (
        response: AxiosResponse<{
          last_minute: LastMinute[]
        }>
      ) => {
        return response.data.last_minute
      }
    )
  }

  static getLastMinuteMatch(lastMinuteId: string): Promise<LastMinute> {
    console.log(
      `LastMinuteApi.getLastMinuteMatches - POST /last-minute - teamId`
    )
    return apiClient
      .get(`/game-last-minute/${lastMinuteId}`)
      .then((response: AxiosResponse<LastMinute>) => {
        return response.data
      })
  }
}
