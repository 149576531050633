import * as React from 'react'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { Theme } from '../../../ui/themes/Theme'
import { StyleSheet, View, Text } from 'react-native'
import { shadeColor } from '../../../../support/shadeColor'
import { dayOfTheWeekName, timeToHuman } from '../../../../support/dates'
import { PlayoffGame } from '../../../../modules/playoff/Playoff'
import { TeamScore1Set } from '../../../common/TeamScore/TeamScore1Set'
import { defaultTheme } from '../../../ui/themes/default/defaultTheme'

interface Props {
  game: PlayoffGame
}

export const PlayoffsMatch = ({ game }: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  if (!game) return <View />

  console.log('game', game)
  return (
    <View style={styles.container}>
      <View style={styles.leftBorder} />

      {/* Teams */}
      <View style={styles.teams}>
        <View style={[styles.team, styles.team1]}>
          {game.first_team ? (
            <Text style={styles.playersName}>{game.team1ShortNames()}</Text>
          ) : (
            <Text style={styles.playersNameEmpty}>- / -</Text>
          )}
        </View>

        <View style={[styles.team, styles.team2]}>
          {game.second_team ? (
            <Text style={styles.playersName}>{game.team2ShortNames()}</Text>
          ) : (
            <Text style={styles.playersNameEmpty}>- / -</Text>
          )}
        </View>
      </View>

      <View style={styles.right}>
        {/* Score */}
        {game.score && (
          <View style={styles.scoreContainer}>
            <View style={styles.scoreTeam}>
              <TeamScore1Set
                score={
                  game.score[game.first_team.id]?.set_1 as unknown as string
                }
                color={theme.playoffs.scoreboard.color}
                fontSize={25}
              />
            </View>
            <View style={styles.scoreTeam}>
              <TeamScore1Set
                score={
                  game.score[game.second_team.id]?.set_1 as unknown as string
                }
                color={theme.playoffs.scoreboard.color}
                fontSize={25}
              />
            </View>
          </View>
        )}

        {/*  Date if not played */}
        {!game.score && game.scheduled_on && (
          <View style={styles.dateContainer}>
            <Text style={styles.dayNumber}>
              {new Date(game.scheduled_on).getDate()}
            </Text>
            <Text style={styles.dayName}>
              {dayOfTheWeekName(new Date(game.scheduled_on)).toUpperCase()}
            </Text>
            <Text style={styles.time}>
              {timeToHuman(new Date(game.scheduled_on))}h
            </Text>
          </View>
        )}

        {/*  No Date & not played */}
        {!game.score && !game.scheduled_on && (
          <View style={styles.dateContainer}>
            <Text style={styles.dayNumber}> FECHA</Text>
            <Text style={styles.dayName}> a confirmar</Text>
          </View>
        )}
      </View>

      <View style={styles.winnerContainer}>
        {game.winner() && (
          <>
            {game.winner() !== game.first_team && (
              <View style={styles.winnerLoser} />
            )}
            <View style={styles.winnerVictory}>
              <Text style={styles.winnerVictoryText}>V</Text>
            </View>
            {game.winner() !== game.second_team && (
              <View style={styles.winnerLoser} />
            )}
          </>
        )}
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      height: 88,
      width: 295,
      flexDirection: 'row',
    },
    leftBorder: {
      width: 5,
      backgroundColor: '#404150',
    },
    teams: {
      flex: 1,
    },
    team: {
      flex: 1,
      justifyContent: 'center',
      paddingLeft: 15,
    },
    team1: {
      backgroundColor: shadeColor(
        theme.background.color.toString(),
        theme.dark ? 40 : -40
      ),
    },
    team2: {
      backgroundColor: shadeColor(
        theme.background.color.toString(),
        theme.dark ? 80 : -80
      ),
    },
    playersName: {
      color: theme.text.color,
      fontFamily: theme.text.fontPrimarySemiBold,
      fontSize: 18,
    },
    playersNameEmpty: {
      fontFamily: theme.text.fontPrimarySemiBold,
      fontSize: 18,
      alignSelf: 'center',
      color: 'white',
    },
    right: {
      flex: 1,
    },
    scoreContainer: {
      backgroundColor: theme.playoffs.scoreboard.backgroundColor,
      flex: 1,
    },
    scoreTeam: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    dateContainer: {
      borderColor: shadeColor(
        theme.background.color.toString(),
        theme.dark ? 150 : -150
      ),
      backgroundColor: theme.background.color,
      borderWidth: 2,
      flex: 1,
      width: '100%',
      paddingHorizontal: 10,
      justifyContent: 'center',
    },
    dayNumber: {
      fontSize: 25,
      color: theme.text.color,
      fontFamily: theme.text.fontFeatured,
      lineHeight: 30,
    },
    dayName: {
      fontSize: 20,
      color: theme.text.color,
      fontFamily: theme.text.fontFeatured,
      lineHeight: 25,
    },
    time: {
      color: theme.text.colorGrey,
    },
    winnerContainer: {
      width: 33,
    },
    winnerVictory: {
      backgroundColor: theme.colors.primary,
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
    },
    winnerVictoryText: {
      color: theme.playoffs.scoreboard.color,
      fontFamily: theme.text.fontFeatured,
      fontSize: 25,
    },
    winnerLoser: {
      flex: 1,
      backgroundColor: shadeColor(
        theme.playoffs.scoreboard.backgroundColor.toString(),
        -20
      ),
    },
  })
