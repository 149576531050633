import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'

function IconEyeClosed(props: SvgProps) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.217 8.217a1 1 0 011.419-1.41l1.965 1.965C13 8.258 14.5 8 16 8c4.549 0 9.096 2.363 10.865 7.137L27 15.5a10.4 10.4 0 01-3.59 5.082l1.782 1.782a1 1 0 01-1.41 1.419c0-.002-.002-.003-.004-.005L8.222 8.222l-.005-.005zm-.177 2.651A10.403 10.403 0 005 15.5l.14.357C6.91 20.637 11.456 23 16 23c1.235 0 2.47-.175 3.648-.524l-2.115-2.115c-.52.08-1.036.118-1.533.118-2.913 0-6.501-1.321-8.094-4.994a7.787 7.787 0 011.98-2.77L8.04 10.868zm5.591-.065l7.928 7.927a7.76 7.76 0 002.528-3.215C22.502 11.842 18.913 10.52 16 10.52c-.763 0-1.568.088-2.369.282z"
        fill="#9B9B9B"
      />
    </Svg>
  )
}

export default IconEyeClosed
