import * as React from 'react'
import Svg, { G, Path } from 'react-native-svg'

const BallIcon = (props: { color: any }) => (
  <Svg width={30} height={30}>
    <G fill="none" fillRule="evenodd" stroke={props.color}>
      <Path d="M1 15c0 7.732 6.268 14 14 14s14-6.268 14-14S22.732 1 15 1 1 7.268 1 15Z" />
      <Path d="M24.554 4.77s1.045 5.542-4.487 8.666c-5.531 3.125-6.266 6.494-5.837 9.558.35 2.5 3.37 6.617 8.283 3.818M11.122 1.547s-5.225 1.29-5.347 7.54c-.123 6.249-1.671 10.062-4.267 9.657" />
    </G>
  </Svg>
)
export default BallIcon
