import { Team } from '../../modules/team/Team'

interface MeState {
  teams?: Team[]
  currentTeam: Team | undefined
}

type MeAction =
  | { type: 'setTeams'; payload: Team[] }
  | { type: 'setCurrentTeam'; payload: Team }

export const meReducer = (state: MeState, action: MeAction): MeState => {
  switch (action.type) {
    case 'setTeams':
      return {
        ...state,
        teams: action.payload,
        currentTeam: action.payload.length > 0 ? action.payload[0] : undefined,
      }

    case 'setCurrentTeam':
      return {
        ...state,
        currentTeam: action.payload,
      }

    default:
      return state
  }
}
