import { Theme } from '../Theme'

const colors = {
  primary: '#A3FC6F',
  error: '#FE526D',
  success: '#93F81C',
  warning: '#ec631c',
  blueDark: '#1B1927',
  greenDark: '#18362E',
  white: '#FFFFFF',
  black: '#000000',
  muted: '#5E6272',
  danger: 'red',
  grey: '#A8A8A8',
  win: '#93F81C',
  lose: '#FC7258',
}

export const defaultTheme: Theme = {
  name: 'default',
  dark: true,
  colors: colors,
  background: {
    image: undefined,
    color: colors.primary,
  },
  text: {
    fontSize: 14,
    fontPrimary: 'DMSans_400Regular',
    fontPrimarySemiBold: 'DMSans_500Medium',
    fontPrimaryBold: 'DMSans_700Bold',
    fontSecondary: 'Poppins_600SemiBold',
    fontFeatured: 'Anton_400Regular',
    color: colors.greenDark,
    colorMuted: colors.muted,
    colorGrey: colors.grey,
  },
  button: {
    borderColor: colors.grey,
    fontSize: 14,
    default: {
      borderColor: colors.grey,
      backgroundColor: 'transparent',
      color: colors.grey,
    },
    primary: {
      borderColor: colors.primary,
      backgroundColor: colors.primary,
      color: colors.black,
    },
    secondary: {
      borderColor: colors.primary,
      backgroundColor: colors.greenDark,
      color: colors.primary,
    },
    disabled: {
      borderColor: '#2e2b43',
      backgroundColor: '#62827A',
      color: colors.grey,
    },
    cancel: {
      borderColor: '#FE526E',
      backgroundColor: '#FE526E',
      color: '#FFFFFF',
    },
    transparent: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: colors.grey,
    },
  },
  input: {
    fontSize: 14,
    color: colors.grey,
    borderColor: colors.greenDark,
    backgroundColor: 'transparent',
    placeholderColor: '#7c7c7c',
  },
  switch: {
    trackColorFalse: '#8c92a7',
    trackColorTrue: colors.primary,
    thumbColor: '#4D5362',
  },
  link: {
    color: '#2686FA',
  },
  grid: {
    gap: 10,
  },
  home: {
    imageSources: {
      myMatches: require('./images/home/cup-green.png'),
      stats: require('./images/home/cup-blue.png'),
      classification: require('./images/home/racket-lilac.png'),
      lastMinuteMatches: require('./images/home/racket-lilac.png'),
      notifications: require('./images/home/racket-red.png'),
      upcomingTournaments: require('./images/home/cup-orange.png'),
      playoffs: require('./images/home/cup-green.png'),
    },
  },
  stats: {
    imageSources: {
      wins: require('./images/stats/cup-green.png'),
      rank: require('./images/stats/rank.png'),
      playoffsYes: require('./images/stats/playoffs-yes.png'),
      playoffsNo: require('./images/stats/playoffs-no.png'),
    },
  },
  playoffs: {
    imageSources: {
      background: require('./images/playoffs/playoffs-bg.png'),
      backgroundAspectRatio: 2240 / 1238,
    },
    scoreboard: {
      backgroundColor: '#45D6FC',
      color: colors.primary,
    },
  },
  notification: {
    warning: {
      imageSource: require('./images/notification/racket-lilac.png'),
      imageSourceRead: require('./images/notification/cup-orange-read.png'),
      color: '#feb952',
    },
    danger: {
      imageSource: require('./images/notification/danger.png'),
      imageSourceRead: require('./images/notification/danger-read.png'),
      color: '#FE526D',
    },
    info: {
      imageSource: require('./images/notification/cup-orange.png'),
      imageSourceRead: require('./images/notification/racket-lilac-read.png'),
      color: '#4A90E2',
    },
  },
}
