import { Theme } from '../../components/ui/themes/Theme'

type ThemeAction = { type: 'setTheme'; payload: Theme }

export const themeReducer = (state: Theme, action: ThemeAction): Theme => {
  switch (action.type) {
    case 'setTheme':
      return action.payload
    default:
      return state
  }
}
