import * as React from 'react'
import {
  StyleSheet,
  View,
  Keyboard,
  Alert,
  ScrollView,
  Image,
  TextInput as TextInputReact,
} from 'react-native'
import { AuthStackScreenProps } from '../../../navigation/AuthStackNavigator'
// import Image from 'react-native-scalable-image'
import {
  Button,
  Form,
  FormGroup,
  CustomTextInput,
  PasswordTextInput,
  KeyboardView,
  P,
  H2,
} from '../../ui/ui'
import { InputTypes } from '../../ui/inputs/CustomTextInput'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { LinkButton } from '../../ui/buttons/LinkButton/LinkButton'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { useForm } from '../../../hooks/useForm'
import { AuthContext } from '../../../contexts/auth/AuthContext'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  getFCMToken,
  requestPushPermission,
} from '../../../support/push-notifications'
import { config } from '../../../config/config'
// @ts-ignore
import { theme } from '../../ui/themes/theme'
import { Footer } from '../../common/Footer/Footer'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'
import { useEffect } from 'react'

translationService.addTranslations(
  'screens.login',
  require('./LoginScreen.i18n.json')
)

type Props = AuthStackScreenProps<'LoginScreen'>

export function LoginScreen({ navigation }: Props) {
  const theme = defaultTheme
  console.log('theme', theme)

  const { login } = React.useContext(AuthContext)

  const { email, password, change } = useForm(
    config.isDebug
      ? {
          // email: 'sergio@bake250.com',
          // password: '123456',
          email: 'natxo@bake250.com',
          password: 'natxo@bake250.com',
        }
      : { email: '', password: '' }
  )

  useEffect(() => {
    setErrorMessage('')
  }, [email, password])

  const [loading, setLoading] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState('')

  const passwordRef = React.useRef<TextInputReact>(null)

  const { trans } = useTranslation()

  const doLogin = async () => {
    setLoading(true)
    setErrorMessage('')
    Keyboard.dismiss
    // await requestPushPermission()
    const fcmToken = ''
    // if (!fcmToken) {
    //   Alert.alert('Error (FCM)', 'Contacta con La Liguita')
    //   return
    // }
    const result = await login(email, password, fcmToken ?? '')
    if (result.kind !== 'success') {
      setLoading(false)
      if (result.kind === 'bad-credentials') {
        setErrorMessage('Su email o contraseña no son correctos.')
        Alert.alert(trans('screens.login.Login error title'), undefined, [
          { text: 'OK', onPress: () => {} },
        ])
      } else if (result.kind === 'bad-email') {
        setErrorMessage('Ingresa un email válido.')
      } else if (result.kind === 'unknown-error') {
        setErrorMessage(result.errorMessage)

        Alert.alert('Error', result.errorMessage, [
          { text: 'OK', onPress: () => {} },
        ])
      }
    }
  }

  return (
    <KeyboardView>
      <ScrollView keyboardShouldPersistTaps="always">
        <View style={styles.container}>
          <View style={styles.logo}>
            <Image
              style={{ width: 160, height: 100 }}
              resizeMode={'contain'}
              source={require('../../../assets/images/app-logo-green.png')}
            />
          </View>

          <H2 center style={styles.title}>
            {`Nuevos clubs,\n más parejas,\n ¡mejores premios!`}
          </H2>

          <P style={styles.subtitle}>Inserta tus datos</P>

          <Form>
            <FormGroup>
              <CustomTextInput
                type={InputTypes.text}
                onChangeText={(value) => change('email', value)}
                placeholder={'Username'}
                value={email}
                center
                returnKeyType="next"
                onSubmitEditing={() => {
                  if (passwordRef.current) {
                    passwordRef.current.focus()
                  }
                }}
              />
            </FormGroup>

            <FormGroup>
              <PasswordTextInput
                ref={passwordRef}
                onChangeText={(value) => change('password', value)}
                placeholder={trans('screens.login.Password placeholder')}
                value={password}
                returnKeyType="done"
                onSubmitEditing={doLogin}
              />
            </FormGroup>

            <FormGroup style={styles.loginButton}>
              <Button
                variant={ButtonVariant.primary}
                onPress={doLogin}
                width="100%"
                loading={loading}
                disabled={email === '' || password === ''}
              >
                LOGIN
              </Button>
              <P
                center
                style={{
                  marginTop: 20,
                  color: 'red',
                  marginHorizontal: 30,
                }}
              >
                {errorMessage}
              </P>
            </FormGroup>

            <FormGroup>
              <P center style={{ color: theme.text.colorMuted }}>
                {trans('screens.login.Forgot your password?')}
              </P>
              <LinkButton
                center
                onPress={() => navigation.navigate('PasswordRecoveryScreen')}
              >
                {trans('screens.login.Recovery password')}
              </LinkButton>
            </FormGroup>
          </Form>
        </View>
      </ScrollView>

      <Footer />
    </KeyboardView>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
  },
  logo: {
    marginBottom: 45,
  },
  title: {
    marginBottom: 30,
    fontSize: 30,
    // color: theme.colors.greenDark,
  },
  subtitle: {
    fontSize: 18,
    // color: theme.colors.greenDark,
    marginBottom: 15,
    // fontFamily: theme.text.fontPrimarySemiBold,
  },
  loginButton: {
    marginBottom: 20,
  },
  footerContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    flex: 1,
  },
})
