import AsyncStorage from '@react-native-async-storage/async-storage'
import { User } from '../modules/user/User'

const USER = 'user'
const AUTH_TOKEN = 'authToken'
const REFRESH_TOKEN = 'refreshToken'

export class LocalStorage {
  static clearAll(): Promise<void> {
    return AsyncStorage.clear()
  }

  static setUser(user: User): Promise<void> {
    const userString = JSON.stringify(user)
    return AsyncStorage.setItem(USER, userString)
  }

  static getAuthToken(): Promise<string | null> {
    return AsyncStorage.getItem(AUTH_TOKEN)
  }

  static setAuthToken(authToken: string): Promise<void> {
    return AsyncStorage.setItem(AUTH_TOKEN, authToken)
  }

  static getRefreshToken(): Promise<string | null> {
    return AsyncStorage.getItem(REFRESH_TOKEN)
  }

  static setAuthTokens(authToken: string, refreshToken: string): Promise<void> {
    return AsyncStorage.multiSet([
      [AUTH_TOKEN, authToken],
      [REFRESH_TOKEN, refreshToken],
    ])
  }

  static setAuth(
    user: User,
    authToken: string,
    refreshToken: string
  ): Promise<void> {
    const userString = JSON.stringify(user)
    return AsyncStorage.multiSet([
      [USER, userString],
      [AUTH_TOKEN, authToken],
      [REFRESH_TOKEN, refreshToken],
    ])
  }

  static getAuth(): Promise<
    [user: User, authToken: string, refreshToken: string]
  > {
    return AsyncStorage.multiGet([USER, AUTH_TOKEN, REFRESH_TOKEN]).then(
      // @ts-expect-error TS2488
      // Type 'void | readonly KeyValuePair[]' must have a '[Symbol.iterator]()' method that returns an iterator.
      // This error appeared after upgrading async-storage from ^1.15.14 to 1.16.1.
      // There are solutions at https://github.com/Microsoft/TypeScript/issues/12707, but nothing seems to fix it.
      ([
        [_userKey, userJson],
        [_autTokenKey, authToken],
        [_refreshTokenKey, refreshToken],
      ]) => {
        // console.log(`userJson`, userJson)
        if (!userJson) {
          throw Error(`LocalStorage - userJson does not exist: ${userJson}`)
        }
        if (!authToken) {
          throw Error(`LocalStorage - authToken does not exist: ${authToken}`)
        }
        if (!refreshToken) {
          throw Error(
            `LocalStorage - refreshToken does not exist: ${refreshToken}`
          )
        }
        const user = User.fromJsonStringify(userJson)
        // console.log(`LocalStorage user`, user)
        return [user, authToken, refreshToken]
      }
    )
  }
}
