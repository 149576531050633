import * as React from 'react'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { useTournament } from '../../../modules/tournament/useTournament'
import { Loader } from '../../common/Loader/Loader'
import { TournamentCard } from '../../common/TournamentCard/TournamentCard'
import { Accordion, Container } from '../../ui/ui'
import { ScrollView, StyleSheet } from 'react-native'
import { View } from 'react-native'

type Props = MainStackScreenProps<'TournamentScreen'>

export const TournamentScreen = ({ route }: Props) => {
  const { isLoading, tournament } = useTournament(route.params.tournamentId)

  if (isLoading) return <Loader />

  if (tournament)
    return (
      <ScrollView>
        <Container style={styles.container}>
          <View style={styles.cardContainer}>
            <TournamentCard tournament={tournament} />
          </View>

          <Accordion sections={tournament.content} />
        </Container>
      </ScrollView>
    )

  return null
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingBottom: 100,
  },
  cardContainer: {
    marginBottom: 20,
  },
})
