import * as React from 'react'
import { GameApi } from './GameApi'
import { MyGames } from './MyGames'
import { extractApiError } from '../../support/apiError'

type Result = MyGames | 'loading' | Error

export function useOtherTeamGames(teamId: string): Result {
  const [myGames, setMyGames] = React.useState<Result>('loading')

  React.useEffect(() => {
    if (teamId) {
      GameApi.getOtherTeamGames(teamId)
        .then((games: MyGames) => {
          setMyGames(games)
        })
        .catch((error) => {
          console.log(`useMyGames GameApi.getMyGames error`, error)
          setMyGames(extractApiError(error))
        })
    }
  }, [teamId])

  return myGames
}
