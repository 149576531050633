import * as React from 'react'
import { SafeAreaView, StyleSheet, View } from 'react-native'
import { Button, ButtonSet, ButtonSetItem } from '../../ui/ui'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { Loader } from '../../common/Loader/Loader'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { CancelGameModal } from './partials/CancelGameModal'
import { EnterScoreModal } from './partials/EnterScoreModal'
import { GameScreenHeader } from '../../common/GameScreenParts/GameScreenHeader'
import { GameScreenBody } from '../../common/GameScreenBody/GameScreenBody'
import { MeContext } from '../../../contexts/me/MeContext'
import { ErrorMessage } from '../../common/ErrorMessage'
import { useGetPlayoffGame } from '../../../modules/playoff/usePlayoffByTeamId'
import { PlayoffScoreboard } from '../../common/Scoreboard/PlayoffScoreboard'
import { teamSimpleMapper } from '../../../modules/team/teamSimpleMapper'
import { Team } from '../../../modules/team/Team'
import { PlayoffEnterScoreModal } from './partials/PlayoffEnterScoreModal'

translationService.addTranslations(
  'screens.gameScreen',
  require('./GameScreen.i18n.json')
)

type Props = MainStackScreenProps<'PlayoffGameScreen'>

export const PlayoffGameScreen = ({ route }: Props) => {
  const { trans } = useTranslation()

  const { currentTeam } = React.useContext(MeContext)
  const result = useGetPlayoffGame(route.params.playoffGameId)

  const [cancelModalVisible, setCancelModalVisible] = React.useState(false)
  const [enterScoreModalVisible, setEnterScoreModalVisible] =
    React.useState(false)

  if (!currentTeam) {
    // This should never happen because at HomeScreen we navigate to MyGamesScreen only if we have currentTeam
    return <ErrorMessage text={'No currentTeam'} />
  }

  if (result === 'loading') {
    return <Loader />
  }

  if (result instanceof Error) {
    return <ErrorMessage text={result.message} />
  }

  if (!result) return <ErrorMessage text={'error'} />

  const { reloadGame, game } = result

  return (
    <>
      <SafeAreaView style={styles.container}>
        <GameScreenHeader
          club={game.club}
          date={game?.scheduled_on ? new Date(game.scheduled_on) : undefined}
          team1={
            game?.first_team
              ? (teamSimpleMapper(game?.first_team) as unknown as Team)
              : undefined
          }
          team2={
            game?.second_team
              ? (teamSimpleMapper(game?.second_team) as unknown as Team)
              : undefined
          }
        />

        <GameScreenBody>
          <PlayoffScoreboard
            color="#716F86"
            game={game}
            gameScore={game?.score ?? null}
          />
        </GameScreenBody>

        <View style={styles.footer}>
          <ButtonSet>
            {game.resultCanBeSent && (
              <ButtonSetItem>
                <Button
                  onPress={() => {
                    setEnterScoreModalVisible(true)
                  }}
                  variant={ButtonVariant.primary}
                >
                  {trans('screens.gameScreen.Record result')}
                </Button>
              </ButtonSetItem>
            )}

            {game.canBeCancelled && (
              <ButtonSetItem>
                <Button
                  onPress={() => {
                    setCancelModalVisible(true)
                  }}
                  variant={ButtonVariant.cancel}
                >
                  {trans('screens.gameScreen.Cancel game')}
                </Button>
              </ButtonSetItem>
            )}

            {game.isCancelled() && (
              <Button
                onPress={() => {}}
                variant={ButtonVariant.cancel}
                disabled={true}
              >
                {trans('screens.gameScreen.Cancelled game')}
              </Button>
            )}
          </ButtonSet>
        </View>
      </SafeAreaView>

      <CancelGameModal
        visible={cancelModalVisible}
        gameId={route.params.playoffGameId}
        teamId={currentTeam.id}
        onRequestClose={() => setCancelModalVisible(false)}
        onGameCancelSuccess={() => {
          console.log(`GameScreen reloadGame`)
          setCancelModalVisible(false)
          reloadGame()
        }}
      />

      <PlayoffEnterScoreModal
        visible={enterScoreModalVisible}
        onRequestClose={() => {
          setEnterScoreModalVisible(false)
          reloadGame()
        }}
        game={game}
      />
    </>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
  },
  footer: {
    paddingBottom: 20,
  },
})
