import * as React from 'react'
import { Badge } from '../Badge/Badge'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { useNotificationsContext } from '../../../../contexts/notifications/NotificationsContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

interface Props {
  fontSize?: number
}

export const NotificationsCountBadge = ({ fontSize }: Props) => {
  const theme = defaultTheme
  const { notifications } = useNotificationsContext()

  if (!notifications) {
    return null
  }

  const unreadNotificationsCount = notifications.filter((n) => !n.isRead).length

  fontSize = fontSize || theme.text.fontSize

  return (
    <Badge
      text={unreadNotificationsCount.toString()}
      color={'#F72E47'}
      fontSize={fontSize}
    />
  )
}
