import { DrawerContentComponentProps } from '@react-navigation/drawer'
import {
  Animated,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
} from 'react-native'
import * as React from 'react'
import { IconCheck, IconDropdownCaret } from '../components/ui/icons/icons'
import { H1, H2, NotificationsCountBadge, P } from '../components/ui/ui'
import { TermsBottomLink } from '../components/common/TermsBottomLink/TermsBottomLink'
import { Theme } from '../components/ui/themes/Theme'
import { AuthContext } from '../contexts/auth/AuthContext'
import { MeContext } from '../contexts/me/MeContext'
import { translationService } from '../services/translationService'
import { useTranslation } from '../hooks/useTranslation'
import { config } from '../config/config'
import { LoadIndicator } from '../components/common/Loader/Loader'
import { AntDesign } from '@expo/vector-icons'
// import Image from 'react-native-scalable-image'
import { defaultTheme } from '../components/ui/themes/default/defaultTheme'

translationService.addTranslations(
  'navigation.appDrawerNavigatorContent',
  require('./AppDrawerNavigatorContent.i18n.json')
)

export const AppDrawerNavigationContent = ({
  navigation,
}: DrawerContentComponentProps) => {
  const theme = defaultTheme

  const { teams, currentTeam, setCurrentTeam } = React.useContext(MeContext)

  const styles = getStyles(theme)

  const [myTeamsCollapsed, setMyTeamsCollapsed] = React.useState(true)
  const [doingLogout, setDoingLogout] = React.useState(false)
  const [menuHeight] = React.useState(new Animated.Value(0))

  const { logout } = React.useContext(AuthContext)

  const { trans } = useTranslation()

  const toggleMenu = () => {
    if (!teams) return
    const toValue = myTeamsCollapsed ? teams.length * 60 : 0
    Animated.timing(menuHeight, {
      toValue: toValue,
      duration: 300,
      useNativeDriver: false,
    }).start()
    setMyTeamsCollapsed(!myTeamsCollapsed)
  }

  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            alignItems: 'center',
            marginTop: Platform.OS === 'android' ? 10 : 0,
          }}
        >
          <Image width={70} source={require('../assets/images/isotipo.png')} />

          <View style={styles.closeButton}>
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => navigation.closeDrawer()}
            >
              <AntDesign name="close" size={35} color="black" />
            </TouchableOpacity>
          </View>
        </View>

        <View style={styles.menuContainer}>
          <View style={styles.menuItem}>
            <View style={styles.menuItemInner}>
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => navigation.navigate('ProfileScreen')}
              >
                <H1 style={styles.menuItemText}>
                  {trans('navigation.appDrawerNavigatorContent.Profile')}
                </H1>
              </TouchableOpacity>
            </View>
          </View>

          <View style={styles.menuItem}>
            <View style={styles.menuItemInner}>
              <TouchableOpacity activeOpacity={0.5} onPress={toggleMenu}>
                <H1 style={styles.menuItemText}>
                  {trans('navigation.appDrawerNavigatorContent.My teams')}
                </H1>
              </TouchableOpacity>

              <TouchableOpacity activeOpacity={0.5} onPress={toggleMenu}>
                <View
                  style={{
                    ...styles.menuItemRight,
                    marginLeft: 20,
                    marginTop: 20,
                    transform: [
                      { rotate: myTeamsCollapsed ? '0deg' : '180deg' },
                    ],
                  }}
                >
                  <IconDropdownCaret color={theme.colors.greenDark} />
                </View>
              </TouchableOpacity>
            </View>

            <Animated.View style={{ ...styles.submenu, height: menuHeight }}>
              {teams &&
                !myTeamsCollapsed &&
                teams.map((team) => (
                  <TouchableOpacity
                    style={styles.submenuTeam}
                    key={team.id}
                    onPress={(_) => setCurrentTeam(team)}
                  >
                    <Text
                      style={[
                        styles.submenuTeamText,
                        currentTeam?.id === team.id
                          ? styles.submenuTeamTextActive
                          : {},
                      ]}
                    >
                      {team.leagueGroup?.name}
                    </Text>
                    <View>
                      <Text
                        style={[
                          styles.submenuTeamText,
                          currentTeam?.id === team.id
                            ? styles.submenuTeamTextActive
                            : {},
                        ]}
                      >
                        {team.player1.name} / {team.player2.name}
                      </Text>
                    </View>
                    {currentTeam?.id === team.id && (
                      <IconCheck color={theme.colors.greenDark} />
                    )}
                  </TouchableOpacity>
                ))}
            </Animated.View>
          </View>

          <View style={styles.menuItem}>
            <View style={styles.menuItemInner}>
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => navigation.navigate('NotificationsScreen')}
              >
                <H1 style={styles.menuItemText}>
                  {trans('navigation.appDrawerNavigatorContent.Notifications')}
                </H1>
              </TouchableOpacity>

              <View style={styles.menuItemRight}>
                <NotificationsCountBadge fontSize={13} />
              </View>
            </View>
          </View>

          {/*
          <View style={styles.menuItem}>
            <View style={styles.menuItemInner}>
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={() => navigation.navigate('FaqsScreen')}
              >
                <H1 style={styles.menuItemText}>Faq's</H1>
              </TouchableOpacity>
            </View>
          </View>
          */}

          {doingLogout ? (
            <LoadIndicator />
          ) : (
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => {
                setDoingLogout(true)
                logout()
              }}
            >
              <H2 style={styles.closeSessionButton}>
                {trans('navigation.appDrawerNavigatorContent.Log out')}
              </H2>
            </TouchableOpacity>
          )}
        </View>

        {config.isDebug && <P>{config.apiBaseUrl}</P>}

        <TermsBottomLink />
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.primary,
      flex: 1,
    },
    inner: {
      flex: 1,
      paddingHorizontal: 20,
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 20,
      paddingBottom: 20,
    },
    background: {
      position: 'absolute',
      zIndex: 0,
      width: '100%',
      height: '100%',
      resizeMode: 'cover',
    },
    closeButton: {
      alignSelf: 'flex-end',
      backgroundColor: theme.colors.primary,
      height: 50,
      width: 50,
      borderRadius: 30,
      alignItems: 'center',
      justifyContent: 'center',
    },
    menuContainer: {
      justifyContent: 'center',
      flex: 1,
      width: '100%',
    },
    menuItem: {
      marginBottom: 45,
      alignItems: 'flex-start',
    },
    menuItemInner: {
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    menuItemText: {
      fontSize: 20,
      marginBottom: 0,
      height: 33,
      color: 'white',
      fontFamily: 'Syne_800ExtraBold',
    },
    menuItemRight: {
      position: 'absolute',
      right: -35,
    },
    submenu: {
      paddingTop: 30,
      marginVertical: -10,
    },
    submenuTeam: {
      flex: 1,
      paddingVertical: 10,
      flexDirection: 'row',
    },
    submenuTeamText: {
      flex: 0,
      fontSize: 15,
      lineHeight: 20,
      color: '#6a6d73',
      textAlign: 'center',
      fontFamily: theme.text.fontSecondary,
      marginRight: 16,
    },
    submenuTeamTextActive: {
      color: theme.colors.greenDark,
    },
    closeSessionButton: {
      fontSize: 18,
      color: theme.colors.primary,
    },
  })
