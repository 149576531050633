import * as React from 'react'
import { View, Switch as SwitchReact, SwitchProps } from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

interface Props extends SwitchProps {}

export const Switch = ({ disabled, onValueChange, value }: Props) => {
  const theme = defaultTheme
  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: 'black',
        borderRadius: 15,
      }}
    >
      <SwitchReact
        disabled={disabled}
        trackColor={{
          false: 'white',
          true: '#577548',
        }}
        thumbColor={'white'}
        ios_backgroundColor={'white'}
        onValueChange={onValueChange}
        value={value}
      />
    </View>
  )
}
