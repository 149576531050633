import * as React from 'react'
import { TeamStats } from './TeamStats'
import { Uuid } from '../../support/types'
import { StatisticsApi } from './StatisticsApi'
import { extractApiError } from '../../support/apiError'

export function useTeamStats(teamId: Uuid): 'loading' | TeamStats | Error {
  const [result, setResult] = React.useState<'loading' | TeamStats | Error>(
    'loading'
  )

  React.useEffect(() => {
    setResult('loading')
    StatisticsApi.getTeamStatistics(teamId)
      .then((teamStats) => {
        setResult(teamStats)
      })
      .catch((error) => {
        setResult(extractApiError(error))
      })
  }, [teamId])

  return result
}
