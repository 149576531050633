import * as React from 'react'
import { Platform, TouchableOpacity } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { DrawerNavigationProp } from '@react-navigation/drawer/src/types'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  navigation: DrawerNavigationProp<any, any>
}

export const DrawerOpenButton = ({ navigation }: Props) => {
  const theme = defaultTheme

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      style={{
        marginRight: theme.grid.gap * 2,
        backgroundColor: theme.background.color,
        height: 50,
        width: 50,
        borderRadius: 30,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        marginTop: 20,
      }}
      onPress={() => navigation.openDrawer()}
    >
      <Feather name="menu" size={28} color="black" />
    </TouchableOpacity>
  )
}
