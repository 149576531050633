import { Uuid } from '../../support/types'

export class LeagueGroup {
  id: Uuid
  name: string

  constructor(id: Uuid, name: string) {
    this.id = id
    this.name = name
  }

  static fromJson(json: { id: string; name: string }): LeagueGroup {
    return new LeagueGroup(json.id, json.name)
  }
}
