import * as React from 'react'
import Svg, { Path } from 'react-native-svg'

export const IconCamera = ({
  size,
  color,
}: {
  size?: number
  color?: string
}) => {
  const width = size ? size : 24
  const height = size ? (size * 22) / 24 : 22
  return (
    <Svg width={width} height={height} viewBox={`0 0 24 22`} fill="none">
      <Path
        d="M15 12.5714C15 13.193 14.8241 13.8007 14.4944 14.3175C14.1648 14.8343 13.6962 15.2372 13.1481 15.4751C12.5999 15.7129 11.9967 15.7752 11.4147 15.6539C10.8328 15.5326 10.2982 15.2333 9.87868 14.7938C9.45912 14.3542 9.1734 13.7942 9.05764 13.1846C8.94189 12.5749 9.0013 11.943 9.22836 11.3687C9.45542 10.7944 9.83994 10.3036 10.3333 9.95824C10.8266 9.6129 11.4067 9.42857 12 9.42857C12.7956 9.42857 13.5587 9.75969 14.1213 10.3491C14.6839 10.9385 15 11.7379 15 12.5714ZM24 6.28572V19.6429C24 20.268 23.7629 20.8676 23.341 21.3096C22.919 21.7517 22.3467 22 21.75 22H2.25C1.65326 22 1.08097 21.7517 0.65901 21.3096C0.237053 20.8676 0 20.268 0 19.6429V6.28572C0 5.66056 0.237053 5.06102 0.65901 4.61897C1.08097 4.17692 1.65326 3.92857 2.25 3.92857H5.25L6.1825 1.48238C6.34963 1.04429 6.63839 0.668816 7.01144 0.404538C7.38449 0.140259 7.82466 -0.000663402 8.275 2.34801e-06H15.725C16.1749 -0.000139243 16.6145 0.141028 16.9871 0.405273C17.3596 0.669518 17.648 1.04471 17.815 1.48238L18.75 3.92857H21.75C22.3467 3.92857 22.919 4.17692 23.341 4.61897C23.7629 5.06102 24 5.66056 24 6.28572ZM18 12.5714C18 11.3282 17.6481 10.113 16.9888 9.07927C16.3295 8.04559 15.3925 7.23994 14.2961 6.76419C13.1997 6.28844 11.9933 6.16396 10.8295 6.4065C9.66557 6.64903 8.59647 7.24769 7.75736 8.12676C6.91824 9.00583 6.3468 10.1258 6.11529 11.3451C5.88378 12.5645 6.0026 13.8283 6.45672 14.9769C6.91085 16.1254 7.67988 17.1071 8.66658 17.7978C9.65327 18.4885 10.8133 18.8571 12 18.8571C13.5913 18.8571 15.1174 18.1949 16.2426 17.0161C17.3679 15.8373 18 14.2385 18 12.5714Z"
        fill={color ? color : '#FFFFFF'}
      />
    </Svg>
  )
}
