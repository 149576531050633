import * as React from 'react'
import { tournamentRepo } from '../../services/services'
import { Tournament } from './Tournament'
import { Uuid } from '../../support/types'

export const useTournament = (tournamentId: Uuid) => {
  const [isLoading, setIsLoading] = React.useState(true)
  const [tournament, setTournament] = React.useState<Tournament>()

  const findById = async (tournamentId: Uuid) => {
    const tournament = await tournamentRepo.findById(tournamentId)

    setTournament(tournament)
    setIsLoading(false)
  }

  React.useEffect(() => {
    findById(tournamentId)
  }, [])

  return {
    tournament,
    isLoading,
  }
}
