import teamDummy from '../../data/dummy/team.json'
import { Uuid } from '../../support/types'
import { ApiTeamAdapter } from './ApiTeamAdapter'
import { Team } from './Team'
import { apiClient } from '../../support/apiClient'
import { AxiosResponse } from 'axios'
import { TeamJson } from './TeamJson'

const apiTeamAdapter = new ApiTeamAdapter()

export class TeamApi {
  static findById(teamId: Uuid): Promise<Team> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        // @ts-ignore
        const team = apiTeamAdapter.adapt(teamDummy)

        resolve(team)
      }, 500)
    })
  }

  static getMyTeams(): Promise<Team[]> {
    console.log(`TeamApi.getMyTeams() - GET /my-teams`)
    return apiClient
      .get(`/my-teams`)
      .then((response: AxiosResponse<{ teams: TeamJson[] }>) => {
        const teamsJson = response.data.teams
        return teamsJson.map((json) => Team.fromJson(json))
      })
  }
}
