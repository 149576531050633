import { Theme } from '../components/ui/themes/Theme'
import {
  CardStyleInterpolators,
  StackNavigationOptions,
} from '@react-navigation/stack'
import * as React from 'react'
import { HeaderBackButtonProps } from '@react-navigation/elements'
import { HeaderBackButton } from '../components/common/HeaderBackButton/HeaderBackButton'

export const stackNavigationOptions = (
  theme: Theme
): StackNavigationOptions => {
  return {
    headerLeft: ({ onPress, canGoBack }: HeaderBackButtonProps) =>
      canGoBack && <HeaderBackButton onPress={onPress} />,
    headerStyle: {
      backgroundColor: 'white',
      elevation: 0,
      shadowColor: 'transparent',
    },
    headerTintColor: theme.text.color.toString(),
    headerTitleStyle: {
      color: theme.text.color,
      fontWeight: '900',
      fontFamily: theme.text.fontPrimary,
      alignSelf: 'center',
    },
    headerTitleAlign: 'center',
    headerBackTitle: ' ',
    headerBackTitleStyle: {
      fontFamily: theme.text.fontPrimary,
    },
    cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
    cardStyle: {
      backgroundColor: 'white',
    },
  }
}
