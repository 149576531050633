import { apiClient } from '../../support/apiClient'
import { AxiosResponse } from 'axios'
import { Playoff, PlayoffGame, PlayoffLeague, PlayoffScore } from './Playoff'
import { GameScoreJson } from '../game/GameScoreJson'
import { GameStatus } from '../game/GameStatus'

export class PlayoffApi {
  static getPlayoff(teamId: string): Promise<Playoff> {
    console.log(`PlayoffApi.getPlayoff - POST /playoff - teamId`, teamId)
    return apiClient.post(`/playoff`, { team_id: teamId }).then(
      (
        response: AxiosResponse<{
          playoff: Playoff
        }>
      ) => {
        return response.data.playoff
      }
    )
  }

  static getPlayoffs(teamId: string): Promise<PlayoffLeague> {
    console.log(`PlayoffApi.getPlayoffs - POST /playoffs - teamId`, teamId)
    return apiClient.post(`/playoffs`, { team_id: teamId }).then(
      (
        response: AxiosResponse<{
          league: PlayoffLeague
        }>
      ) => {
        return response.data.league
      }
    )
  }

  static showPlayoff(playoffId: string): Promise<Playoff> {
    console.log(
      `PlayoffApi.showPlayoff - POST /showPlayoff - playoffId`,
      playoffId
    )
    return apiClient.get(`/playoffs/${playoffId}/show`).then(
      (
        response: AxiosResponse<{
          playoff: Playoff
        }>
      ) => {
        return response.data.playoff
      }
    )
  }

  static getGamePlayoff(playoffId: string): Promise<PlayoffGame> {
    console.log(
      `PlayoffApi.showPlayoff - POST /showPlayoff - playoffId`,
      playoffId
    )
    return apiClient
      .get(`/gameplayoff/${playoffId}`)
      .then((response: AxiosResponse<PlayoffGame>) => {
        const gameJson = response.data
        console.log(
          'PlayoffGame.fromJson(gameJson)',
          PlayoffGame.fromJson(gameJson)
        )
        return PlayoffGame.fromJson(gameJson)
      })
  }
  static setGameScore(
    gameId: string,
    score: PlayoffScore,
    status: GameStatus
  ): Promise<{ status: string }> {
    const data = { game_id: gameId, score, status }
    return apiClient.post(`/gameplayoff/score`, data).then(
      (
        response: AxiosResponse<{
          status: string
        }>
      ) => {
        return response.data
      }
    )
  }
}
