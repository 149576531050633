import * as React from 'react'
import { MeContext } from '../../contexts/me/MeContext'
import { extractApiError } from '../../support/apiError'
import { LastMinuteApi } from './LastMinuteApi'
import { LastMinute } from './LastMinute'

type Result = LastMinute[] | 'loading' | Error

const mockLastMinuteData: LastMinute = {
  id: 'd2dac4a4-f9bc-4b87-b66e-9607b8a6c0f3',
  scheduled_on: '2021-07-17T22:00:00',
  club: 'Real Club de Polo',
  first_team: {
    id: '6eed5c7f-0fec-4d71-b886-9974780ffdba',
    name: 'lala',
    rank: 2,
    rankingTrend: 'down',
    player1: {
      id: 'dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
      name: 'Natxo',
      picture: 'https://i.pravatar.cc/300?dec2f0fb-243e-4cf6-a5f1-7c0d8b1ad348',
    },
    player2: {
      id: '981eaa12-0a4f-4911-b179-5d41e920710c',
      name: 'Sergio',
      picture: 'https://i.pravatar.cc/300?981eaa12-0a4f-4911-b179-5d41e920710c',
    },
  },
  second_team: {
    id: '123456789',
    name: '',
    rank: 8,
    rankingTrend: 'up',
    player1: {
      id: '61fab585-8af9-41b1-a5d1-60f6922c50db',
      name: 'Manel',
      picture: 'https://i.pravatar.cc/300?61fab585-8af9-41b1-a5d1-60f6922c50db',
    },
    player2: {
      id: '85b6d368-2d92-4077-acd2-e5310b85f4fd',
      name: 'Carles',
      picture: 'https://i.pravatar.cc/300?85b6d368-2d92-4077-acd2-e5310b85f4fd',
    },
  },
  status: 'STATUS_FINISHED',
}

export function useMyLastMinuteMatches(): Result {
  const { currentTeam } = React.useContext(MeContext)
  const [myGames, setMyGames] = React.useState<Result>('loading')

  React.useEffect(() => {
    if (currentTeam) {
      LastMinuteApi.getLastMinuteMatches(currentTeam.id)
        .then((games: LastMinute[]) => {
          setMyGames(games)
          // TODO REMOVE MOCK DATA
          // setMyGames([mockLastMinuteData])
        })
        .catch((error) => {
          console.log(
            `useMyLastMinuteMatches LastMinuteApi.getLastMinuteMatches error`,
            error
          )
          setMyGames(extractApiError(error))
        })
    }
  }, [currentTeam])

  return myGames
}
