import * as React from 'react'
import { View } from 'react-native'

interface Props {
  size: number
}

export const SpacerVertical = ({ size }: Props) => (
  <View style={{ height: size }} />
)
