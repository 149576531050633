import * as React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Switch } from '../../ui/ui'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  text: string
  icon: any
  switchValue?: boolean
  switchOnChange?: Function
}

export const ProfileField = ({
  text,
  icon,
  switchValue,
  switchOnChange,
}: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  return (
    <View style={styles.container}>
      <View style={styles.icon}>{icon}</View>
      <View>
        <Text style={styles.text}>{text}</Text>
      </View>
      <View style={styles.spacer} />
      {switchValue !== undefined && (
        <View style={styles.switcher}>
          <Switch
            value={switchValue}
            onValueChange={(value) =>
              switchOnChange ? switchOnChange(value) : null
            }
          />
        </View>
      )}
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingHorizontal: 20,
      paddingVertical: 0,
      flexDirection: 'row',
      alignSelf: 'stretch',
      borderRadius: 12,
      marginBottom: 16,
      alignItems: 'center',
      minHeight: 60,
      borderBottomWidth: 1,
    },
    icon: {
      marginRight: 15,
      width: 19,
      alignItems: 'center',
    },
    text: {
      color: theme.text.color,
      fontFamily: theme.text.fontPrimary,
      fontSize: 16,
      fontWeight: '600',
    },
    spacer: {
      flex: 1,
    },
    switcher: {},
  })
