import * as React from 'react'
import { View } from 'react-native'
import { StyleSheet, Dimensions } from 'react-native'

interface Props {
  children: JSX.Element[] | JSX.Element
}

export const PlayoffsCarouselItem = ({ children }: Props) => {
  return <View style={styles.container}>{children}</View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: Dimensions.get('window').width,
    height: 600,
  },
})
