import { Uuid } from '../../support/types'

export type TournamentContentSection = {
  title: string
  content: string
}

export class Tournament {
  id: Uuid
  name: string
  image: string
  site: string
  dateFrom: Date
  dateTo: Date
  schedule: string
  content: TournamentContentSection[]

  constructor(
    id: Uuid,
    name: string,
    image: string,
    site: string,
    dateFrom: Date,
    dateTo: Date,
    schedule: string,
    content: TournamentContentSection[]
  ) {
    this.id = id
    this.name = name
    this.image = image
    this.site = site
    this.dateFrom = dateFrom
    this.dateTo = dateTo
    this.schedule = schedule
    this.content = content
  }
}
