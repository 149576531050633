import { Team } from './Team'
import { Player } from '../player/Player'
import { ModelAdapter } from '../../support/interfaces'
import { ApiLeagueGroupAdapter } from '../league/ApiLeagueAdapter'

export class ApiTeamAdapter implements ModelAdapter<Team> {
  adapt(item: any): Team {
    const leagueGroupAdapter = new ApiLeagueGroupAdapter()

    return new Team(
      item.id,
      new Player(item.player1.id, item.player1.name, item.player1.picture),
      new Player(item.player2.id, item.player2.name, item.player2.picture),
      item.rank,
      item.rankingTrend,
      item.leagueGroup ? leagueGroupAdapter.adapt(item.leagueGroup) : undefined
    )
  }
}
