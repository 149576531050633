import meDummy from '../../data/dummy/me.json'
import { Me } from './Me'
import { ApiTeamAdapter } from '../team/ApiTeamAdapter'

const apiTeamAdapter = new ApiTeamAdapter()

export class ApiMeRepo {
  baseUrl: string

  constructor() {
    this.baseUrl = '/me'
  }

  async findMe(): Promise<Me> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        const teams = meDummy.teams.map((item) => apiTeamAdapter.adapt(item))
        resolve({
          teams,
          currentTeam: teams.length > 0 ? teams[0] : undefined,
        })
      }, 500)
    })
  }
}
