import * as React from 'react'
import Svg, { Polygon } from 'react-native-svg'
import { defaultColor, defaultSize, IconProps } from './iconsSettings'

export const IconRankDown = ({
  size = defaultSize,
  color = defaultColor,
}: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 213.333 213.333" fill="none">
      <Polygon
        points="0,53.333 106.667,160 213.333,53.333"
        fill={color.toString()}
      />
    </Svg>
  )
}
