import { ColorValue, ViewStyle } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'

export interface IconProps {
  size?: number
  color?: ColorValue
  style?: ViewStyle
}

export const defaultSize: number = 14
export const defaultColor: ColorValue = '#FFFFFF'
