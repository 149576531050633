import { Playoff } from './Playoff'
import playoffDummy from '../../data/dummy/playoff.json'
import { Uuid } from '../../support/types'
import { ApiPlayoffAdapter } from './ApiPlayoffAdapter'

const apiPlayoffAdapter = new ApiPlayoffAdapter()

export class ApiPlayoffRepo {
  baseUrl: string

  constructor() {
    this.baseUrl = '/playoffs'
  }

  async findById(playoffId: Uuid): Promise<Playoff> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        // @ts-ignore
        const playoff = apiPlayoffAdapter.adapt(playoffDummy)

        resolve(playoff)
      }, 500)
    })
  }

  async findByLeagueGroupId(leagueGroupId: Uuid): Promise<Playoff> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        // @ts-ignore
        const playoff = apiPlayoffAdapter.adapt(playoffDummy)

        resolve(playoff)
      }, 500)
    })
  }
}
