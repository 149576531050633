import { Game } from './Game'
import { ApiTeamAdapter } from '../team/ApiTeamAdapter'
import { parseISO } from 'date-fns'
import { ModelAdapter } from '../../support/interfaces'

export class ApiGameAdapter implements ModelAdapter<Game> {
  adapt(item: any): Game {
    const teamAdapter = new ApiTeamAdapter()

    return new Game(
      item.id,
      item.date ? parseISO(item.date) : undefined,
      item.site,
      item.team1 ? teamAdapter.adapt(item.team1) : undefined,
      item.team2 ? teamAdapter.adapt(item.team2) : undefined,
      item.score
    )
  }
}
