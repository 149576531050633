import { LastMinuteMatch } from './LastMinuteMatch'
import lastMinuteMatchesDummy from '../../data/dummy/lastMinuteMatches.json'
import lastMinuteMatchDummy from '../../data/dummy/lastMinuteMatch.json'
import { ApiLastMinuteMatchAdapter } from './ApiLastMinuteMatchAdapter'
import { Uuid } from '../../support/types'
import { Game } from '../game/Game'

const apiLastMinuteMatchAdapter = new ApiLastMinuteMatchAdapter()

export class ApiLastMinuteMatchRepo {
  baseUrl: string

  constructor() {
    this.baseUrl = '/last-minute-matches'
  }

  async findById(gameId: Uuid): Promise<LastMinuteMatch> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        // @ts-ignore
        const lastMinuteMatch =
          apiLastMinuteMatchAdapter.adapt(lastMinuteMatchDummy)

        resolve(lastMinuteMatch)
      }, 500)
    })
  }

  async findAllMine(): Promise<LastMinuteMatch[]> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        // @ts-ignore
        const lastMinuteMatches = lastMinuteMatchesDummy.map((item: any) =>
          apiLastMinuteMatchAdapter.adapt(item)
        )
        resolve(lastMinuteMatches)
      }, 500)
    })
  }

  async generateMatch(
    lastMinuteMatchId: Uuid,
    teamId: Uuid
  ): Promise<Uuid | false> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        resolve(false)
        resolve('d2dac4a4-f9bc-4b87-b66e-9607b8a6c0f3')
      }, 500)
    })
  }
}
