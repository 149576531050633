import * as React from 'react'
import { TeamAvatars } from '../TeamAvatars/TeamAvatars'
import { StyleSheet, View, useWindowDimensions, Image } from 'react-native'
import { P } from '../../ui/texts/P/P'
import { RankingTrendIcon } from '../RankingTrendIcon/RankingTrendIcon'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { Team } from '../../../modules/team/Team'
import { shadeColor } from '../../../support/shadeColor'
import { TeamAvatarsPlaceholder } from '../TeamAvatarsPlaceholder/TeamAvatarsPlaceholder'
import { PlayerNames } from '../PlayerNames'
import { H1 } from '../../ui/texts/H1/H1'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  team1?: Team
  team2?: Team
  showRank?: boolean
  showNames?: boolean
}

export const TeamsVersus = ({ team1, team2, showRank, showNames }: Props) => {
  const theme = defaultTheme
  const { width } = useWindowDimensions()
  const styles = getStyles(theme, width)

  return (
    <View style={styles.container}>
      {team1 && (
        <View style={styles.team}>
          {showRank ? (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 10,
                left: 40,
                marginBottom: 10,
              }}
            >
              <P
                style={{
                  color: '#9B9B9B',
                  fontSize: 24,
                  marginRight: 5,
                  fontFamily: theme.text.fontPrimaryBold,
                }}
              >
                {team1.rank ? team1.rank : '-'}º
              </P>
              <RankingTrendIcon trend={team1.rankingTrend} />
            </View>
          ) : null}
          <TeamAvatars
            image1={team1.player1.picture}
            image2={team1.player2.picture}
            size={73}
            inverse
          />

          {showNames && (
            <PlayerNames
              player1Name={team1.player1.name?.split(' ')[0]}
              player2Name={team1.player2.name?.split(' ')[0]}
              style={styles.playerNames}
            />
          )}
        </View>
      )}

      {team1 === undefined && (
        <View style={styles.team}>
          {/*TODO CHANGE IMAGE*/}
          <Image
            resizeMode="contain"
            style={{
              height: 75,
              bottom: 15,
            }}
            source={require('../../../assets/images/questionMark.png')}
          />
          <P style={{ color: theme.colors.primary }}>_ _</P>
          {/*<TeamAvatarsPlaceholder size={73} inverse={true} />*/}
        </View>
      )}

      <Image
        resizeMode="contain"
        style={{
          height: 40,
          alignSelf: 'center',
        }}
        source={require('../../../assets/images/versus.png')}
      />

      {team2 && (
        <View style={styles.team}>
          {showRank ? (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 10,
                right: 40,
                marginBottom: 10,
              }}
            >
              <P
                style={{
                  color: '#9B9B9B',
                  fontSize: 24,
                  marginRight: 5,
                  fontFamily: theme.text.fontPrimaryBold,
                }}
              >
                {team2.rank ? team2.rank : '-'}º
              </P>
              <RankingTrendIcon trend={team2.rankingTrend} />
            </View>
          ) : null}

          <TeamAvatars
            image1={team2.player1.picture}
            image2={team2.player2.picture}
            size={73}
          />

          {showNames && (
            <PlayerNames
              player1Name={team2.player1.name?.split(' ')[0]}
              player2Name={team2.player2.name?.split(' ')[0]}
              style={styles.playerNames}
            />
          )}
        </View>
      )}

      {team2 === undefined && (
        <View style={styles.team}>
          <TeamAvatarsPlaceholder size={73} inverse={true} />
        </View>
      )}
    </View>
  )
}

const getStyles = (theme: Theme, windowWidth: number) =>
  StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    team: {
      alignItems: 'center',
      flex: 1,
    },
    rankLeft: {
      alignItems: 'center',
      marginTop: 10,
      right: 40,
      marginBottom: 10,
    },
    rankRight: {
      alignItems: 'center',
      marginTop: 10,
      left: 40,
      marginBottom: 10,
    },
    rankPosition: {
      color: '#9B9B9B',
      fontSize: 24,
      marginRight: 5,
      fontFamily: theme.text.fontPrimaryBold,
    },
    versusText: {
      color: '#9B9B9B',
      marginHorizontal: windowWidth < 376 ? 10 : 15,
      fontFamily: theme.text.fontPrimaryBold,
      fontSize: windowWidth < 376 ? 18 : 24,
      lineHeight: 24,
      top: 25,
    },
    playerNames: {
      marginTop: 10,
    },
    playerEmpty: {
      height: 73,
      width: 73,
      borderColor: theme.colors.primary,
      borderWidth: 1,
      backgroundColor: shadeColor(theme.background.color.toString(), 60),
      borderRadius: 50,
      borderStyle: 'dashed',
    },
  })
