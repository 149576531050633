import { Uuid } from '../../support/types'
import { PlayerJson } from './PlayerJson'

export class Player {
  id: Uuid
  name: string
  picture: string | null

  constructor(id: Uuid, name: string, picture: string | null) {
    this.id = id
    this.name = name
    this.picture = picture
  }

  static fromJson(json: PlayerJson): Player {
    return new Player(json.id, json.name, json.picture)
  }
}
