import { Uuid } from '../../support/types'
import { UserJson } from './UserJson'

export class User {
  id: Uuid
  firstName: string
  lastName: string
  image: string | null
  email: string
  phone: string
  externalId: string
  notificationsEnabled: boolean

  constructor(
    id: Uuid,
    firstName: string,
    lastName: string,
    image: string | null,
    email: string,
    phone: string,
    externalId: string,
    notificationsEnabled: boolean
  ) {
    this.id = id
    this.firstName = firstName
    this.lastName = lastName
    this.image = image
    this.email = email
    this.phone = phone
    this.externalId = externalId
    this.notificationsEnabled = notificationsEnabled
  }

  fullName() {
    return `${this.firstName} ${this.lastName}`
  }

  static fromJson(json: UserJson): User {
    return new User(
      json.id,
      json.name,
      json.surname,
      json.picture,
      json.email,
      json.phone,
      '',
      false
    )
  }

  /**
   * Takes the result of `JSON.stringify(user)` and converts it to a `User` class instance.
   * @param userAsJsonString the result of `JSON.stringify(user)`
   */
  static fromJsonStringify(userAsJsonString: string): User {
    const userObject: User = JSON.parse(userAsJsonString)
    return new User(
      userObject.id,
      userObject.firstName,
      userObject.lastName,
      userObject.image,
      userObject.email,
      userObject.phone,
      userObject.externalId,
      userObject.notificationsEnabled
    )
  }
}
