import * as React from 'react'
import { IconRankDown, IconRankUp } from '../../ui/icons/icons'
import { RankingTrend } from '../../../modules/game/RankingTrend'

interface Props {
  trend: RankingTrend
  size?: number
}

export const RankingTrendIcon = ({ trend, size = 14 }: Props) => {
  switch (trend) {
    case 'up':
      return <IconRankUp color={'#A3FC6F'} size={14} />

    case 'down':
      return <IconRankDown color={'#F72E47'} size={14} />

    default:
      return <></>
  }
}
