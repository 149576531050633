import * as React from 'react'
import { TextStyle, View, ViewProps } from 'react-native'

type FormPropTypes = {
  style?: TextStyle
  children: React.ReactNode
}

export const Form = (props: FormPropTypes) => {
  return (
    <View
      style={{
        width: '80%',
        ...props.style,
      }}
    >
      {props.children}
    </View>
  )
}
