import { Linking, StyleSheet, View } from 'react-native'
import { LinkButton } from '../../ui/buttons/LinkButton/LinkButton'
import { P } from '../../ui/texts/P/P'
import * as React from 'react'
// @ts-ignore
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

export const Footer = () => {
  const theme = defaultTheme

  return (
    <View style={styles.footerContainer}>
      <LinkButton
        color={theme.colors.greenDark}
        center
        style={{ textDecorationLine: 'none' }}
        onPress={() => Linking.openURL('https://www.laliguitadepadel.com')}
      >
        laliguitadepadel.com
      </LinkButton>
      <P
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 3,
          marginHorizontal: 5,
        }}
      >
        .
      </P>
      <LinkButton
        color={theme.colors.greenDark}
        center
        style={{ textDecorationLine: 'none' }}
        onPress={() =>
          Linking.openURL('https://laliguitadepadel.com/politica-de-privacidad')
        }
      >
        Privacy Politics
      </LinkButton>
      <P
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          bottom: 3,
          marginHorizontal: 5,
        }}
      >
        .
      </P>
      <LinkButton
        color={theme.colors.greenDark}
        center
        style={{ textDecorationLine: 'none' }}
        onPress={() => Linking.openURL('https://laliguitadepadel.com/rules')}
      >
        FAQs
      </LinkButton>
    </View>
  )
}
const styles = StyleSheet.create({
  footerContainer: {
    alignSelf: 'center',
    flexDirection: 'row',
    flex: 1,
    alignItems: 'flex-end',
    marginBottom: 40,
  },
})
