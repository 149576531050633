import { Theme } from '../themes/Theme'

export const defaultTextStyles = (theme: Theme) => {
  return {
    fontFamily: theme.text.fontPrimary,
    fontFamilyBold: theme.text.fontPrimaryBold,
    color: theme.text.color,
    fontSize: 14,
    lineHeightEm: 1.5,
    marginEm: 0.5,
    paddingEm: 0.5,
  }
}

export const defaultTextStylesByFontSize = (
  theme: Theme,
  fontSize: number
): {} => {
  fontSize = fontSize || defaultTextStyles(theme).fontSize

  return {
    fontSize,
    fontFamily: theme.text.fontPrimarySemiBold,
    color: defaultTextStyles(theme).color,
    lineHeight: fontSize * defaultTextStyles(theme).lineHeightEm,
  }
}
