import { apiClient } from '../../support/apiClient'
import { AxiosResponse } from 'axios'
import { UserJson } from './UserJson'
import { User } from './User'

interface GetMeResponse {
  player: UserJson
}

export class UserApi {
  static getUser(): Promise<User> {
    return apiClient
      .get(`/me`)
      .then((axiosResponse: AxiosResponse<GetMeResponse>) => {
        // console.log(`/me`, axiosResponse)
        const response = axiosResponse.data
        return User.fromJson(response.player)
      })
  }
}
