import * as React from 'react'
import { FlatList, Image, StyleSheet, View } from 'react-native'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { NotificationCard } from '../../common/NotificationCard/NotificationCard'
import { Notification } from '../../../modules/notification/Notification'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { useNotificationsContext } from '../../../contexts/notifications/NotificationsContext'
import { Theme } from '../../ui/themes/Theme'
import { SpacerVertical } from '../../ui/layout/SpacerVertical/SpacerVertical'
import { ErrorMessage } from '../../common/ErrorMessage'
import { H1 } from '../../ui/texts/H1/H1'
import { H2 } from '../../ui/texts/H2/H2'
import { trans } from '../../../services/translationService'
import { LinearGradient } from 'expo-linear-gradient'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

type Props = MainStackScreenProps<'NotificationsScreen'>

export function NotificationsScreen({}: Props) {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const { notifications, getNotifications } = useNotificationsContext()

  React.useEffect(() => {
    getNotifications()
    // We don't want to repeat the 'GET /notifications' call ever, we only want to do it once.
    // We also avoid potential infinite loops if in the future getNotifications depends on
    // notifications (in NotificationsContext).
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!notifications) {
    // It could be that the 'GET /notifications' call in NotificationsContext is still in-flight,
    // but since it's unlikely, let's assume the call failed.
    return <ErrorMessage text="Error al obtener las notificaciones" />
  }

  const renderNotification = (notification: Notification) => (
    <View key={notification.id}>
      <NotificationCard notification={notification} />
    </View>
  )

  const RenderEmptyComponent = () => {
    return (
      <View
        style={{
          paddingVertical: 30,
          flex: 1,
          marginTop: '35%',
        }}
      >
        <Image
          resizeMode="contain"
          style={{
            alignSelf: 'center',
            height: 60,
            width: 60,
            marginBottom: 20,
          }}
          source={require('../../../assets/images/small-logo.png')}
        />

        <H1
          center
          style={{
            color: '#A8A8A8',
            fontSize: 22,
            fontFamily: 'Syne_800ExtraBold',
          }}
        >
          {`AHORA NO HAY \nNOTIFICACIONES`}
        </H1>
        <H2 center style={{ fontSize: 18, color: '#A8A8A8' }}>
          No tienes notificaciones pendientes!
        </H2>
      </View>
    )
  }

  return (
    <View style={{ flex: 1 }}>
      <LinearGradient
        colors={[
          'white',
          'white',
          'white',
          theme.background.color.toString(),
          theme.background.color.toString(),
          theme.background.color.toString(),
        ]}
        style={{
          position: 'absolute',
          height: '120%',
          width: '120%',
        }}
      />
      <FlatList
        data={notifications}
        renderItem={({ item }) => renderNotification(item)}
        ItemSeparatorComponent={() => <SpacerVertical size={20} />}
        keyExtractor={(notification) => notification.id}
        contentContainerStyle={styles.container}
        ListEmptyComponent={RenderEmptyComponent}
      />
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingVertical: 20,
      paddingHorizontal: theme.grid.gap * 2,
    },
  })
