import * as React from 'react'
import { ImageBackground, SafeAreaView, StyleSheet, View } from 'react-native'
import { Button, ButtonSetItem } from '../../ui/ui'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { Scoreboard } from '../../common/Scoreboard/Scoreboard'
import { useGame } from '../../../modules/game/useGame'
import { Loader } from '../../common/Loader/Loader'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { CancelGameModal } from './partials/CancelGameModal'
import { EnterScoreModal } from './partials/EnterScoreModal'
import { GameScreenHeader } from '../../common/GameScreenParts/GameScreenHeader'
import { MeContext } from '../../../contexts/me/MeContext'
import { ErrorMessage } from '../../common/ErrorMessage'
import { GameScore } from '../../../modules/game/Game'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { HeaderBackButtonProps } from '@react-navigation/elements'
import { HeaderBackButton } from '../../common/HeaderBackButton/HeaderBackButton'
import { ClubImage } from '../../common/ClubImage/ClubImage'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'screens.gameScreen',
  require('./GameScreen.i18n.json')
)

type Props = MainStackScreenProps<'GameScreen'>

export const GameScreen = ({ route, navigation }: Props) => {
  const { trans } = useTranslation()

  const { currentTeam } = React.useContext(MeContext)
  const result = useGame(route.params.gameId)

  const [cancelModalVisible, setCancelModalVisible] = React.useState(false)
  const [enterScoreModalVisible, setEnterScoreModalVisible] =
    React.useState(false)
  const theme = defaultTheme

  if (!currentTeam) {
    // This should never happen because at HomeScreen we navigate to MyGamesScreen only if we have currentTeam
    return <ErrorMessage text={'No currentTeam'} />
  }

  if (result === 'loading') {
    return <Loader />
  }

  if (result instanceof Error) {
    return <ErrorMessage text={result.message} />
  }

  navigation.setOptions({
    headerLeft: ({ onPress, canGoBack }: HeaderBackButtonProps) =>
      canGoBack && (
        <HeaderBackButton color={theme.colors.primary} onPress={onPress} />
      ),
    headerStyle: {
      backgroundColor: theme.colors.greenDark,
    },
    headerTitleStyle: {
      color: 'white',
    },
  })

  const { game, reloadGame } = result

  const isMyTeamGame = () => {
    return game.team1.id === currentTeam.id || game.team2.id === currentTeam.id
  }

  console.log(
    `game.date`,
    game.date,
    `game.isCancelled()`,
    game.isCancelled(),
    `game.isPast()`,
    game.isPast()
  )

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.colors.greenDark,
      }}
    >
      <SafeAreaView style={styles.container}>
        <ImageBackground
          style={{
            width: '100%',
            aspectRatio: 830 / 1792,
            position: 'absolute',
            alignSelf: 'center',
            zIndex: 1,
          }}
          source={require('../../../assets/images/background.png')}
        >
          <View style={{ marginTop: 30 }}>
            <GameScreenHeader
              club={game.club}
              date={game.date}
              team1={game.team1}
              team2={game.team2}
            />
          </View>

          <ClubImage style={{ bottom: 20 }} club={game.club} />

          <Scoreboard
            color="#716F86"
            game={game}
            gameScore={game.score ?? GameScore.empty()}
          />

          <View style={styles.footer}>
            <View>
              {game.resultCanBeSent && isMyTeamGame() ? (
                <ButtonSetItem>
                  <Button
                    onPress={() => {
                      setEnterScoreModalVisible(true)
                    }}
                    variant={ButtonVariant.primary}
                  >
                    {trans('screens.gameScreen.Record result')}
                  </Button>
                </ButtonSetItem>
              ) : null}

              {game.canBeCancelled && isMyTeamGame() ? (
                <ButtonSetItem style={{ marginTop: 10 }}>
                  <Button
                    onPress={() => {
                      setCancelModalVisible(true)
                    }}
                    variant={ButtonVariant.cancel}
                  >
                    {trans('screens.gameScreen.Cancel game')}
                  </Button>
                </ButtonSetItem>
              ) : null}

              {game.isCancelled() && (
                <Button
                  onPress={() => {}}
                  variant={ButtonVariant.cancel}
                  disabled={true}
                >
                  {trans('screens.gameScreen.Cancelled game')}
                </Button>
              )}
            </View>
          </View>
        </ImageBackground>
      </SafeAreaView>
      <CancelGameModal
        visible={cancelModalVisible}
        gameId={route.params.gameId}
        teamId={currentTeam.id}
        game={game}
        onRequestClose={() => setCancelModalVisible(false)}
        onGameCancelSuccess={() => {
          console.log(`GameScreen reloadGame`)
          setCancelModalVisible(false)
          reloadGame()
        }}
      />

      <EnterScoreModal
        visible={enterScoreModalVisible}
        onRequestClose={() => setEnterScoreModalVisible(false)}
        game={game}
      />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
  },
  footer: {
    bottom: 50,
  },
})
