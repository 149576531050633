import * as React from 'react'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { Theme } from '../../../ui/themes/Theme'
import { ColorValue, StyleSheet, View } from 'react-native'
import { defaultTheme } from '../../../ui/themes/default/defaultTheme'

interface Props {
  backgroundColor?: ColorValue
  children: React.ReactNode
}

export const TeamStatsPanel = ({ backgroundColor, children }: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  return (
    <View
      style={[styles.container, backgroundColor ? { backgroundColor } : {}]}
    >
      {children}
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.colors.greenDark,
      padding: theme.grid.gap * 2,
      borderRadius: 12,
      marginBottom: theme.grid.gap * 2,
      width: '100%',
      alignItems: 'center',
    },
  })
