import * as React from 'react'
import {
  Button,
  ButtonSet,
  ButtonSetItem,
  Modal,
  TextError,
} from '../../../ui/ui'
import { useTranslation } from '../../../../hooks/useTranslation'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { Theme } from '../../../ui/themes/Theme'
import { StyleSheet, View, Text } from 'react-native'
import { ButtonVariant } from '../../../ui/buttons/ButtonVariant/ButtonVariant'
import { PlayoffGame, PlayoffScore } from '../../../../modules/playoff/Playoff'
import { PlayoffApi } from '../../../../modules/playoff/PlayoffApi'
import { PlayoffScoreboard } from '../../../common/Scoreboard/PlayoffScoreboard'
import { defaultTheme } from '../../../ui/themes/default/defaultTheme'

interface Props {
  game: PlayoffGame
  visible: boolean
  onRequestClose: () => void
}

export const PlayoffEnterScoreModal = ({
  game,
  visible,
  onRequestClose,
}: Props) => {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const { trans } = useTranslation()

  const [gameScore, setGameScore] = React.useState<PlayoffScore>(
    game.score ?? null
  )

  const [showError, setShowError] = React.useState(false)

  const submitScore = () => {
    console.log('EnterScoreModal submit', gameScore)
    if (!gameScore) {
      setShowError(true)
      return
    }

    PlayoffApi.setGameScore(game.id, gameScore, 'STATUS_FINISHED')
      .then((result) => {
        console.log(`GameApi.setGameScore result`, result)
        game.score = gameScore
        closeModal()
      })
      .catch((error) => {
        console.log(`GameApi.setGameScore error`, error)
      })
  }

  const closeModal = () => {
    setShowError(false)
    onRequestClose()
  }

  return (
    <Modal visible={visible} onRequestClose={closeModal} style={styles.modal}>
      <View style={styles.scoreContainer}>
        <Text style={styles.title}>
          {trans('screens.gameScreen.Which it was the result?')}
        </Text>

        <PlayoffScoreboard
          game={game}
          gameScore={gameScore}
          onChangeGameScore={(newGameScore) => {
            setShowError(false)
            setGameScore(newGameScore)
          }}
          color={theme.colors.grey}
          editable
        />
      </View>

      <View style={styles.scoreValidation}>
        {showError && <TextError>{trans('validation.score')}</TextError>}
      </View>

      <ButtonSet>
        <ButtonSetItem>
          <Button
            variant={ButtonVariant.primary}
            onPress={(_) => {
              submitScore()
            }}
          >
            {trans('general.Confirm')}
          </Button>
        </ButtonSetItem>

        <ButtonSetItem>
          <Button variant={ButtonVariant.default} onPress={closeModal}>
            {trans('general.Go back')}
          </Button>
        </ButtonSetItem>
      </ButtonSet>
    </Modal>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    modal: {
      justifyContent: 'flex-start',
      marginTop: 105,
    },
    scoreContainer: {
      backgroundColor: theme.dark ? '#FFFFFF17' : '#000000AA',
      marginBottom: 18,
      borderRadius: 16,
      width: '100%',
      paddingVertical: 20,
      paddingHorizontal: 25,
    },
    title: {
      fontFamily: theme.text.fontPrimaryBold,
      color: theme.text.color,
      textAlign: 'center',
      fontSize: 18,
      marginBottom: 30,
    },
    scoreValidation: {
      height: 40,
    },
  })
