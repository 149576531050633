import * as React from 'react'
import { Badge } from '../Badge/Badge'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { useMyLastMinuteMatches } from '../../../../modules/lastMinuteMatch/useMyLastMinuteMatches'
import { defaultTheme } from '../../themes/default/defaultTheme'

interface Props {
  fontSize?: number
}

export const LastMinuteCountBadge = ({ fontSize }: Props) => {
  const theme = defaultTheme
  const myLastMinuteMatches = useMyLastMinuteMatches()

  if (myLastMinuteMatches === 'loading') return null

  if (myLastMinuteMatches instanceof Error) return null

  fontSize = fontSize || theme.text.fontSize

  return (
    <Badge
      text={myLastMinuteMatches.length.toString()}
      color={'#F72E47'}
      fontSize={fontSize}
    />
  )
}
