import * as React from 'react'
import { TextStyle, View } from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

export enum RowAlign {
  center = 'center',
  top = 'flex-start',
  bottom = 'flex-end',
}

interface Props {
  style?: TextStyle
  children: React.ReactNode
  verticalAlign?: RowAlign
}

export const Row = ({
  style = {},
  children,
  verticalAlign = RowAlign.top,
}: Props) => {
  const theme = defaultTheme

  return (
    <View
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        marginHorizontal: -theme.grid.gap,
        alignItems: verticalAlign,
        ...style,
      }}
    >
      {children}
    </View>
  )
}
