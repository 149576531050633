import * as React from 'react'
import { StyleProp, TextInput, TextStyle } from 'react-native'

interface Props {
  onChange: (value: number | null) => void
  style: StyleProp<TextStyle>
}

export const SetInput = ({ onChange, style }: Props) => {
  const [value, setValue] = React.useState('')
  return (
    <TextInput
      style={style}
      maxLength={1}
      autoCompleteType={'off'}
      keyboardType={'numeric'}
      autoCorrect={false}
      value={value}
      onChange={(event) => {
        onChange(inputToValue(event.nativeEvent.text))
        setValue('')
      }}
    />
  )
}

const valueToInput = (value: number | null): string => {
  return value !== null ? value.toString() : '-'
}

const inputToValue = (inputValue: string): number | null => {
  return isNaN(parseInt(inputValue)) ? null : parseInt(inputValue)
}
