import * as React from 'react'
import { ImageBackground, StyleSheet, View } from 'react-native'

interface Props {
  children?: React.ReactNode
}

export const ScoreboardWithBackground = ({ children }: Props) => {
  return (
    <ImageBackground
      style={styles.resultScoreboard}
      source={require('../../common/ScoreboardWithBackground/images/scoreboard-bg.png')}
    >
      <View style={styles.resultScoreboardInner}>{children}</View>
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  resultScoreboard: {
    height: 197,
    width: 304,
  },
  resultScoreboardInner: {
    paddingTop: 18,
    paddingHorizontal: 15,
  },
})
