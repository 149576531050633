import * as React from 'react'
import { ActivityIndicator, ColorValue, StyleSheet, View } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { theme } from '../../ui/themes/theme'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  size?: 'sm' | 'md' | 'lg' | number
  color?: ColorValue
}

const sizeToNumber = {
  sm: 10,
  md: 30,
  lg: 60,
}

export const Loader = ({ size = 'lg' }: Props) => {
  const theme = defaultTheme

  return (
    <View style={styles.container}>
      <LoadIndicator size={size} color={theme.colors.primary} />
    </View>
  )
}

export function LoadIndicator({ size = 'lg', color }: Props) {
  const theme = defaultTheme

  return (
    <ActivityIndicator
      color={color || theme.colors.primary}
      size={typeof size === 'number' ? size : sizeToNumber[size]}
    />
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
  },
})
