import axios, { AxiosResponse } from 'axios'
import { config } from '../config/config'
import { prettyPrint, prettyPrintOmitting } from './log'
import { LocalStorage } from '../data/LocalStorage'

axios.defaults.baseURL = config.apiBaseUrl
axios.defaults.timeout = 21000
axios.defaults.headers.post['Content-Type'] = 'application/json'

export const apiClient = axios.create()

// Log network requests and responses at the console with console.log.
// Alternatively, there's the option to use Flipper, which is more ergonomic.
const LOG_NETWORK_CALLS_AT_CONSOLE = false
if (LOG_NETWORK_CALLS_AT_CONSOLE) {
  function responseError(error: any) {
    return `RESPONSE error - ${error.config.method.toUpperCase()} ${
      error.config.url
    }`
  }

  apiClient.interceptors.request.use(
    (requestConfig) => {
      console.log(
        `REQUEST success - ${requestConfig.method?.toUpperCase()} ${
          requestConfig.url
        }\n`,
        prettyPrint(requestConfig)
      )
      return requestConfig
    },
    (error) => {
      console.log(
        `REQUEST error - ${error.config.method.toUpperCase()} ${
          error.config.url
        }\n`,
        prettyPrint(error)
      )
      return Promise.reject(error)
    }
  )

  apiClient.interceptors.response.use(
    (response) => {
      // Response status code is 2XX
      /*
      if (response.config.url?.includes('/xyz')) {
        console.log(
          `RESPONSE ${response.config.url}\n`,
          prettyPrint(response).substr(0, 250)
        )
        return response
      }
      */
      let responseLog = prettyPrintOmitting(response, ['request'])
      if (responseLog.length > 1800) {
        responseLog = responseLog.slice(0, 1800) + '(truncated)'
      }
      console.log(
        `RESPONSE success - ${response.config.method?.toUpperCase()} ${
          response.config.url
        }\n`,
        responseLog
      )
      return response
    },
    (error) => {
      // Response status code is _not_ 2XX
      console.log(
        `${responseError(error)} - error\n`,
        `status:`,
        error.response?.status,
        `\nmessage:`,
        prettyPrint(error.message)
      )
      console.log(
        `${responseError(error)} - error.config\n`,
        prettyPrint(error.config)
      )
      console.log(
        `${responseError(error)} - error.isAxiosError:`,
        error.isAxiosError
      )
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(
          `${responseError(error)} - error.response\n`,
          prettyPrintOmitting(error.response, ['config', 'request'])
        )
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest
        console.log(
          `${responseError(error)} - error.request\n`,
          prettyPrint(error.request)
        )
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log(
          `${responseError(error)} - no error.response nor error.request`
        )
      }
      return Promise.reject(error)
    }
  )
}

const UNAUTHORIZED_ENDPOINTS = [
  '/sign-in',
  '/token/refresh',
  '/passwords/reset_token',
]

function endpointRequiresAuthHeader(url: string): boolean {
  // The endpoint /passwords/reset_token has a query string: /passwords/reset_token?email=abc@example.com.
  // We need to remove it for includes() below to work.
  const urlNoQueryStrings = url.split('?')[0]
  // Note that by using includes() both strings need to be fully equal, not partially. Eg a sub-string like '/token'
  // will not work against eg '/token/refresh'. This is exactly what we want since there could be any other endpoint
  // that partially matches but requires auth header.
  return !UNAUTHORIZED_ENDPOINTS.includes(urlNoQueryStrings)
}

apiClient.interceptors.request.use(async (requestConfig) => {
  const url = requestConfig.url
  if (url && endpointRequiresAuthHeader(url)) {
    // TODO get token from AuthContext which is in memory, instead of reading from disk on every request
    const token = await LocalStorage.getAuthToken()
    if (token) {
      requestConfig.headers.Authorization = `Bearer ${token}`
    }
  }

  return requestConfig
})

// Refresh token on 401 and re-try the previous request
// https://stackoverflow.com/q/47216452/4034572
// https://github.com/axios/axios/issues/934#issuecomment-322003342

interface RefreshTokenResponse {
  auth_token: string
  refresh_token: string
}

interface RefreshTokenResult {
  newAuthToken: string
  newRefreshToken: string
}

// We must place this function here instead of being a static method of AuthApi (ie AuthApi.refreshToken) otherwise we
// have a require cycle. This is the console warning:
// Require cycle: src/modules/auth/AuthApi.ts -> src/support/apiClient.ts -> src/modules/auth/AuthApi.ts
// Require cycles are allowed, but can result in uninitialized values. Consider refactoring to remove the need for a cycle.
function refreshToken(
  currentRefreshToken: string
): Promise<RefreshTokenResult> {
  return apiClient
    .post(`/token/refresh`, {
      refresh_token: currentRefreshToken,
    })
    .then((axiosResponse: AxiosResponse<RefreshTokenResponse>) => {
      const response = axiosResponse.data
      return {
        newAuthToken: response.auth_token,
        newRefreshToken: response.refresh_token,
      }
    })
}

apiClient.interceptors.response.use(undefined, async (error) => {
  if (error.config && error.response && error.response.status === 401) {
    try {
      console.log('Server responded 401 - Expired JWT Token')
      const currentRefreshToken = await LocalStorage.getRefreshToken()
      if (currentRefreshToken) {
        const { newAuthToken, newRefreshToken } = await refreshToken(
          currentRefreshToken
        )
        await LocalStorage.setAuthTokens(newAuthToken, newRefreshToken)
        // TODO update AuthContext tokens or remove them from there so that they are always retrieved from LocalStorage
        error.config.headers.Authorization = `Bearer ${newAuthToken}`
        return axios.request(error.config)
      }
    } catch (e) {
      console.log(`apiClient error while refreshing token on 404`, e)
      // TODO logout?
    }
  }
  return Promise.reject(error)
})

// Modify response error and returns only error by api
/*
apiClient.interceptors.response.use(undefined, (error: any) => {
  console.log(
    `apiClient.interceptors.response onRejected - error\n`,
    prettyPrint(error)
  )
  let errorMessage = null

  // Api error
  if (error.response?.data?.error) {
    console.log(`apiClient.interceptors.response onRejected - Api error`)
    errorMessage = error.response.data.error.code
  }

  // Default Symfony error
  if (error.response?.data?.message) {
    console.log(
      `apiClient.interceptors.response onRejected - Default Symfony error`
    )
    errorMessage = error.response.data.error
  }

  if (errorMessage) {
    console.log(
      `apiClient.interceptors.response onRejected - errorMessage`,
      errorMessage
    )
    return Promise.reject(errorMessage)
  }

  return Promise.reject(error)
})
*/
