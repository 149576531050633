import * as React from 'react'
import { Image, ImageSourcePropType, StyleSheet, View } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { shadeColor } from '../../../support/shadeColor'
import { Theme } from '../../ui/themes/Theme'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  imageSource?: ImageSourcePropType
  size?: number
}

export const UserAvatar = ({ imageSource, size = 48 }: Props) => {
  const theme = defaultTheme
  const styles = getStyles(theme, size)

  return (
    <View style={styles.container}>
      {imageSource ? (
        <Image
          style={styles.container}
          source={imageSource}
          resizeMode="cover"
        />
      ) : (
        <View style={styles.container}>
          <Image
            style={{ height: 44, width: 44 }}
            height={48}
            width={48}
            source={require('../../../assets/images/isotipo.png')}
            resizeMode="center"
          />
        </View>
      )}
    </View>
  )
}

const getStyles = (theme: Theme, size: number) =>
  StyleSheet.create({
    container: {
      borderRadius: size / 2,
      width: size,
      height: size,
      backgroundColor: shadeColor(
        theme.background.color.toString(),
        theme.dark ? 100 : -100
      ),
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
