import * as React from 'react'
import { League } from '../../../modules/league/League'
import { LeagueApi } from '../../../modules/league/LeagueApi'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { MeContext } from '../../../contexts/me/MeContext'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { BackHandler } from 'react-native'
import { LeagueGroup } from '../../../modules/league/LeagueGroup'
import Collapsible from 'react-native-collapsible'
import { H1, P } from '../../ui/ui'
import { Entypo } from '@expo/vector-icons'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'leagueGroupSelector',
  require('./LeagueGroupSelector.i18n.json')
)

interface Props {
  setSelectedGroup: (leagueGroup: LeagueGroup) => void
  setSelectedCategoryName: (categoryName: string) => void
  onRequestClose?: () => void
  selectedGroup: LeagueGroup
  selectedCategoryName: string
}

export function LeagueGroupSelector({
  setSelectedGroup,
  setSelectedCategoryName,
  onRequestClose,
  selectedGroup,
  selectedCategoryName,
}: Props) {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const { currentTeam } = React.useContext(MeContext)

  const [league, setLeague] = React.useState<League | undefined>(undefined)
  const [dropdownLeagueVisible, setDropdownLeagueVisible] =
    React.useState(false)
  const [dropdownGroupVisible, setDropdownGroupVisible] = React.useState(false)

  React.useEffect(() => {
    if (currentTeam) {
      LeagueApi.getLeagueGroups(currentTeam.id).then((l) => setLeague(l))
    }
  }, [currentTeam])

  const filterCategory = React.useMemo(
    () =>
      league?.categories.filter((category) => {
        return category.name === selectedCategoryName
      }),
    [league?.categories, selectedCategoryName]
  )

  BackHandler.addEventListener('hardwareBackPress', () => {
    if (onRequestClose) {
      onRequestClose()
    }
    return true
  })

  if (!league || !filterCategory) {
    return null
  }
  console.log('filter', filterCategory)
  return (
    <View>
      <View>
        <TouchableOpacity
          activeOpacity={1}
          style={styles.selectLeague}
          onPress={() => setDropdownLeagueVisible(!dropdownLeagueVisible)}
        >
          <P style={styles.headerSubtitle}>Selecciona tu Liga</P>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <H1 style={styles.headerTitle}>{selectedCategoryName ?? '-'}</H1>

            {dropdownLeagueVisible ? (
              <Entypo name="chevron-up" size={35} color="black" />
            ) : (
              <Entypo name="chevron-down" size={35} color="black" />
            )}
          </View>
          <Collapsible
            collapsed={!dropdownLeagueVisible}
            style={styles.container}
          >
            {league.categories.map((category) => (
              <View key={category.id}>
                <TouchableOpacity
                  onPress={() => {
                    setSelectedCategoryName(category.name)
                    setDropdownLeagueVisible(false)
                    setSelectedGroup({ name: '-', id: selectedGroup.id })
                  }}
                  style={styles.league}
                >
                  <View style={styles.leagueLine} />
                  <Text style={styles.leagueName}>{category.name}</Text>
                  <View style={styles.leagueLine} />
                </TouchableOpacity>
              </View>
            ))}
          </Collapsible>
        </TouchableOpacity>
        <TouchableOpacity
          activeOpacity={1}
          style={styles.selectGroup}
          onPress={() => setDropdownGroupVisible(!dropdownGroupVisible)}
        >
          <P style={styles.headerSubtitle}>Selecciona tu grupo</P>
          <View
            style={{ flexDirection: 'row', justifyContent: 'space-between' }}
          >
            <H1 style={styles.headerTitle}>
              Grupo {selectedGroup?.name ?? '-'}
            </H1>

            {dropdownGroupVisible ? (
              <Entypo name="chevron-up" size={35} color="black" />
            ) : (
              <Entypo name="chevron-down" size={35} color="black" />
            )}
          </View>
          <Collapsible
            collapsed={!dropdownGroupVisible}
            style={{ backgroundColor: '#CEFFB4' }}
          >
            {filterCategory?.map((category) => (
              <View
                style={{
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                }}
                key={category.id}
              >
                {category.groups?.map((leagueGroup) => (
                  <View style={styles.leagueGroup} key={leagueGroup.id}>
                    <TouchableOpacity
                      onPress={() => {
                        setSelectedGroup(leagueGroup)
                        setDropdownGroupVisible(false)
                      }}
                      style={{
                        paddingTop: 10,
                        paddingHorizontal: 25,
                      }}
                    >
                      <Text style={styles.leagueGroupText}>
                        {leagueGroup.name}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            ))}

            {filterCategory[0]?.groups.length === 0 ? (
              <View style={styles.leagueGroup}>
                <View
                  style={{
                    paddingTop: 10,
                    paddingHorizontal: 25,
                  }}
                >
                  <Text style={{ color: '#62827A' }}>
                    No hay grupos en esta categoría
                  </Text>
                </View>
              </View>
            ) : null}
          </Collapsible>
        </TouchableOpacity>
      </View>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      backgroundColor: theme.background.color,
    },
    header: {
      paddingVertical: 10,
    },
    title: {
      color: theme.colors.greenDark,
      fontSize: 18,
      fontFamily: theme.text.fontPrimary,
      textAlign: 'center',
    },
    league: {
      flexDirection: 'row',
      alignItems: 'center',
      paddingVertical: 20,
    },
    leagueLine: {
      height: 1,
      color: theme.colors.greenDark,
      flex: 1,
    },
    leagueName: {
      color: theme.colors.greenDark,
      paddingHorizontal: 8,
      fontSize: 16,
      lineHeight: 16,
    },
    leagueGroup: {
      alignItems: 'center',
      width: '33%',
    },
    leagueGroupText: {
      color: theme.text.color,
      fontFamily: theme.text.fontSecondary,
      fontSize: 22,
    },
    headerSubtitle: {
      marginTop: 18,
      marginBottom: 5,
      color: '#62827A',
    },
    headerTitle: {
      fontSize: 24,
      fontFamily: 'Syne_800ExtraBold',
      marginBottom: 15,
    },
    selectLeague: {
      backgroundColor: theme.colors.primary,
      borderTopEndRadius: 25,
      borderTopStartRadius: 25,
      paddingBottom: 25,
      paddingHorizontal: 15,
    },
    selectGroup: {
      backgroundColor: '#CEFFB4',
      borderTopEndRadius: 25,
      borderTopStartRadius: 25,
      bottom: 25,
      paddingHorizontal: 15,
      paddingBottom: 10,
      borderBottomLeftRadius: 25,
      borderBottomRightRadius: 25,
    },
  })
