import { AppLanguage } from '../i18n/AppLanguage'

interface Config {
  apiBaseUrl: string
  /**
   * This constant controls things like:
   * - Showing the button that opens the Network Requests screen.
   * - Showing Alerts with extra information, eg network request response codes
   * and Symfony errors.
   */
  isDebug: boolean
  locale: string
  language: AppLanguage
  termsUrl: string
}

// process.env.API_ENV and process.env.ENABLE_DEBUG_FEATURES are defined in eas.json. Important: process.env variables
// are undefined when developing locally ('expo start' and 'expo publish'), they only work on EAS Build servers or when
// doing 'eas build --local'.
// See https://docs.expo.dev/build-reference/variables/#can-i-share-environment-variables-defined-in
// "When you define environment variables on build profiles in eas.json, they will not be available for local
// development when you run expo start."

export const config: Config = {
  apiBaseUrl:
    process.env.API_ENV === 'dev' || __DEV__
      ? 'https://api.laliguitadepadel.com/api'
      : 'https://api.laliguitadepadel.com/api',
  isDebug: process.env.ENABLE_DEBUG_FEATURES === 'true' || __DEV__,
  locale: 'es-ES',
  language: 'es',
  termsUrl: 'https://laliguitadepadel.com/politica-de-privacidad',
}
