import { Player } from '../player/Player'
import { Uuid } from '../../support/types'
import { RankingTrend } from '../game/RankingTrend'
import { TeamJson } from './TeamJson'
import { LeagueGroup } from '../league/LeagueGroup'

export class Team {
  id: Uuid
  player1: Player
  player2: Player
  rank: number
  rankingTrend: RankingTrend
  league: {
    id: string
    /** "Barcelona Primavera 2022" */
    name: string
  }
  leagueCategory: {
    id: string
    /** "masculino" */
    name: string
  }
  /** "A1" */
  leagueGroup: LeagueGroup
  hasPlayoff?: boolean

  constructor(
    id: Uuid,
    player1: Player,
    player2: Player,
    rank: number,
    rankingTrend: RankingTrend,
    league: {
      id: string
      name: string
    },
    leagueCategory: {
      id: string
      name: string
    },
    leagueGroup: LeagueGroup,
    hasPlayoff?: boolean
  ) {
    this.id = id
    this.player1 = player1
    this.player2 = player2
    this.rank = rank
    this.rankingTrend = rankingTrend
    this.league = league
    this.leagueCategory = leagueCategory
    this.leagueGroup = leagueGroup
    this.hasPlayoff = hasPlayoff
  }

  static fromJson(json: TeamJson): Team {
    const player1 = Player.fromJson(json.player1)
    const player2 = Player.fromJson(json.player2)
    const leagueGroup = LeagueGroup.fromJson(json.leagueGroup)
    return new Team(
      json.id,
      player1,
      player2,
      json.rank,
      json.rankingTrend,
      json.league,
      json.leagueCategory,
      leagueGroup,
      json?.hasPlayoff
    )
  }
}
