import { LastMinuteMatch } from './LastMinuteMatch'
import { ApiTeamAdapter } from '../team/ApiTeamAdapter'
import { parseISO } from 'date-fns'
import { ModelAdapter } from '../../support/interfaces'

export class ApiLastMinuteMatchAdapter
  implements ModelAdapter<LastMinuteMatch>
{
  adapt(item: any): LastMinuteMatch {
    const teamAdapter = new ApiTeamAdapter()

    return new LastMinuteMatch(
      item.id,
      parseISO(item.date),
      item.site,
      teamAdapter.adapt(item.team)
    )
  }
}
