import * as React from 'react'
import { Image, ImageBackground, StyleSheet, View } from 'react-native'
import { ScoreboardWithBackground } from '../ScoreboardWithBackground/ScoreboardWithBackground'
import { theme } from '../../ui/themes/theme'
import { ClubImage } from '../ClubImage/ClubImage'

interface Props {
  children?: React.ReactNode
}

export const GameScreenBody = ({ children }: Props) => {
  return (
    <View style={styles.result}>
      <View style={styles.resultBackgroundImage}>
        <View style={styles.resultInner}>
          {/*<ClubImage style={{ marginBottom: 15 }} club={game.club} />*/}

          <ScoreboardWithBackground>{children}</ScoreboardWithBackground>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  result: {
    marginTop: -50,
    flex: 1,
  },
  resultBackgroundImage: {
    width: '100%',
    height: undefined,
    aspectRatio: 746 / 754,
    backgroundColor: '#18362E',
  },
  resultInner: {
    paddingTop: 50,
    alignItems: 'center',
  },
  resultSponsorImage: {
    height: 70,
    marginBottom: 15,
  },
})
