import * as React from 'react'
import { Notification } from '../../modules/notification/Notification'
import { NotificationsApi } from '../../modules/notification/NotificationsApi'

type NotificationsContextValue = {
  notifications?: Notification[]
  getNotifications: () => void
}

const NotificationsContext = React.createContext(
  {} as NotificationsContextValue
)

export const useNotificationsContext = () =>
  React.useContext(NotificationsContext)

export function NotificationsContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [notifications, setNotifications] = React.useState<
    Notification[] | undefined
  >(() => {
    console.log(`NotificationsContextProvider initialized`)
    return undefined
  })

  React.useEffect(() => {
    NotificationsApi.getNotifications()
      .then((newNotifications) => {
        setNotifications(newNotifications)
      })
      .catch((error) => {
        // We ignore the error since we already have undefined as initial state
        console.log(
          `NotificationsContext useEffect getNotifications error`,
          error
        )
      })
  }, [])

  const getNotifications = React.useCallback(() => {
    NotificationsApi.getNotifications()
      .then((newNotifications) => {
        setNotifications(newNotifications)
        return newNotifications
      })
      .then((newNotifications) => {
        // getNotifications is called only in NotificationsScreen, so we mark all unread notifications as read
        const unreadNotificationIds = newNotifications
          .filter((n) => !n.isRead)
          .map((n) => n.id)
        if (unreadNotificationIds.length > 0) {
          return NotificationsApi.markNotificationsAsRead(unreadNotificationIds)
        }
        return undefined
      })
      .catch((error) => {
        // We ignore the error since we probably already got some notifications in the
        // useEffect() above, and we don't want to lose them.
        console.log(`NotificationsContext getNotifications error`, error)
      })
  }, [])

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        getNotifications,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}
