import * as React from 'react'
import { useUpcomingTournaments } from '../../../modules/tournament/useUpcomingTournaments'
import { Loader } from '../../common/Loader/Loader'
import { ScrollView, StyleSheet, View } from 'react-native'
import { ScreenTitle } from '../../common/ScreenTitle/ScreenTitle'
import { AlertBox } from '../../ui/alertbox/AlertBox'
import { Theme } from '../../ui/themes/Theme'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { useTranslation } from '../../../hooks/useTranslation'
import Carousel from 'react-native-snap-carousel'
import { translationService } from '../../../services/translationService'
import { Tournament } from '../../../modules/tournament/Tournament'
import { Container } from '../../ui/ui'
import { TournamentCard } from '../../common/TournamentCard/TournamentCard'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'screens.tournaments',
  require('./TournamentsScreen.i18n.json')
)

export const TournamentsScreen = () => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  const { trans } = useTranslation()

  const { isLoading, upcomingTournaments } = useUpcomingTournaments()

  const carousel: any = undefined

  const [activeIndex, setActiveIndex] = React.useState(0)

  // const ref = React.useRef<Carousel<any>>(null)

  const renderTournament = React.useCallback(
    (tournament: Tournament, index: number) => (
      <View style={styles.itemContainer}>
        <TournamentCard
          tournament={tournament}
          textIsVisible={true}
          clickable={true}
        />
      </View>
    ),
    [activeIndex]
  )

  if (isLoading) return <Loader />

  if (upcomingTournaments.length === 0)
    return (
      <View style={styles.container}>
        <ScreenTitle title={trans('screens.tournaments.title')} />
        <View style={styles.noResultContainer}>
          <AlertBox
            type={'warning'}
            title={trans('screens.tournaments.no_result_alert_title')}
            description={trans(
              'screens.tournaments.no_result_alert_description'
            )}
          />
        </View>
      </View>
    )

  if (upcomingTournaments.length !== 0) {
    return (
      <ScrollView>
        <View style={styles.container}>
          <Container>
            <ScreenTitle title={trans('screens.tournaments.title')} />
          </Container>

          {/*<View style={styles.carouselContainer}>*/}
          {/*  <Carousel*/}
          {/*    layout="default"*/}
          {/*    ref={ref}*/}
          {/*    data={upcomingTournaments}*/}
          {/*    sliderWidth={320}*/}
          {/*    itemWidth={320}*/}
          {/*    renderItem={({*/}
          {/*      item,*/}
          {/*      index,*/}
          {/*    }: {*/}
          {/*      item: Tournament*/}
          {/*      index: number*/}
          {/*    }) => renderTournament(item, index)}*/}
          {/*    onSnapToItem={(index) => setActiveIndex(index)}*/}
          {/*    inactiveSlideScale={1}*/}
          {/*    inactiveSlideOpacity={0.3}*/}
          {/*  />*/}
          {/*</View>*/}
        </View>
      </ScrollView>
    )
  }
  return null
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingVertical: 30,
    },
    noResultContainer: {
      marginTop: 80,
    },
    carouselContainer: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      height: 700,
    },
    itemContainer: {
      marginLeft: theme.grid.gap * 2,
      marginRight: 0,
    },
  })
