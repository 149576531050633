import * as React from 'react'
import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { Notification } from '../../../modules/notification/Notification'
import { useNavigation } from '@react-navigation/native'
import { MainStackScreenNavigationProp } from '../../../navigation/MainStackNavigator'
import BallIcon from '../../ui/themes/default/images/home/ball'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

interface Props {
  notification: Notification
}

export function NotificationCard({ notification }: Props) {
  const navigation =
    useNavigation<MainStackScreenNavigationProp<'NotificationsScreen'>>()

  const theme = defaultTheme
  const styles = getStyles(theme)

  const stylesWhenIsRead = getStylesWhenIsRead(theme)

  const image = notification.isRead
    ? theme.notification[notification.themeNotificationType()].imageSourceRead
    : theme.notification[notification.themeNotificationType()].imageSource

  const borderColor =
    theme.notification[notification.themeNotificationType()].color

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={() => {
        switch (notification.type) {
          case 'game.lastMinute.created':
          case 'game.cancelled.butLastMinute':
            navigation.navigate('LastMinuteScreen')
            return
          case 'game.cancelled.byTeam':
          case 'game.modified':
          case 'game.manually_created':
            if (notification.resourceId) {
              navigation.navigate('GameScreen', {
                gameId: notification.resourceId,
              })
            }
            return
        }
      }}
    >
      <View
        style={{
          ...styles.container,
          borderColor,
          ...(notification.isRead ? stylesWhenIsRead.container : {}),
        }}
      >
        <View style={styles.left}>
          <View
            style={{
              height: 45,
              width: 45,
              borderWidth: 1,
              borderColor: 'black',
              borderRadius: 30,
            }}
          />
          {/*<Image style={styles.image} source={image} />*/}
        </View>

        <View style={styles.right}>
          <Text style={styles.title}>{notification.title}</Text>
          <Text style={styles.message}>
            {notification.message}{' '}
            {notification.resourceId && (
              <Text style={styles.linkText}>Ver cambio</Text>
            )}
          </Text>
        </View>

        {notification.isRead && (
          <View style={styles.read}>
            <BallIcon color={'white'} />
          </View>
        )}
      </View>
    </TouchableOpacity>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      borderRadius: 12,
      borderWidth: 1,
      borderColor: 'transparent',
      paddingVertical: 10,
      paddingHorizontal: 20,
      flexDirection: 'row',
      alignItems: 'center',
      backgroundColor: '#CEFFB4',
    },
    left: {
      width: 60,
    },
    right: {
      flex: 1,
    },
    image: {
      width: 40,
      height: 40,
      marginRight: 20,
      resizeMode: 'contain',
    },
    title: {
      fontFamily: theme.text.fontPrimaryBold,
      fontSize: 16,
      color: theme.text.color,
      marginBottom: 2,
    },
    message: {
      fontFamily: theme.text.fontPrimary,
      fontSize: 12,
      color: theme.text.colorMuted,
    },
    linkText: {
      color: theme.colors.greenDark,
      textDecorationLine: 'underline',
    },
    read: {
      backgroundColor: '#F72E6D',
      position: 'absolute',
      right: 10,
      top: 10,
      width: 25,
      height: 25,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 25,
    },
    readText: {
      color: theme.background.color,
      fontFamily: theme.text.fontPrimary,
    },
  })

const getStylesWhenIsRead = (theme: Theme) =>
  StyleSheet.create({
    container: {
      borderColor: '#CEFFB4',
    },
    image: {
      tintColor: 'grey',
    },
  })
