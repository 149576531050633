import * as React from 'react'
import { Text } from 'react-native'
import { TextPropTypes } from '../TextPropTypesInterface'
import { defaultTextStylesByFontSize } from '../defaultTextStyles'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

export const P = (props: TextPropTypes) => {
  const theme = defaultTheme

  const fontSize = props.style?.fontSize || theme.text.fontSize

  return (
    <Text
      {...props}
      style={{
        ...defaultTextStylesByFontSize(theme, fontSize),
        textAlign: props.center ? 'center' : 'auto',
        ...props.style,
      }}
    >
      {props.children}
    </Text>
  )
}
