import * as React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import * as Animatable from 'react-native-animatable'
import { default as AccordionVendor } from 'react-native-collapsible/Accordion'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { Theme } from '../../themes/Theme'
import { IconDropdownCaret } from '../../icons/icons'
import { shadeColor } from '../../../../support/shadeColor'
import { defaultTheme } from '../../themes/default/defaultTheme'

interface Section {
  title: string
  content: any
}

interface Props {
  sections: Section[]
  multipleSelect?: boolean
}

export const Accordion = ({ sections, multipleSelect = true }: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  const [activeSections, setActiveSections] = React.useState([])
  const [multipleSelectState] = React.useState(multipleSelect)

  const renderHeader = (section: Section, _: any, isActive: boolean) => {
    return (
      <Animatable.View
        duration={400}
        style={styles.headerRow}
        transition="backgroundColor"
      >
        <Text style={styles.headerText}>{section.title}</Text>
        <Animatable.View
          style={{ rotation: isActive ? 180 : 0 }}
          transition="rotation"
        >
          <IconDropdownCaret />
        </Animatable.View>
      </Animatable.View>
    )
  }

  const renderContent = (section: Section, _: any, isActive: boolean) => {
    return (
      <Animatable.View
        duration={400}
        style={styles.content}
        transition="backgroundColor"
      >
        <Text style={styles.content}>{section.content}</Text>
      </Animatable.View>
    )
  }

  const updateActiveSections = (activeSections: any) => {
    setActiveSections(activeSections)
  }

  return (
    <AccordionVendor
      activeSections={activeSections}
      sections={sections}
      touchableComponent={TouchableOpacity}
      expandMultiple={multipleSelectState}
      renderHeader={renderHeader}
      renderContent={renderContent}
      duration={400}
      onChange={updateActiveSections}
      sectionContainerStyle={styles.section}
    />
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    section: {
      borderColor: 'transparent',
      borderBottomColor: shadeColor(theme.background.color.toString(), 150),
      borderWidth: 1,
    },
    headerRow: {
      backgroundColor: 'transparent',
      padding: 10,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerText: {
      fontFamily: theme.text.fontSecondary,
      color: theme.text.color,
      fontSize: 16,
    },
    content: {
      fontSize: theme.text.fontSize,
      lineHeight: theme.text.fontSize * 1.8,
      paddingVertical: 5,
      paddingHorizontal: 7,
      paddingBottom: 20,
      color: theme.text.colorGrey,
    },
    active: {},
    inactive: {},
  })
