import { apiClient } from '../../support/apiClient'
import { AxiosResponse } from 'axios'
import { Notification, NotificationType } from './Notification'

type ResponseNotification = {
  id: string
  /**
   * An id, eg "40064a51-0192-4b59-8a91-75d372c315d4". Is a game id for now, but in
   * the future can be an id of something else, depending on the notification type.
   * At the moment the resource_id will not be null but in the future it could.
   */
  resource_id: string | null
  /** "game.modified" | "game.cancelled.byTeam" */
  type: NotificationType
  /** 'Partido modificado' */
  title: string
  /** 'Tu partido del 25-10-21 se ha modificado <a>Ver cambio</a>' */
  text: string
  /** 0 */
  read: 0 | 1
  /** "2022-03-01T11:45:44+01:00" */
  created_at: '2022-03-01T11:45:44+01:00'
  /** "2022-03-01T11:45:44+01:00" */
  updated_at: '2022-03-01T11:45:44+01:00'
}

export class NotificationsApi {
  static getNotifications(): Promise<Notification[]> {
    return apiClient.get(`/notifications`).then(
      (
        response: AxiosResponse<{
          notifications: ResponseNotification[]
        }>
      ) => {
        return response.data.notifications.map(
          (notification: ResponseNotification) =>
            new Notification(
              notification.id,
              notification.type,
              notification.title,
              notification.text,
              !!notification.read,
              notification.resource_id
            )
        )
      }
    )
  }

  /**
   * @return { "status": "ok" }
   */
  static markNotificationsAsRead(
    notificationIds: string[]
  ): Promise<{ status: string }> {
    return apiClient.post(`/notifications`, notificationIds).then(
      (
        response: AxiosResponse<{
          status: string
        }>
      ) => {
        return response.data
      }
    )
  }
}
