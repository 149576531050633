import * as React from 'react'
import { Alert, StyleSheet, View, Image } from 'react-native'
import { AuthStackScreenProps } from '../../../navigation/AuthStackNavigator'
import {
  Button,
  Form,
  FormGroup,
  H1,
  CustomTextInput,
  P,
  KeyboardView,
  H2,
} from '../../ui/ui'
import { InputTypes } from '../../ui/inputs/CustomTextInput'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { AuthApi } from '../../../modules/auth/AuthApi'
import { extractApiError } from '../../../support/apiError'
import { Expand } from '../../ui/layout/Expand'
import { FullHeightScrollView } from '../../ui/FullHeightScrollView'
import { Footer } from '../../common/Footer/Footer'

translationService.addTranslations(
  'screens.passwordRecovery',
  require('./PasswordRecoveryScreen.i18n.json')
)

type Props = AuthStackScreenProps<'PasswordRecoveryScreen'>

export function PasswordRecoveryScreen({}: Props) {
  const { trans } = useTranslation()

  const [email, setEmail] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [message, setMessage] = React.useState({
    type: 'error' || 'success',
    text: '',
  })

  const recoverPassword = React.useCallback(() => {
    setLoading(true)
    AuthApi.recoverPassword(email)
      .then(() => {
        setLoading(false)
        setMessage({
          type: 'success',
          text: trans('screens.passwordRecovery.Success-message'),
        })
        Alert.alert(
          trans('screens.passwordRecovery.Success-title'),
          trans('screens.passwordRecovery.Success-message')
        )
      })
      .catch((error) => {
        setLoading(false)
        console.log(
          `PasswordRecoveryScreen AuthApi.recoverPassword error`,
          error,
          extractApiError(error)
        )
        setMessage({
          type: 'error',
          text: trans('screens.passwordRecovery.Error-message'),
        })

        Alert.alert(
          trans('screens.passwordRecovery.Error-title'),
          trans('screens.passwordRecovery.Error-message')
        )
      })
  }, [email, trans])

  return (
    <KeyboardView>
      <FullHeightScrollView keyboardShouldPersistTaps="always">
        <View style={styles.container}>
          <View style={{ marginBottom: 45 }}>
            <Image
              style={{ width: 160, height: 100 }}
              resizeMode={'contain'}
              source={require('../../../assets/images/app-logo-green.png')}
            />
          </View>
          <Expand flex={1} />

          <H2 center style={styles.title}>
            {`Recuperar\nContraseña`}
          </H2>

          <P style={styles.fillDataTitle}>
            Inserta el email de registro de tu usuario
          </P>

          <Form>
            <FormGroup>
              <CustomTextInput
                type={InputTypes.text}
                value={email}
                onChangeText={setEmail}
                placeholder={trans(
                  'screens.passwordRecovery.Email placeholder'
                )}
                center
                returnKeyType="done"
                onSubmitEditing={recoverPassword}
              />
            </FormGroup>

            <FormGroup style={styles.formGroupButton}>
              <Button
                variant={ButtonVariant.primary}
                onPress={recoverPassword}
                width="100%"
                loading={loading}
                disabled={email === ''}
              >
                ENVIAR
              </Button>
              <P
                center
                style={{
                  marginTop: 20,
                  color: message.type === 'success' ? '#18362E' : 'red',
                  marginHorizontal: 30,
                }}
              >
                {message.text}
              </P>
            </FormGroup>
          </Form>

          <Expand flex={2} />
        </View>
      </FullHeightScrollView>

      <Footer />
    </KeyboardView>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
  },
  title: {
    marginBottom: 30,
    fontSize: 30,
    // color: theme.colors.greenDark,
  },
  fillDataTitle: {
    marginBottom: 20,
  },
  formGroupButton: {
    marginBottom: 20,
  },
})
