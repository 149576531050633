import * as React from 'react'
import { AlertBox } from '../../ui/ui'

export const NoTeamSelectedAlert = () => {
  return (
    <AlertBox
      type={'error'}
      title={'No hay ningún equipo seleccionado.'}
      description={
        'Si no sabes en que grupo estas, ponte\n' +
        'en contacto con nosotros a traves de:\n' +
        'Consulta de Grupo/Nivel'
      }
    />
  )
}
