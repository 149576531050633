import * as React from 'react'
import { StatusBar, View } from 'react-native'
import AppLoading from 'expo-app-loading'
import {
  DMSans_500Medium,
  DMSans_700Bold,
  DMSans_400Regular,
  useFonts as useFontsInter,
} from '@expo-google-fonts/dm-sans'
import {
  Anton_400Regular,
  useFonts as useFontsAnton,
} from '@expo-google-fonts/anton'
import {
  Syne_800ExtraBold,
  useFonts as useFontsSyne,
} from '@expo-google-fonts/syne'
import {
  Poppins_600SemiBold,
  useFonts as useFontsPoppins,
} from '@expo-google-fonts/poppins'
import { AuthStackNavigator } from './src/navigation/AuthStackNavigator'
import { NavigationContainer, Theme } from '@react-navigation/native'
import { AppDrawerNavigator } from './src/navigation/AppDrawerNavigator'
import {
  ThemeContext,
  ThemeContextProvider,
} from './src/contexts/theme/ThemeContext'
import {
  AuthContext,
  AuthContextProvider,
} from './src/contexts/auth/AuthContext'
import { AuthStatus } from './src/contexts/auth/AuthStatus'
import { LoadingScreen } from './src/components/screens/LoadingScreen/LoadingScreen'
import { defaultTheme } from './src/components/ui/themes/default/defaultTheme'

// TODO isHeadless prop check for iOS - see https://rnfirebase.io/messaging/usage#background-application-state

export default function App() {
  const [dmsansFontsLoaded] = useFontsInter({
    DMSans_400Regular,
    DMSans_500Medium,
    DMSans_700Bold,
  })

  const [poppinsFontsLoaded] = useFontsPoppins({
    Poppins_600SemiBold,
  })

  const [antonFontsLoaded] = useFontsAnton({
    Anton_400Regular,
  })

  const [syneFontsLoaded] = useFontsSyne({
    Syne_800ExtraBold,
  })

  if (
    !dmsansFontsLoaded ||
    !antonFontsLoaded ||
    !poppinsFontsLoaded ||
    !syneFontsLoaded
  ) {
    return <AppLoading />
  }

  return (
    <AppState>
      <AppInner />
    </AppState>
  )
}

const AppState = ({ children }: any) => {
  return (
    <ThemeContextProvider>
      <AuthContextProvider>{children}</AuthContextProvider>
    </ThemeContextProvider>
  )
}

const AppInner = () => {
  const theme = defaultTheme

  const { authStatus } = React.useContext(AuthContext)

  const myTheme: Theme = {
    dark: theme.dark,
    colors: {
      primary: theme.colors.primary.toString(),
      background: theme.background.color.toString(),
      card: theme.background.color.toString(),
      text: theme.text.color.toString(),
      border: 'transparent',
      notification: '#F72E47',
    },
  }

  return (
    <NavigationContainer theme={myTheme}>
      <StatusBar
        translucent={true}
        backgroundColor={theme.background.color}
        barStyle={theme.dark ? 'dark-content' : 'dark-content'}
      />
      {authStatus === AuthStatus.checking && <LoadingScreen />}
      {authStatus === AuthStatus.notAuthenticated && <AuthStackNavigator />}
      {authStatus === AuthStatus.authenticated && <AppDrawerNavigator />}
    </NavigationContainer>
  )
}
