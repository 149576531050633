import * as React from 'react'
import { Text, TextStyle, View } from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

type Props = {
  label?: string
  style?: TextStyle
  children: React.ReactNode
}

export const FormGroup = ({ label, style, children }: Props) => {
  const theme = defaultTheme

  return (
    <View
      style={{
        marginBottom: theme.input.fontSize * 1.2,
        ...style,
      }}
    >
      {label && <Text style={{ marginBottom: 10 }}>{label}</Text>}
      {children}
    </View>
  )
}
