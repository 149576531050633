import * as React from 'react'
import { HomeScreen } from '../components/screens/HomeScreen/HomeScreen'
import { GameScreen } from '../components/screens/GameScreen/GameScreen'
import { NotificationsScreen } from '../components/screens/NotificationsScreen/NotificationsScreen'
import { ProfileScreen } from '../components/screens/ProfileScreen/ProfileScreen'
import { MyGamesScreen } from '../components/screens/MyGamesScreen/MyGamesScreen'
import { FaqsScreen } from '../components/screens/FaqsScreen/FaqsScreen'
import {
  createStackNavigator,
  StackNavigationProp,
  StackScreenProps,
} from '@react-navigation/stack'
import { ThemeContext } from '../contexts/theme/ThemeContext'
import { stackNavigationOptions } from './stackNavigationOptions'
import { Uuid } from '../support/types'
import { RankingScreen } from '../components/screens/RankingScreen/RankingScreen'
import { translationService } from '../services/translationService'
import { useTranslation } from '../hooks/useTranslation'
import { LastMinuteScreen } from '../components/screens/LastMinuteScreen/LastMinuteScreen'
import { LastMinuteMatchScreen } from '../components/screens/LastMinuteMatchScreen/LastMinuteMatchScreen'
import { TournamentsScreen } from '../components/screens/TournamentsScreen/TournamentsScreen'
import { TournamentScreen } from '../components/screens/TournamentScreen/TournamentScreen'
import { TeamStatsScreen } from '../components/screens/TeamStatsScreen/TeamStatsScreen'
import { PlayoffsScreen } from '../components/screens/PlayoffsScreen/PlayoffsScreen'
import { NetworkLoggerScreen } from '../components/screens/NetworkLoggerScreen'
import { OtherTeamGamesScreen } from '../components/screens/OtherTeamGamesScreen/OtherTeamGamesScreen'
import { PlayoffGameScreen } from '../components/screens/GameScreen/PlayoffGameScreen'
import { LeagueGroup } from '../modules/league/LeagueGroup'
import { defaultTheme } from '../components/ui/themes/default/defaultTheme'

translationService.addTranslations(
  'navigation.mainStackNavigation',
  require('./MainStackNavigation.i18n.json')
)

type MainStackParamList = {
  NetworkLoggerScreen: undefined
  HomeScreen: undefined
  ProfileScreen: undefined
  GameScreen: { gameId: Uuid }
  PlayoffGameScreen: { playoffGameId: Uuid }
  MyGamesScreen: undefined
  OtherTeamGamesScreen: { teamId: Uuid }
  RankingScreen: undefined
  NotificationsScreen: undefined
  FaqsScreen: undefined
  LastMinuteScreen: undefined
  LastMinuteMatchScreen: { lastMinuteMatchId: Uuid }
  TournamentsScreen: undefined
  TournamentScreen: { tournamentId: Uuid }
  TeamStatsScreen: { teamId: Uuid }
  PlayoffsScreen: undefined
}

/**
 * Usage:
 * ```
 * type Props = MainStackScreenProps<'ProfileScreen'>
 *
 * export function ProfileScreen({ navigation }: Props) {
 *   ...
 * }
 * ```
 */
export type MainStackScreenProps<Screen extends keyof MainStackParamList> =
  StackScreenProps<MainStackParamList, Screen>

/**
 * Usage:
 * ```
 * const navigation = useNavigation<MainStackScreenNavigationProp<'HomeScreen'>>()
 * navigation.navigate('NotificationsScreen')
 * ```
 */
export type MainStackScreenNavigationProp<
  Screen extends keyof MainStackParamList
> = StackNavigationProp<MainStackParamList, Screen>

const Stack = createStackNavigator<MainStackParamList>()

export const MainStackNavigator = () => {
  const theme = defaultTheme

  const { trans } = useTranslation()

  return (
    <Stack.Navigator
      screenOptions={stackNavigationOptions(theme)}
      initialRouteName="HomeScreen"
    >
      <Stack.Screen
        name="NetworkLoggerScreen"
        component={NetworkLoggerScreen}
        options={{ headerShown: true, title: '' }}
      />
      <Stack.Screen
        name="HomeScreen"
        component={HomeScreen}
        options={{ title: '' }}
      />
      <Stack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={{ title: trans('navigation.mainStackNavigation.Profile') }}
      />
      <Stack.Screen
        name="GameScreen"
        component={GameScreen}
        options={{
          title: trans('navigation.mainStackNavigation.Match details'),
        }}
      />
      <Stack.Screen
        name="MyGamesScreen"
        component={MyGamesScreen}
        options={{ title: trans('navigation.mainStackNavigation.My matches') }}
      />
      <Stack.Screen
        name="PlayoffGameScreen"
        component={PlayoffGameScreen}
        options={{ title: trans('navigation.mainStackNavigation.My matches') }}
      />
      <Stack.Screen
        name="OtherTeamGamesScreen"
        component={OtherTeamGamesScreen}
        options={{ title: trans('navigation.mainStackNavigation.Matches') }}
      />
      {/*//aca*/}
      <Stack.Screen
        name="RankingScreen"
        component={RankingScreen}
        options={{ title: trans('navigation.mainStackNavigation.Rankings') }}
      />
      <Stack.Screen
        name="NotificationsScreen"
        component={NotificationsScreen}
        options={{
          title: trans('navigation.mainStackNavigation.Notifications'),
        }}
      />
      <Stack.Screen
        name="FaqsScreen"
        component={FaqsScreen}
        options={{ title: '' }}
      />
      <Stack.Screen
        name="LastMinuteScreen"
        component={LastMinuteScreen}
        options={{
          title: trans('navigation.mainStackNavigation.Last Minute Matches'),
        }}
      />
      <Stack.Screen
        name="LastMinuteMatchScreen"
        component={LastMinuteMatchScreen}
        options={{
          title: trans('navigation.mainStackNavigation.Last Minute Match'),
        }}
      />
      <Stack.Screen
        name="TournamentsScreen"
        component={TournamentsScreen}
        options={{
          title: trans('navigation.mainStackNavigation.Upcoming Tournaments'),
        }}
      />
      <Stack.Screen
        name="TournamentScreen"
        component={TournamentScreen}
        options={{
          title: trans('navigation.mainStackNavigation.Tournament'),
        }}
      />
      <Stack.Screen
        name="TeamStatsScreen"
        component={TeamStatsScreen}
        options={{
          title: trans('navigation.mainStackNavigation.Stats'),
        }}
      />
      <Stack.Screen
        name="PlayoffsScreen"
        component={PlayoffsScreen}
        options={{ title: trans('navigation.mainStackNavigation.Playoffs') }}
      />
    </Stack.Navigator>
  )
}
