import { ModelAdapter } from '../../support/interfaces'
import { Playoff } from './Playoff'
import { ApiGameAdapter } from '../game/ApiGameAdapter'

const apiMatchAdapter = new ApiGameAdapter()

export class ApiPlayoffAdapter implements ModelAdapter<Playoff> {
  adapt(item: any): Playoff {
    return new Playoff(
      item.id,
      item.leagueGroupId,
      apiMatchAdapter.adapt(item.quarters1),
      apiMatchAdapter.adapt(item.quarters2),
      apiMatchAdapter.adapt(item.quarters3),
      apiMatchAdapter.adapt(item.quarters4),
      apiMatchAdapter.adapt(item.semifinals1),
      apiMatchAdapter.adapt(item.semifinals2),
      apiMatchAdapter.adapt(item.final)
    )
  }
}
