import { TeamSimple } from '../team/TeamSimple'
import { Uuid } from '../../support/types'
import { GameStatus } from '../game/GameStatus'

interface Result {
  set_1: string
}

export interface PlayoffScore {
  [teamId: string]: Result
}

export interface Category {
  id: string
  name: string
  playoffs: Playoff[]
}

export interface Playoff {
  id: string
  name: string
  games?: PlayoffGame[]
}

export interface PlayoffLeague {
  id: string
  name: string
  categories: Category[]
}

export interface GamesSorted {
  roundOf16: PlayoffGame[]
  roundOf8: PlayoffGame[]
  roundOf4: PlayoffGame[]
  semi: PlayoffGame[]
  final: PlayoffGame[]
}

export class PlayoffGame {
  id: Uuid
  round: number
  order: number
  scheduled_on: Date
  date: Date
  club: string
  first_team: TeamSimple
  second_team: TeamSimple
  first_game?: any
  second_game?: any
  status: GameStatus
  score: PlayoffScore
  resultCanBeSent: boolean
  canBeCancelled: boolean

  constructor(
    id: Uuid,
    round: number,
    order: number,
    scheduled_on: Date,
    date: Date,
    club: string,
    first_team: TeamSimple,
    second_team: TeamSimple,
    score: PlayoffScore,
    status: GameStatus,
    resultCanBeSent: boolean,
    canBeCancelled: boolean,
    first_game?: any,
    second_game?: any
  ) {
    this.id = id
    this.round = round
    this.order = order
    this.scheduled_on = scheduled_on
    this.date = date
    this.club = club
    this.first_team = first_team
    this.second_team = second_team
    this.first_game = first_game
    this.second_game = second_game
    this.score = score
    this.status = status
    this.resultCanBeSent = resultCanBeSent
    this.canBeCancelled = canBeCancelled
  }

  static fromJson(game: PlayoffGame): PlayoffGame {
    return new PlayoffGame(
      game.id,
      game.round,
      game.order,
      game.scheduled_on,
      game.date,
      game.club,
      game.first_team,
      game.second_team,
      game.score,
      game.status,
      game.resultCanBeSent,
      game.canBeCancelled,
      game.first_game,
      game.second_game
    )
  }

  winner(): TeamSimple | undefined {
    if (!this.score) {
      return undefined
    }

    const firstTeamScore = this.score[this.first_team.id]
    const secondTeamScore = this.score[this.second_team.id]

    return parseInt(firstTeamScore?.set_1) > parseInt(secondTeamScore?.set_1)
      ? this.first_team
      : this.second_team
  }

  isPast(): boolean {
    return (
      new Date(this.date).setHours(0, 0, 0, 0) <=
      new Date().setHours(0, 0, 0, 0)
    )
  }

  isToday(): boolean {
    const today = new Date()
    return (
      this.date.getDate() === today.getDate() &&
      this.date.getMonth() === today.getMonth() &&
      this.date.getFullYear() === today.getFullYear()
    )
  }

  isCancelled(): boolean {
    return this.status === 'STATUS_CANCELLED'
  }

  team1ShortNames(): string | undefined {
    if (!this.first_team) {
      return '- / -'
    }
    return `${this.first_team.player1.name
      .substr(0, 3)
      .toUpperCase()} / ${this.first_team.player2.name
      .substr(0, 3)
      .toUpperCase()}`
  }

  team2ShortNames(): string | undefined {
    if (!this.second_team) {
      return '- / -'
    }
    return `${this.second_team.player1.name
      .substr(0, 3)
      .toUpperCase()} / ${this.second_team.player2.name
      .substr(0, 3)
      .toUpperCase()}`
  }
}
