import * as React from 'react'
import Svg, { SvgProps, Path } from 'react-native-svg'
/* SVGR has dropped some elements not supported by react-native-svg: title */

function IconEyeOpen(props: SvgProps) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" {...props}>
      <Path
        d="M17.031 15.5h2.407c0 .421-.092.839-.271 1.227-.18.389-.441.74-.77 1.034-.33.294-.721.524-1.15.677a3.719 3.719 0 01-1.345.213 3.63 3.63 0 01-2.325-.931 3.066 3.066 0 01-1.014-2.132c-.013-.421.066-.84.233-1.233.167-.392.418-.75.739-1.052.32-.302.704-.542 1.127-.706A3.71 3.71 0 0116 12.349v2.206c0 .25.109.49.302.668.193.177.456.277.73.277zm9.834-.363L27 15.5c-1.632 4.983-6.316 7.5-11 7.5-4.544 0-9.089-2.363-10.86-7.143L5 15.5C6.632 10.517 11.316 8 16 8c4.549 0 9.096 2.363 10.865 7.137zm-2.778.378C22.502 11.842 18.913 10.52 16 10.52c-2.954 0-6.529 1.321-8.094 4.964C9.499 19.158 13.087 20.48 16 20.48c2.947 0 6.522-1.321 8.087-4.964z"
        fill="#9B9B9B"
      />
    </Svg>
  )
}

export default IconEyeOpen
