import * as React from 'react'
import Svg, { Path } from 'react-native-svg'
import { defaultColor, defaultSize, IconProps } from './iconsSettings'

export const IconSuccess = ({
  size = defaultSize,
  color = defaultColor,
}: IconProps) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 551.13 551.13" fill="none">
      <Path
        d="m275.565 0c-151.944 0-275.565 123.621-275.565 275.565s123.621 275.565 275.565 275.565 275.565-123.621 275.565-275.565-123.621-275.565-275.565-275.565zm0 516.685c-132.955 0-241.119-108.164-241.119-241.119s108.164-241.12 241.119-241.12 241.12 108.164 241.12 241.119-108.165 241.12-241.12 241.12z"
        fill={color.toString()}
      />
      <Path
        d="m241.119 337.325-73.937-73.937-24.354 24.354 98.291 98.291 167.183-167.183-24.354-24.354z"
        fill={color.toString()}
      />
    </Svg>
  )
}
