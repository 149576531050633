import * as React from 'react'
import { Dimensions, StyleSheet, TouchableOpacity, View } from 'react-native'
import { PlayoffsCarousel } from './partials/PlayoffsCarousel'
import { PlayoffsCarouselItem } from './partials/PlayoffsCarouselItem'
import { ScreenTitle } from '../../common/ScreenTitle/ScreenTitle'
import { DropdownButton } from '../../ui/buttons/DropdownButton/DropdownButton'
import { MeContext } from '../../../contexts/me/MeContext'
import { Container } from '../../ui/layout/Container/Container'
import Collapsible from 'react-native-collapsible'
import { ScrollView } from 'react-native'
import { NoTeamSelectedAlert } from '../../common/NoTeamSelectedAlert/NoTeamSelectedAlert'
import {
  usePlayoffByTeamId,
  usePlayoffsLeagues,
  useShowPlayoff,
} from '../../../modules/playoff/usePlayoffByTeamId'
import { Loader } from '../../common/Loader/Loader'
import { trans } from '../../../services/translationService'
import { ErrorMessage } from '../../common/ErrorMessage'
import {
  PlayoffGroupSelector,
  PlayoffSimple,
} from '../../common/LeagueGroupSelector/PlayoffGroupSelector'
import { PlayoffGame, GamesSorted } from '../../../modules/playoff/Playoff'
import { useMemo } from 'react'
import { PlayoffsMatch } from './partials/PlayoffsMatch'
import { PlayoffsFinal } from './partials/PlayoffsFinal'
import { useNavigation } from '@react-navigation/native'
import { MainStackScreenNavigationProp } from '../../../navigation/MainStackNavigator'

export const PlayoffsScreen = () => {
  const { currentTeam } = React.useContext(MeContext)
  const navigation =
    useNavigation<MainStackScreenNavigationProp<'PlayoffsScreen'>>()

  const [selectedPlayoff, setSelectedPlayoff] = React.useState<PlayoffSimple>()
  const styles = getStyles()

  const [leagueGroupSelectorActive, setLeagueGroupSelectorActive] =
    React.useState(false)

  const playoff = usePlayoffByTeamId(currentTeam?.id)
  const playoffsLeague = usePlayoffsLeagues(currentTeam?.id)

  const showPlayoff = useShowPlayoff(selectedPlayoff?.id)

  const sortGames = (
    data: { games: PlayoffGame[] } | any
  ): GamesSorted | undefined => {
    if (!Array.isArray(data.games)) return undefined
    const sorted: GamesSorted = {
      roundOf16: [],
      roundOf8: [],
      roundOf4: [],
      semi: [],
      final: [],
    }

    const newPlayoffGame = (game: PlayoffGame) => {
      return new PlayoffGame(
        game.id,
        game.round,
        game.order,
        game.scheduled_on,
        game.date,
        game.club,
        game.first_team,
        game.second_team,
        game.score,
        game.status,
        game.resultCanBeSent,
        game.canBeCancelled,
        game.first_game,
        game.second_game
      )
    }

    for (const game of data.games as PlayoffGame[]) {
      switch (game.round) {
        case 16:
          sorted.roundOf16.push(newPlayoffGame(game))
          break
        case 8:
          sorted.roundOf8.push(newPlayoffGame(game))
          break
        case 4:
          sorted.roundOf4.push(newPlayoffGame(game))
          break
        case 2:
          sorted.semi.push(newPlayoffGame(game))
          break
        case 1:
          sorted.final.push(newPlayoffGame(game))
          break
        default:
          break
      }
    }

    for (const key in sorted) {
      if (sorted.hasOwnProperty(key)) {
        sorted[key as keyof GamesSorted].sort(
          (a: PlayoffGame, b: PlayoffGame) => a.order - b.order
        )
      }
    }

    return sorted
  }

  const gamesSorted = useMemo(
    () => sortGames(showPlayoff ? showPlayoff : playoff),
    [playoff, showPlayoff]
  )

  if (!currentTeam) return <NoTeamSelectedAlert />
  if (
    playoff === 'loading' ||
    playoffsLeague === 'loading' ||
    showPlayoff === 'loading'
  ) {
    return <Loader />
  }

  if (playoff instanceof Error) {
    return <ErrorMessage text={playoff.message} />
  }

  if (playoffsLeague instanceof Error) {
    return <ErrorMessage text={playoffsLeague.message} />
  }

  if (showPlayoff instanceof Error) {
    return <ErrorMessage text={showPlayoff.message} />
  }

  const navigateToGame = (gameId: string) => {
    console.log('NAVIGATE TO', gameId)
    if (!gameId) return
    navigation.navigate('PlayoffGameScreen', { playoffGameId: gameId })
  }

  console.log('gamesSorted', gamesSorted)

  if (playoff.games && gamesSorted)
    return (
      <ScrollView style={styles.container}>
        <Container style={{ zIndex: 2 }}>
          <View style={styles.header}>
            <ScreenTitle
              title={`${trans('general.Playoff')} ${
                showPlayoff?.name ? showPlayoff.name : playoff.name
              }`}
              subtitle={currentTeam.league.name}
              marginBottom={0}
            />
            <DropdownButton
              isActive={leagueGroupSelectorActive}
              onPress={() =>
                setLeagueGroupSelectorActive(!leagueGroupSelectorActive)
              }
            />
          </View>

          <Collapsible collapsed={!leagueGroupSelectorActive}>
            <PlayoffGroupSelector
              categories={playoffsLeague.categories}
              onSelect={(playoff, _leagueCategory) => {
                setSelectedPlayoff(playoff)
                setLeagueGroupSelectorActive(false)
              }}
              onRequestClose={() => setLeagueGroupSelectorActive(false)}
            />
          </Collapsible>
        </Container>

        <View style={styles.playoffsCarouselContainer}>
          <PlayoffsCarousel>
            {/* roundOf16 */}
            {gamesSorted.roundOf16.length > 0 ? (
              <View style={styles.playoffWrapper}>
                <View style={styles.quartersContainer}>
                  {gamesSorted.roundOf16.map((game) => {
                    return (
                      <TouchableOpacity
                        onPress={() => navigateToGame(game.id)}
                        key={game.id}
                        style={styles.playoffsMatchContainer}
                      >
                        <PlayoffsMatch game={game} />
                      </TouchableOpacity>
                    )
                  })}
                </View>
              </View>
            ) : null}

            {/* roundOf8 */}
            {gamesSorted.roundOf8.length > 0 ? (
              <View style={styles.playoffWrapper}>
                <View style={styles.quartersContainer}>
                  <View style={styles.quartersContainer}>
                    {gamesSorted.roundOf8.map((game) => {
                      return (
                        <TouchableOpacity
                          onPress={() => navigateToGame(game.id)}
                          key={game.id}
                          style={styles.playoffsMatchContainer}
                        >
                          <PlayoffsMatch game={game} />
                        </TouchableOpacity>
                      )
                    })}
                  </View>
                </View>
              </View>
            ) : null}

            {/* roundOf4 */}
            {gamesSorted.roundOf4.length > 0 ? (
              <PlayoffsCarouselItem>
                <View style={styles.semifinalsContainer}>
                  {gamesSorted.roundOf4.map((game) => {
                    return (
                      <TouchableOpacity
                        onPress={() => navigateToGame(game.id)}
                        key={game.id}
                        style={styles.playoffsMatchContainer}
                      >
                        <PlayoffsMatch game={game} />
                      </TouchableOpacity>
                    )
                  })}
                </View>
              </PlayoffsCarouselItem>
            ) : null}

            {/* Semi */}
            {gamesSorted.semi.length > 0 ? (
              <PlayoffsCarouselItem>
                {gamesSorted.semi.map((game) => {
                  return (
                    <TouchableOpacity
                      onPress={() => navigateToGame(game.id)}
                      key={game.id}
                      style={styles.semifinalsContainer}
                    >
                      <PlayoffsMatch game={game} />
                    </TouchableOpacity>
                  )
                })}
              </PlayoffsCarouselItem>
            ) : null}

            {/* Final */}
            {gamesSorted.final.length > 0 ? (
              <PlayoffsCarouselItem>
                <View>
                  <PlayoffsFinal
                    onPress={() => navigateToGame(gamesSorted?.final[0].id)}
                    game={gamesSorted.final[0]}
                  />
                </View>
              </PlayoffsCarouselItem>
            ) : null}
          </PlayoffsCarousel>
        </View>
      </ScrollView>
    )

  return null
}

const getStyles = () =>
  StyleSheet.create({
    container: {
      paddingTop: 30,
    },
    header: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    playoffsCarouselContainer: {
      zIndex: 0,
      top: -40,
    },
    playoffsMatchContainer: {},
    quartersContainer: {
      alignItems: 'center',
      justifyContent: 'space-around',
      flex: 1,
      paddingVertical: 30,
    },
    semifinalsContainer: {
      alignItems: 'center',
      justifyContent: 'space-around',
      flex: 1,
      paddingVertical: 50,
    },
    playoffWrapper: {
      flex: 1,
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height * 1.5,
    },
  })
