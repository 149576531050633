import * as React from 'react'
import { TextPropTypes } from '../TextPropTypesInterface'
import { Text } from 'react-native'
import {
  defaultTextStyles,
  defaultTextStylesByFontSize,
} from '../defaultTextStyles'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

export const H2 = (props: TextPropTypes) => {
  const theme = defaultTheme

  const fontSize = props.style?.fontSize || 35
  console.log('props.,style', props.style)
  return (
    <Text
      {...props}
      style={{
        ...defaultTextStylesByFontSize(theme, fontSize),
        fontFamily: theme.text.fontPrimaryBold,
        marginBottom: fontSize * defaultTextStyles(theme).marginEm,
        lineHeight: fontSize * 1.2,
        textAlign: props.center ? 'center' : 'auto',
        ...props.style,
      }}
    >
      {props.children}
    </Text>
  )
}
