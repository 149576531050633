import * as React from 'react'
import { View, ViewStyle } from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

enum ColAlign {
  center = 'center',
  left = 'flex-start',
  right = 'flex-end',
}

interface Props {
  style?: ViewStyle
  align?: ColAlign
  width?: number
  children: React.ReactNode
}

export const Col = ({
  style = {},
  align = ColAlign.left,
  width,
  children,
}: Props) => {
  const theme = defaultTheme

  if (width !== undefined && width > 1) {
    throw new DOMException()
  }

  return (
    <View
      style={{
        display: 'flex',
        alignItems: align,
        width: width !== undefined ? `${width * 100}%` : 'auto',
        paddingHorizontal: theme.grid.gap,
        ...style,
      }}
    >
      {children}
    </View>
  )
}
