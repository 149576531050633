import * as React from 'react'
import { GameApi } from './GameApi'
import { Game } from './Game'
import { Uuid } from '../../support/types'
import { extractApiError } from '../../support/apiError'

export function useGame(
  gameId: Uuid
): 'loading' | { game: Game; reloadGame: () => void } | Error {
  const [result, setResult] =
    React.useState<ReturnType<typeof useGame>>('loading')

  const getGame = React.useCallback(() => {
    setResult('loading')
    GameApi.getGame(gameId)
      .then((game) => {
        setResult({ game, reloadGame: getGame })
      })
      .catch((error) => {
        setResult(extractApiError(error))
      })
  }, [gameId])

  React.useEffect(() => {
    getGame()
  }, [getGame])

  return result
}
