import * as React from 'react'
import { ForwardedRef } from 'react'
import {
  Pressable,
  StyleSheet,
  TextInput,
  TextInputProps,
  View,
} from 'react-native'
import IconEyeClosed from '../icons/IconEyeClosed'
import IconEyeOpen from '../icons/IconEyeOpen'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../themes/default/defaultTheme'

export const PasswordTextInput = React.forwardRef(
  (props: TextInputProps, ref: ForwardedRef<TextInput>) => {
    const [showPassword, setShowPassword] = React.useState(false)

    const theme = defaultTheme
    const fontSize = theme.input.fontSize

    return (
      <View
        style={[
          styles.container,
          {
            borderColor: theme.input.borderColor,
            borderRadius: fontSize * 2,
          },
        ]}
      >
        <TextInput
          {...props}
          ref={ref}
          secureTextEntry={!showPassword}
          placeholderTextColor={theme.input.placeholderColor}
          style={[
            // Similar styles than CustomTextInput
            {
              backgroundColor: theme.input.backgroundColor,
              color: theme.input.color,
              paddingVertical: fontSize,
              paddingHorizontal: fontSize * 2,
              fontSize: fontSize,
            },
            styles.textInput,
          ]}
        />
        <Pressable
          style={styles.icon}
          onPress={() => setShowPassword((current) => !current)}
        >
          {showPassword ? <IconEyeClosed /> : <IconEyeOpen />}
        </Pressable>
      </View>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    borderWidth: 1,
  },
  textInput: {
    flex: 1,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  icon: {
    position: 'absolute',
    right: 14,
    alignSelf: 'center',
  },
})
