import * as React from 'react'
import {
  Modal as ModalNative,
  ModalProps,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../themes/Theme'
import { shadeColor } from '../../../support/shadeColor'
import { defaultTheme } from '../themes/default/defaultTheme'

interface Props extends ModalProps {
  children: React.ReactNode
  style?: ViewStyle
}

export const Modal = (props: Props) => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  const { width } = useWindowDimensions()

  return (
    <ModalNative
      animationType="slide"
      transparent={true}
      statusBarTranslucent={true}
      {...props}
    >
      <View style={[styles.container, props.style]}>
        <View style={[styles.inner, { width: width + 4, paddingBottom: 30 }]}>
          {props.children}
        </View>
      </View>
    </ModalNative>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: 'flex-end',
      backgroundColor: theme.background.color,
    },
    inner: {
      width: '100%',
      alignItems: 'center',
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      padding: 24,
      borderColor: shadeColor(theme.background.color.toString(), 150),
      // borderWidth: 2,
      borderBottomWidth: 0,
      marginLeft: -2,
    },
  })
