import * as React from 'react'
import {
  ColorValue,
  GestureResponderEvent,
  Image,
  ImageSourcePropType,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native'
import { H2, P } from '../../ui/ui'
import { NotificationsCountBadge } from '../../ui/badges/NotificationsCountBadge/NotificationsCountBadge'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { LastMinuteCountBadge } from '../../ui/badges/NotificationsCountBadge/LastMinuteCountBadge'
import BallIcon from '../../ui/themes/default/images/home/ball'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

type ShowNotifications = 'Notifications' | 'LastMinute' | 'none'
type ThemeType = 'light' | 'normal' | 'dark'

interface Props {
  title: string
  description: string
  icon: ImageSourcePropType
  showNotificationsCount?: ShowNotifications
  onPress: (event: GestureResponderEvent) => void
  themeType?: ThemeType
  extraPadding?: number
}

export const HomeTile = ({
  title,
  description,
  showNotificationsCount = 'none',
  onPress,
  themeType = 'normal',
  extraPadding,
  icon,
}: Props) => {
  const theme = defaultTheme

  let backgroundColor: ColorValue
  let titleColor: ColorValue
  let descriptionColor = '#62827A'

  switch (themeType) {
    case 'light':
      backgroundColor = '#CEFFB4'
      titleColor = theme.colors.greenDark
      break
    case 'normal':
      backgroundColor = theme.colors.primary
      titleColor = '#18362E'
      break
    case 'dark':
      backgroundColor = theme.colors.greenDark
      titleColor = theme.colors.primary
      break
  }

  const styles = getStyles(theme, backgroundColor, titleColor, descriptionColor)

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      style={styles.container}
      onPress={onPress}
    >
      <View style={{ alignSelf: 'flex-end', marginBottom: 5, flex: 1 }}>
        <Image
          source={icon}
          height={30}
          width={30}
          style={{ height: 30, width: 30 }}
          resizeMode={'cover'}
        />
      </View>
      <View style={{ height: extraPadding }} />
      <H2
        style={{
          fontSize: 16,
          color: titleColor,
          lineHeight: 27,
          minHeight: 48,
        }}
      >
        {title}
      </H2>
      <P style={styles.description}>{description}</P>

      {showNotificationsCount === 'Notifications' && (
        <View style={styles.badge}>
          <NotificationsCountBadge />
        </View>
      )}

      {showNotificationsCount === 'LastMinute' && (
        <View style={styles.badge}>
          <LastMinuteCountBadge />
        </View>
      )}
    </TouchableOpacity>
  )
}

const getStyles = (
  theme: Theme,
  backgroundColor: ColorValue,
  titleColor: ColorValue,
  descriptionColor: ColorValue
) =>
  StyleSheet.create({
    container: {
      backgroundColor: backgroundColor,
      padding: theme.grid.gap * 2,
      borderRadius: 25,
      marginBottom: theme.grid.gap * 2,
      width: '100%',
      flex: 1,
    },
    image: {
      width: 40,
      height: 40,
      marginBottom: 13,
    },
    title: {
      fontSize: 16,
      color: titleColor,
    },
    description: {
      fontSize: theme.text.fontSize * 0.92,
      color: descriptionColor,
    },
    badge: {
      position: 'absolute',
      top: 15,
      left: 15,
    },
  })
