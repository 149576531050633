import * as React from 'react'
import { Image, StyleSheet, View, Text, TouchableOpacity } from 'react-native'
import { TeamStatsPanel } from './partials/TeamStatsPanel'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { ScrollView } from 'react-native'
import { Col, Container, H2, P, Row } from '../../ui/ui'
import { useTeamStats } from '../../../modules/teamStats/useTeamStats'
import { Loader } from '../../common/Loader/Loader'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { RankingTrendIcon } from '../../common/RankingTrendIcon/RankingTrendIcon'
import { CancellationsBox } from '../../common/CancellationsBox/CancellationsBox'
import { ErrorMessage } from '../../common/ErrorMessage'
import ProgressBarColumns from '../../common/ProgressBarColumns'
import { LinearGradient } from 'expo-linear-gradient'
import ProgressBarSemicircle from '../../common/ProgressBarSemicircle'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'screens.teamStats',
  require('./TeamStatsScreen.i18n.json')
)

type Props = MainStackScreenProps<'TeamStatsScreen'>

export const TeamStatsScreen = ({ route, navigation }: Props) => {
  const theme = defaultTheme
  const styles = getStyles(theme)

  const teamStats = useTeamStats(route.params.teamId)

  const { trans } = useTranslation()

  if (teamStats === 'loading') {
    return <Loader />
  }

  if (teamStats instanceof Error) {
    return <ErrorMessage text={teamStats.message} />
  }

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <LinearGradient
        colors={[
          'white',
          'white',
          'white',
          theme.background.color.toString(),
          theme.background.color.toString(),
          theme.background.color.toString(),
        ]}
        style={{
          position: 'absolute',
          height: '120%',
          width: '120%',
        }}
      />
      <Container style={styles.container}>
        {/*<View style={styles.team}>*/}
        {/*  <H2 style={styles.teamPlayerNames}>*/}
        {/*    {team.player1.name} / {team.player2.name}*/}
        {/*  </H2>*/}
        {/*</View>*/}

        <TouchableOpacity
          activeOpacity={0.5}
          onPress={() =>
            navigation.navigate('OtherTeamGamesScreen', {
              teamId: route.params.teamId,
            })
          }
        >
          <View>
            <View>
              <View style={styles.top}>
                <P
                  center
                  style={{
                    fontFamily: 'Syne_800ExtraBold',
                    fontSize: 22,
                    color: 'white',
                  }}
                >
                  CLASIFICACIÓN
                </P>
              </View>

              <View style={styles.rankContainer}>
                <View style={styles.rankWrapper}>
                  <View style={styles.rankNumberContainer}>
                    <Text style={styles.rankNumber}>{teamStats.rank}</Text>
                    <RankingTrendIcon
                      trend={teamStats.rankingTrend}
                      size={16}
                    />
                  </View>
                  {['#7dc75b', '#457742', '#2a4d35'].map((item, index) => {
                    return (
                      <View
                        style={[
                          styles.rankNumberBackground,
                          {
                            left: 15 * (index + 1),
                            backgroundColor: item,
                            zIndex: -index,
                          },
                        ]}
                      />
                    )
                  })}

                  <Image
                    resizeMode="contain"
                    style={{ width: '50%' }}
                    source={require('../../../assets/images/app-logo-row.png')}
                  />
                </View>
              </View>

              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <ProgressBarColumns
                  width={60}
                  percentage={teamStats.playedCurrent / teamStats.playedTotal}
                />
                <View>
                  <H2
                    style={{
                      fontSize: 14,
                      color: theme.colors.greenDark,
                      lineHeight: 27,
                      marginTop: 0,
                    }}
                  >
                    {trans('screens.teamStats.Played games')}
                  </H2>
                  <Text style={styles.playedPercentage}>
                    {teamStats.playedCurrent}/{teamStats.playedTotal}
                  </Text>
                </View>
              </View>

              <View style={styles.separation} />

              {/*Juegos a favor, en contra y diferencia*/}
              <View style={styles.gamesTable}>
                <View style={{ width: '150%' }}>
                  <H2
                    center
                    numberOfLines={1}
                    style={{
                      fontSize: 24,
                      color: theme.colors.grey,
                      alignItems: 'center',
                      marginBottom: 20,
                      fontFamily: 'Syne_800ExtraBold',
                    }}
                  >
                    {trans('screens.teamStats.Games')}{' '}
                    <H2
                      center
                      numberOfLines={1}
                      style={{
                        fontSize: 24,
                        color: theme.colors.black,
                        alignItems: 'center',
                        marginBottom: 20,
                        fontFamily: 'Syne_800ExtraBold',
                      }}
                    >
                      {trans('screens.teamStats.Games')}{' '}
                    </H2>
                    {trans('screens.teamStats.Games')}
                  </H2>
                </View>
                <View style={{ flexDirection: 'row', width: '100%' }}>
                  <View style={styles.gamesTableRow}>
                    <P style={styles.gamesTableLabel}>
                      {trans('screens.teamStats.In favor')}
                    </P>
                    <P
                      style={{
                        fontSize: 38,
                        textAlign: 'right',
                        color: '#18362E',
                        fontFamily: theme.text.fontSecondary,
                      }}
                    >
                      {teamStats.gamesWon}
                    </P>
                  </View>

                  <View style={styles.separationVertical} />

                  <View style={styles.gamesTableRow}>
                    <P style={styles.gamesTableLabel}>
                      {trans('screens.teamStats.Against')}
                    </P>
                    <P
                      style={{
                        fontSize: 38,
                        textAlign: 'right',
                        color: '#18362E',
                        fontFamily: theme.text.fontSecondary,
                      }}
                    >
                      {teamStats.gamesLost}
                    </P>
                  </View>

                  <View style={styles.separationVertical} />

                  <View style={styles.gamesTableRow}>
                    <P style={styles.gamesTableLabel}>
                      {trans('screens.teamStats.Difference')}
                    </P>
                    <P
                      style={{
                        fontSize: 38,
                        textAlign: 'right',
                        color: '#18362E',
                        fontFamily: theme.text.fontSecondary,
                      }}
                    >
                      {teamStats.gamesDifference > 0
                        ? '+' + teamStats.gamesDifference
                        : teamStats.gamesDifference}
                    </P>
                  </View>
                </View>

                <View style={styles.separationHorizontal} />
              </View>

              {/* Sets Won / Sets ganados */}
              <TeamStatsPanel>
                <H2 style={styles.panelTitle}>
                  {trans('screens.teamStats.Sets won')}
                </H2>

                {/*<Text style={styles.setsWonNumber}>*/}
                {/*  {teamStats.setsWon}/{teamStats.setsTotal}*/}
                {/*</Text>*/}
                <ProgressBarSemicircle percentage={teamStats.setsPercent} />
                <Text style={styles.setsWonPercentage}>
                  {teamStats.setsPercent}%
                </Text>
              </TeamStatsPanel>

              <TeamStatsPanel>
                <Image
                  source={theme.stats.imageSources.wins}
                  style={styles.winsIcon}
                />
                <H2 style={styles.panelTitle}>
                  {trans('screens.teamStats.Victories')}
                </H2>
                <Text style={styles.winsNumber}>
                  {teamStats.winsCurrent}/{teamStats.winsPlayed}
                </Text>
              </TeamStatsPanel>

              {/*  Classified for Playoffs */}
              {/*<TeamStatsPanel>*/}
              {/*  <H2 style={styles.panelTitle}>*/}
              {/*    {trans('screens.teamStats.Playoffs')}*/}
              {/*  </H2>*/}
              {/*  {teamStats.isClassifiedForPlayoffs() && (*/}
              {/*    <Image*/}
              {/*      style={styles.playoffsImage}*/}
              {/*      source={theme.stats.imageSources.playoffsYes}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*  {!teamStats.isClassifiedForPlayoffs() && (*/}
              {/*    <Image*/}
              {/*      style={styles.playoffsImage}*/}
              {/*      source={theme.stats.imageSources.playoffsNo}*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</TeamStatsPanel>*/}
            </View>

            <View>
              {/*<TeamStatsPanel>*/}
              {/*  <H2 style={styles.panelTitle}>Ver partidos</H2>*/}
              {/*</TeamStatsPanel>*/}

              {/*
            <ClubImage
              imageSource={require('../../../assets/sampleImages/ads/.png')}
              backgroundColor="#ff2709"
              url="https://www.estrelladamm.com/"
              height={190}
            />
            */}
            </View>
          </View>
        </TouchableOpacity>

        <CancellationsBox
          count={teamStats.cancellationsDone}
          max={teamStats.cancellationsTotal}
        />
      </Container>
      <Image
        resizeMode="contain"
        style={{
          width: '40%',
          alignSelf: 'center',
          marginBottom: -20,
          height: 200,
        }}
        source={require('../../../assets/sampleImages/ads/bullpadel.png')}
      />
      <P center style={{ fontSize: 18 }}>
        Patrocinador oficial de La Liguita
      </P>
      <View style={{ height: 100 }}>
        <Image
          resizeMode="contain"
          style={{
            alignSelf: 'center',
            width: 300,
            bottom: 50,
          }}
          source={require('../../../assets/images/ball.png')}
        />
      </View>
    </ScrollView>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingBottom: 50,
      paddingTop: 15,
    },
    panelTitle: {
      fontSize: 22,
      color: theme.colors.primary,
      lineHeight: 27,
      marginBottom: 15,
      fontFamily: 'Syne_800ExtraBold',
    },
    playedMatchTitle: {
      fontSize: 14,
      color: theme.colors.greenDark,
      lineHeight: 27,
      marginTop: 0,
    },
    separation: {
      height: 1,
      backgroundColor: '#18362E',
      marginVertical: 20,
    },
    separationVertical: {
      height: '90%',
      width: 1,
      backgroundColor: '#18362E',
    },
    separationHorizontal: {
      width: '100%',
      height: 1,
      backgroundColor: '#18362E',
      marginTop: 10,
    },
    team: {
      marginBottom: 20,
    },
    teamPlayerNames: {
      fontSize: 25,
    },
    winsIcon: {
      width: 40,
      height: 40,
      borderRadius: 12,
      marginBottom: 13,
    },
    winsNumber: {
      color: theme.colors.primary,
      fontSize: 50,
    },
    setsWonPercentage: {
      color: theme.colors.primary,
      fontSize: 50,
      marginBottom: 10,
      bottom: 0,
      position: 'absolute',
    },
    setsWonNumber: {
      color: theme.colors.grey,
      alignSelf: 'flex-end',
    },
    playoffsImage: {
      aspectRatio: 1,
      height: 100,
      position: 'relative',
      marginTop: 10,
    },
    rankContainer: {
      backgroundColor: theme.colors.greenDark,
      bottom: 25,
      borderRadius: 25,
      paddingVertical: 10,
    },
    rankWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignSelf: 'center',
      alignItems: 'center',
      width: '85%',
      backgroundColor: theme.colors.greenDark,
      paddingVertical: 20,
      marginHorizontal: 30,
    },
    rankNumberContainer: {
      width: 75,
      height: 75,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 75,
      marginRight: 10,
      backgroundColor: theme.colors.primary,
      zIndex: 5,
    },
    rankNumberBackground: {
      width: 75,
      height: 75,
      position: 'absolute',
      alignItems: 'center',
      borderRadius: 75,
    },
    rankNumber: {
      color: theme.colors.greenDark,
      fontSize: 28,
      fontFamily: theme.text.fontPrimaryBold,
      top: 5,
    },
    playedPercentage: {
      color: '#18362E',
      fontSize: 50,
    },
    playedNumber: {
      color: theme.colors.grey,
      alignSelf: 'flex-end',
    },
    gamesTable: {
      width: '100%',
      alignItems: 'center',
      paddingVertical: 20,
    },
    gamesTableTitle: {
      fontSize: 24,
      color: theme.colors.grey,
      alignItems: 'center',
      marginBottom: 20,
      fontFamily: 'Syne_800ExtraBold',
    },
    gamesTableTitleBlack: {
      fontSize: 24,
      color: theme.colors.black,
      alignItems: 'center',
      marginBottom: 20,
      fontFamily: 'Syne_800ExtraBold',
    },
    gamesTableRow: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 10,
      width: '33%',
    },
    gamesTableLabel: {
      fontSize: 14,
      color: '#18362E',
      fontFamily: theme.text.fontSecondary,
    },
    gamesTableValue: {
      fontSize: 23,
      textAlign: 'right',
      color: '#18362E',
      fontFamily: theme.text.fontSecondary,
    },
    top: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      borderBottomWidth: 2,
      borderColor: theme.colors.primary,
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
      paddingBottom: 35,
      paddingTop: 15,
      backgroundColor: theme.colors.primary,
    },
  })
