import React, { useEffect, useRef } from 'react'
import { Animated, View, StyleSheet } from 'react-native'

const SemiCircleProgress = ({ percentage = 0 }) => {
  const rotationAnimation = useRef(new Animated.Value(0)).current

  useEffect(() => {
    animate()
  }, [percentage])

  const animate = () => {
    const toValue = Math.max(Math.min(percentage, 100), 0)

    Animated.timing(rotationAnimation, {
      toValue,
      duration: 1300,
      useNativeDriver: true,
    }).start()
  }

  const getStyles = () => {
    const circleRadius = 120
    const progressWidth = 20
    const interiorCircleRadius = circleRadius - progressWidth

    // @ts-ignore
    // @ts-ignore
    return StyleSheet.create({
      exteriorCircle: {
        width: circleRadius * 2,
        height: circleRadius,
        borderRadius: circleRadius,
        backgroundColor: 'silver',
      },
      rotatingCircleWrap: {
        width: circleRadius * 2,
        height: circleRadius,
        top: circleRadius,
      },
      rotatingCircle: {
        width: circleRadius * 2,
        height: circleRadius,
        borderRadius: circleRadius,
        backgroundColor: '#A3FC6F',
        transform: [
          { translateY: -circleRadius / 2 },
          {
            // @ts-ignore
            rotate: rotationAnimation.interpolate({
              inputRange: [0, 100],
              outputRange: ['0deg', '180deg'],
            }),
          },
          { translateY: circleRadius / 2 },
        ],
      },
      interiorCircle: {
        width: interiorCircleRadius * 2,
        height: interiorCircleRadius,
        borderRadius: interiorCircleRadius,
        backgroundColor: '#18362E',
        top: progressWidth,
      },
    })
  }

  const styles = getStyles()

  return (
    <View style={[defaultStyles.exteriorCircle, styles.exteriorCircle]}>
      <View
        style={[defaultStyles.rotatingCircleWrap, styles.rotatingCircleWrap]}
      >
        <Animated.View
          style={[defaultStyles.rotatingCircle, styles.rotatingCircle]}
        />
      </View>
      <View style={[defaultStyles.interiorCircle, styles.interiorCircle]} />
    </View>
  )
}

const defaultStyles = StyleSheet.create({
  exteriorCircle: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    alignItems: 'center',
    overflow: 'hidden',
  },
  rotatingCircleWrap: {
    position: 'absolute',
    left: 0,
  },
  rotatingCircle: {
    position: 'absolute',
    top: 0,
    left: 0,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  interiorCircle: {
    overflow: 'hidden',
    justifyContent: 'flex-end',
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
})

export default SemiCircleProgress
