import { RankingTrend } from '../game/RankingTrend'
import { Uuid } from '../../support/types'
import { TeamStatsJson } from './TeamStatsJson'

export class TeamStats {
  teamId: Uuid

  rank: number
  rankingTrend: RankingTrend

  playoffsTeams: number

  winsCurrent: number
  winsPlayed: number

  playedPercent: number
  playedTotal: number
  playedCurrent: number

  setsPercent: number
  setsTotal: number
  setsWon: number

  gamesWon: number
  gamesLost: number
  gamesDifference: number

  cancellationsTotal: number
  cancellationsDone: number

  constructor(arg: {
    teamId: Uuid
    rank: number
    rankingTrend: RankingTrend
    playoffsTeams: number
    winsCurrent: number
    winsPlayed: number
    playedPercent: number
    playedTotal: number
    playedCurrent: number
    setsPercent: number
    setsTotal: number
    setsWon: number
    gamesWon: number
    gamesLost: number
    gamesDifference: number
    cancellationsTotal: number
    cancellationsDone: number
  }) {
    this.teamId = arg.teamId
    this.rank = arg.rank
    this.rankingTrend = arg.rankingTrend
    this.playoffsTeams = arg.playoffsTeams
    this.winsCurrent = arg.winsCurrent
    this.winsPlayed = arg.winsPlayed
    this.playedPercent = arg.playedPercent
    this.playedTotal = arg.playedTotal
    this.playedCurrent = arg.playedCurrent
    this.setsPercent = arg.setsPercent
    this.setsTotal = arg.setsTotal
    this.setsWon = arg.setsWon
    this.gamesWon = arg.gamesWon
    this.gamesLost = arg.gamesLost
    this.gamesDifference = arg.gamesDifference
    this.cancellationsTotal = arg.cancellationsTotal
    this.cancellationsDone = arg.cancellationsDone
  }

  isClassifiedForPlayoffs() {
    return this.rank <= 8
  }

  static fromJson(teamId: string, json: TeamStatsJson): TeamStats {
    return new TeamStats({
      teamId: teamId,

      rank: json.rank,
      // TODO rankingTrend set value from json
      rankingTrend: 'up',

      // TODO playoffsTeams set value from json
      playoffsTeams: 2,

      winsCurrent: json.wins_current,
      winsPlayed: json.wins_played,

      playedPercent: json.played_percent,
      playedTotal: json.played_total,
      playedCurrent: json.played_current,

      setsPercent: json.sets_percent,
      setsTotal: json.sets_total,
      setsWon: json.sets_current,

      gamesWon: json.games_won,
      gamesLost: json.games_lost,
      gamesDifference: json.games_difference,

      cancellationsTotal: json.cancellations_total,
      cancellationsDone: json.cancellations_current,
    })
  }
}
