import * as React from 'react'

export const useForm = <T extends Object>(initState: T) => {
  const [form, setForm] = React.useState(initState)

  const change = (field: keyof T, value: string) => {
    setForm({
      ...form,
      [field]: value,
    })
  }

  return {
    ...form,
    form: form,
    change,
  }
}
