import * as React from 'react'
import { GameScreenHeader } from '../../common/GameScreenParts/GameScreenHeader'
import {
  Image,
  ImageBackground,
  SafeAreaView,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import { ButtonSet } from '../../ui/buttons/ButtonSet/ButtonSet'
import { ButtonSetItem } from '../../ui/buttons/ButtonSetItem/ButtonSetItem'
import { Button } from '../../ui/buttons/Button/Button'
import { ButtonVariant } from '../../ui/buttons/ButtonVariant/ButtonVariant'
import { MainStackScreenProps } from '../../../navigation/MainStackNavigator'
import { Loader } from '../../common/Loader/Loader'
import { useTranslation } from '../../../hooks/useTranslation'
import { translationService } from '../../../services/translationService'
import { Theme } from '../../ui/themes/Theme'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { AlertBox, H1, Modal, SpacerVertical } from '../../ui/ui'
import { MeContext } from '../../../contexts/me/MeContext'
import { LastMinuteApi } from '../../../modules/lastMinuteMatch/LastMinuteApi'
import { useLastMinuteMatch } from '../../../modules/lastMinuteMatch/useLastMinuteMatch'
import { ErrorMessage } from '../../common/ErrorMessage'
import { parseISO } from 'date-fns'
import { HeaderBackButtonProps } from '@react-navigation/elements'
import { HeaderBackButton } from '../../common/HeaderBackButton/HeaderBackButton'
import { dateToHuman, timeToHuman } from '../../../support/dates'
import ProgressBar from '../../common/ProgressBar'
import { ClubImage } from '../../common/ClubImage/ClubImage'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'screens.lastMinuteMatch',
  require('./LastMinuteMatchScreen.i18n.json')
)

type MatchingState = 'none' | 'generating' | 'matched' | 'unavailable'

type Props = MainStackScreenProps<'LastMinuteMatchScreen'>

export const LastMinuteMatchScreen = ({ route, navigation }: Props) => {
  const theme = defaultTheme

  const { currentTeam } = React.useContext(MeContext)

  const styles = getStyles(theme)
  const { trans } = useTranslation()

  const lastMinuteMatch = useLastMinuteMatch(route.params.lastMinuteMatchId)

  const [matchingState, setMatchingState] =
    React.useState<MatchingState>('none')

  if (lastMinuteMatch === 'loading') return <Loader />

  if (lastMinuteMatch instanceof Error) {
    return <ErrorMessage text={lastMinuteMatch.message} />
  }

  navigation.setOptions({
    headerLeft: ({ onPress, canGoBack }: HeaderBackButtonProps) =>
      canGoBack && (
        <HeaderBackButton color={theme.colors.primary} onPress={onPress} />
      ),
    headerStyle: {
      backgroundColor: theme.colors.greenDark,
    },
    headerTitleStyle: {
      color: 'white',
    },
  })
  console.log('lastMinuteMatch id', lastMinuteMatch?.id)
  console.log('currentTeam id', currentTeam?.id)

  const generateNewMatch = async () => {
    setMatchingState('none')

    if (lastMinuteMatch && currentTeam) {
      setMatchingState('generating')

      const confirmStatus = await LastMinuteApi.confirmGameLastMinute(
        lastMinuteMatch.id,
        currentTeam.id
      ).catch((error) => {
        console.log(`confirmGameLastMinute error`, error)
      })
      if (confirmStatus && confirmStatus.id) {
        setMatchingState('matched')
      } else {
        setMatchingState('unavailable')
      }
    }
  }

  const goToMyMatches = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: 'HomeScreen' }, { name: 'MyGamesScreen' }],
    })
  }

  const goToLastMinute = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: 'HomeScreen' }, { name: 'LastMinuteScreen' }],
    })
  }
  console.log('lastMinuteMatch 123', lastMinuteMatch)
  if (lastMinuteMatch) {
    return (
      <>
        {currentTeam && (
          <SafeAreaView style={styles.container}>
            <ImageBackground
              style={{
                height: '100%',
                aspectRatio: 830 / 1792,
                position: 'absolute',
                alignSelf: 'center',
                zIndex: 1,
              }}
              source={require('../../../assets/images/background.png')}
            >
              <GameScreenHeader
                club={lastMinuteMatch.club}
                team1={currentTeam}
                team2={
                  lastMinuteMatch?.second_team ??
                  lastMinuteMatch?.first_team ??
                  undefined
                }
              />

              <ClubImage style={{ bottom: 20 }} club={lastMinuteMatch.club} />

              <View>
                {matchingState === 'none' && (
                  <View style={styles.topContainer}>
                    <View style={styles.top}>
                      <H1 style={styles.date}>
                        {dateToHuman(parseISO(lastMinuteMatch.scheduled_on))} -{' '}
                        {timeToHuman(parseISO(lastMinuteMatch.scheduled_on))}
                      </H1>
                    </View>
                    <Text style={styles.title}>
                      {trans('screens.lastMinuteMatch.title')}
                    </Text>

                    <Text style={styles.disclaimer}>
                      Recuerda las condiciones de cancelación de partidos que se
                      aplican a Partidos abierto
                    </Text>
                  </View>
                )}

                {matchingState === 'matched' && (
                  <View style={styles.topContainer}>
                    <View style={styles.top}>
                      <H1 style={styles.date}>
                        {dateToHuman(parseISO(lastMinuteMatch.scheduled_on))} -{' '}
                        {timeToHuman(parseISO(lastMinuteMatch.scheduled_on))}
                      </H1>
                    </View>
                    <H1 style={styles.titleMatched}>
                      {'ESTÁIS APUNTADXS\n' + 'CON ÉXITO\n' + 'A ESTE PARTIDO '}
                    </H1>
                    <Image
                      resizeMode="contain"
                      style={{
                        alignSelf: 'center',
                        height: 40,
                        width: 40,
                        marginVertical: 10,
                      }}
                      source={require('../../../assets/images/smallbal.png')}
                    />
                    <Text style={styles.disclaimerMatched}>
                      Este partido ya está disponible en tu calendario
                    </Text>
                  </View>
                )}

                {matchingState === 'generating' && (
                  <View style={styles.topContainer}>
                    <View style={styles.top}>
                      <H1 style={styles.date}>
                        {dateToHuman(parseISO(lastMinuteMatch.scheduled_on))} -{' '}
                        {timeToHuman(parseISO(lastMinuteMatch.scheduled_on))}
                      </H1>
                    </View>
                    <Image
                      resizeMode="contain"
                      style={{
                        alignSelf: 'center',
                        height: 60,
                        width: 60,
                        marginBottom: 20,
                      }}
                      source={require('../../../assets/images/small-logo.png')}
                    />
                    {/*<View style={styles.generatingLoader}>*/}
                    <ProgressBar />
                    {/*</View>*/}
                    <Text style={styles.disclaimer}>
                      {trans('screens.lastMinuteMatch.Checking availability…')}
                    </Text>
                  </View>
                )}
              </View>

              <View style={styles.footer}>
                <ButtonSet>
                  <ButtonSetItem>
                    {matchingState === 'matched' ? (
                      <Button
                        onPress={() => {
                          navigation.goBack()
                        }}
                        variant={ButtonVariant.primary}
                      >
                        LET'S GO
                      </Button>
                    ) : (
                      <Button
                        onPress={() => {
                          generateNewMatch()
                        }}
                        variant={ButtonVariant.primary}
                        disabled={matchingState !== 'none'}
                        opacity={0}
                      >
                        {trans('screens.lastMinuteMatch.Join')}
                      </Button>
                    )}

                    {matchingState === 'none' && (
                      <View style={{ marginTop: 10, width: '100%' }}>
                        <Button
                          onPress={() => {
                            navigation.goBack()
                          }}
                          variant={ButtonVariant.disabled}
                        >
                          MEJOR EN OTRA OCACIÓN
                        </Button>
                      </View>
                    )}
                  </ButtonSetItem>
                </ButtonSet>
              </View>
            </ImageBackground>
          </SafeAreaView>
        )}

        {/* Success Modal */}

        {/*<Modal*/}
        {/*  visible={matchingState === 'matched'}*/}
        {/*  onRequestClose={() => goToMyMatches()}*/}
        {/*>*/}
        {/*  <AlertBox*/}
        {/*    type={'success'}*/}
        {/*    title={trans('screens.lastMinuteMatch.join_success_title')}*/}
        {/*    description={trans(*/}
        {/*      'screens.lastMinuteMatch.join_success_description'*/}
        {/*    )}*/}
        {/*  />*/}
        {/*  <SpacerVertical size={40} />*/}
        {/*  <ButtonSet>*/}
        {/*    <Button*/}
        {/*      variant={ButtonVariant.primary}*/}
        {/*      onPress={() => {*/}
        {/*        goToMyMatches()*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {trans('screens.lastMinuteMatch.My matches')}*/}
        {/*    </Button>*/}
        {/*  </ButtonSet>*/}
        {/*</Modal>*/}

        {/* Error Modal */}

        <Modal
          visible={matchingState === 'unavailable'}
          onRequestClose={() => goToLastMinute()}
        >
          <AlertBox
            type={'error'}
            title={trans('screens.lastMinuteMatch.join_error_title')}
            description={trans(
              'screens.lastMinuteMatch.join_error_description'
            )}
          />
          <SpacerVertical size={40} />
          <ButtonSet>
            <Button
              variant={ButtonVariant.primary}
              onPress={() => {
                goToLastMinute()
              }}
            >
              {trans('general.Close')}
            </Button>
          </ButtonSet>
        </Modal>
      </>
    )
  }

  return null
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      flexDirection: 'column',
      flex: 1,
      backgroundColor: theme.colors.greenDark,
    },
    title: {
      fontSize: 22,
      textAlign: 'center',
      fontFamily: theme.text.fontSecondary,
      color: 'white',
    },
    titleMatched: {
      fontSize: 22,
      textAlign: 'center',
      fontFamily: theme.text.fontSecondary,
      color: theme.colors.primary,
    },
    generatingLoader: {
      height: 90,
    },
    generatingDescription: {
      fontSize: 14,
      textAlign: 'center',
      fontFamily: theme.text.fontPrimary,
      color: theme.text.colorGrey,
    },
    teamAvatars: {
      flexDirection: 'row',
      justifyContent: 'center',
      marginTop: 5,
      marginBottom: 10,
    },
    disclaimer: {
      fontSize: 14,
      textAlign: 'center',
      fontFamily: theme.text.fontSecondary,
      color: '#62827A',
      marginHorizontal: 20,
      marginTop: 15,
      marginBottom: 25,
    },
    disclaimerMatched: {
      fontSize: 14,
      textAlign: 'center',
      fontFamily: theme.text.fontSecondary,
      color: '#62827A',
      marginHorizontal: 20,
    },
    footer: {
      paddingBottom: 20,
      bottom: 30,
      zIndex: 10,
    },
    top: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderColor: theme.colors.primary,
      borderTopLeftRadius: 25,
      borderTopRightRadius: 25,
      backgroundColor: theme.colors.primary,
      paddingHorizontal: 20,
      bottom: 30,
      marginHorizontal: -2,
      paddingTop: 15,
    },
    topContainer: {
      borderColor: theme.colors.primary,
      borderWidth: 2,
      zIndex: 5,
      backgroundColor: theme.colors.greenDark,
      paddingBottom: 50,
      marginTop: 20,
    },
    date: {
      color: theme.colors.greenDark,
      fontSize: 20,
      textAlign: 'center',
      flex: 1,
    },
  })
