import { LeagueGroup } from './LeagueGroup'

export class LeagueCategory {
  id: string
  /** masculino */
  name: string
  groups: LeagueGroup[]

  constructor(id: string, name: string, groups: LeagueGroup[]) {
    this.id = id
    this.name = name
    this.groups = groups
  }
}
