import * as React from 'react'
import {
  ColorValue,
  GestureResponderEvent,
  Text,
  TouchableHighlight,
} from 'react-native'
import { TextPropTypes } from '../../texts/TextPropTypesInterface'
import { defaultTextStylesByFontSize } from '../../texts/defaultTextStyles'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

interface Props extends TextPropTypes {
  onPress: (event: GestureResponderEvent) => void
  color?: ColorValue
}

export const LinkButton = ({
  style,
  center,
  onPress,
  children,
  color,
}: Props) => {
  const theme = defaultTheme

  const fontSize = style?.fontSize || theme.text.fontSize

  return (
    <TouchableHighlight underlayColor="rgba(73,182,77,1,0.9)" onPress={onPress}>
      <Text
        style={{
          ...defaultTextStylesByFontSize(theme, fontSize),
          color: color ?? theme.link.color,
          textDecorationLine: 'underline',
          textAlign: center ? 'center' : 'auto',
          ...style,
        }}
      >
        {children}
      </Text>
    </TouchableHighlight>
  )
}
