import * as React from 'react'
import { ColorValue, StyleSheet, Text, View } from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

interface Props {
  text: string
  color: ColorValue
  fontSize: number
}

export const Badge = ({ text, color, fontSize }: Props) => {
  const theme = defaultTheme

  fontSize = fontSize || theme.text.fontSize

  return (
    <View>
      <View
        style={{
          ...styles.container,
          backgroundColor: color,
          minHeight: fontSize * 1.6,
          minWidth: fontSize * 1.6,
          borderRadius: fontSize,
          paddingHorizontal: fontSize / 2,
          justifyContent: 'center',
        }}
      >
        <Text style={{ ...styles.text, fontSize }}>{text}</Text>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#F72E47',
    color: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
  },
})
