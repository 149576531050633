import * as React from 'react'
import { Theme } from '../../components/ui/themes/Theme'
import { themeReducer } from './themeReducer'
import { defaultTheme } from '../../components/ui/themes/default/defaultTheme'

interface ThemeContextProps {
  theme: Theme
  setTheme: (newTheme: Theme) => void
}

export const ThemeContext = React.createContext({} as ThemeContextProps)

export const useThemeContext = () => React.useContext(ThemeContext)

export const ThemeContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [theme, dispatch] = React.useReducer(themeReducer, defaultTheme)

  const setTheme = (newTheme: Theme) => {
    dispatch({ type: 'setTheme', payload: newTheme })
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}
