import * as React from 'react'
import { View } from 'react-native'
import { ThemeContext } from '../../../../contexts/theme/ThemeContext'
import { defaultTheme } from '../../themes/default/defaultTheme'

export enum ButtonSetAlign {
  center = 'center',
  left = 'flex-start',
  right = 'flex-end',
}

interface Props {
  children: React.ReactNode
  align?: ButtonSetAlign
}

export const ButtonSet = ({
  children,
  align = ButtonSetAlign.center,
}: Props) => {
  const theme = defaultTheme

  return (
    <View
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: align,
        marginHorizontal: -theme.grid.gap,
        alignItems: 'center',
      }}
    >
      {children}
    </View>
  )
}
