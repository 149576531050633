import * as React from 'react'
import { LinkButton } from '../../ui/buttons/LinkButton/LinkButton'
import { Linking, StyleSheet, View } from 'react-native'
import { ThemeContext } from '../../../contexts/theme/ThemeContext'
import { Theme } from '../../ui/themes/Theme'
import { translationService } from '../../../services/translationService'
import { useTranslation } from '../../../hooks/useTranslation'
import { P } from '../../ui/ui'
import { config } from '../../../config/config'
import * as Application from 'expo-application'
import { defaultTheme } from '../../ui/themes/default/defaultTheme'

translationService.addTranslations(
  'termsBottomLink',
  require('./TermsBottomLink.i18n.json')
)

const url: string = config.termsUrl

export const TermsBottomLink = () => {
  const theme = defaultTheme

  const styles = getStyles(theme)

  const { trans } = useTranslation()

  return (
    <View style={styles.container}>
      <P center style={{ color: 'white' }}>
        {trans('termsBottomLink.agree_notice_1')}
      </P>
      <LinkButton
        onPress={() => {
          Linking.canOpenURL(url).then((supported) => {
            if (supported) {
              Linking.openURL(url)
            }
          })
        }}
        style={styles.link}
      >
        {trans('termsBottomLink.agree_notice_2')}
      </LinkButton>
      <P style={styles.text}>{Application.nativeApplicationVersion}</P>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    container: {
      paddingBottom: 20,
    },
    text: {
      color: theme.text.color,
      textAlign: 'center',
      marginTop: 5,
    },
    link: {
      color: theme.colors.primary,
      textAlign: 'center',
    },
  })
