import { es } from 'date-fns/locale'
import { format as formatFn } from 'date-fns'

const locales = { es }

export const dateToHuman = (date: Date, format: string = 'd MMM') => {
  return formatFn(date, format, {
    locale: locales.es,
  })
}

export const dayOfTheWeekName = (date: Date) => {
  return formatFn(date, 'EEEE', {
    locale: locales.es,
  })
}

export const dateIntervalToHuman = (
  dateFrom: Date,
  dateTo: Date,
  format: string = 'dd/MMM/yyyy'
) => {
  const dateFromFormatted = formatFn(dateFrom, format, {
    locale: locales.es,
  })
  const dateToFormatted = formatFn(dateTo, format, { locale: locales.es })
  return `Del ${dateFromFormatted} al ${dateToFormatted}`
}

export const timeToHuman = (date: Date, format: string = 'H:mm') => {
  return formatFn(date, format, {
    locale: locales.es,
  })
}

export const isToday = (date: Date) => {
  const today = new Date()

  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  )
}
