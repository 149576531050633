import { registerRootComponent } from 'expo'
import App from './App'
import { startNetworkLogging } from 'react-native-network-logger'
import messaging from '@react-native-firebase/messaging'
import { prettyPrint } from './src/support/log'

// https://github.com/alexbrazier/react-native-network-logger
if (__DEV__) {
  startNetworkLogging({
    maxRequests: 200,
    ignoredHosts: ['localhost', '192.168.1.39'],
  })
} else {
  // Disable console.log
  console.log = () => {}
}

// Background & Quit state messages - https://rnfirebase.io/messaging/usage#background--quit-state-messages
// messaging().setBackgroundMessageHandler(async (remoteMessage) => {
//   console.log('FCM setBackgroundMessageHandler', prettyPrint(remoteMessage))
// })

registerRootComponent(App)
