import * as React from 'react'
import { StyleSheet, Text, View, ViewProps } from 'react-native'
import { ThemeContext } from '../../contexts/theme/ThemeContext'
import { Theme } from '../ui/themes/Theme'
import { defaultTheme } from '../ui/themes/default/defaultTheme'

type Props = ViewProps & {
  player1Name: string
  player2Name: string
}

export function PlayerNames({ player1Name, player2Name, ...props }: Props) {
  const theme = defaultTheme
  const styles = getStyles(theme)

  return (
    <View {...props}>
      <Text style={styles.playerName}>
        {player1Name} / {player2Name}
      </Text>
    </View>
  )
}

const getStyles = (theme: Theme) =>
  StyleSheet.create({
    playerName: {
      color: theme.colors.primary,
      fontFamily: theme.text.fontSecondary,
      fontSize: 12,
      textAlign: 'center',
    },
  })
