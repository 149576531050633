import { UserJson } from '../user/UserJson'
import { User } from '../user/User'
import { apiClient } from '../../support/apiClient'
import { AxiosResponse } from 'axios'

interface LoginResponse {
  player: UserJson
  auth_token: string
  refresh_token: string
}

interface LoginResult {
  user: User
  authToken: string
  refreshToken: string
}

export class AuthApi {
  static login(
    email: string,
    password: string,
    fcmToken: string
  ): Promise<LoginResult> {
    return apiClient
      .post(`/sign-in`, {
        email,
        password,
        fcmToken,
      })
      .then((axiosResponse: AxiosResponse<LoginResponse>) => {
        // console.log(`sign-in response`, axiosResponse)
        const response = axiosResponse.data
        const user = User.fromJson(response.player)
        return {
          user,
          authToken: response.auth_token,
          refreshToken: response.refresh_token,
        }
      })
  }

  static logout(): Promise<void> {
    return apiClient.post(`/logout`)
  }

  static recoverPassword(email: string): Promise<number> {
    return apiClient.post(`/passwords/reset_token?email=${email}`)
  }

  static checkToken(token: string): Promise<LoginResult> {
    return new Promise((resolve, reject) => {
      setTimeout((_) => {
        resolve({
          user: new User(
            'ecce860f-b3bd-4ff4-9772-d6586d3c0d55',
            'Natxo',
            'Lecumberri',
            'https://i.pravatar.cc/300?ecce860f-b3bd-4ff4-9772-d6586d3c0d55',
            'natxo@bake250.com',
            '+34 666 666 432',
            '1038',
            true
          ),
          authToken:
            '8934lmjkf9ksdflkndlhksdaljhñksafdlnkjsdavfklhsfdalkjhertuoiyw548kij3878o7',
          refreshToken:
            '8934lmjkf9ksdflkndlhksdaljhñksafdlnkjsdavfklhsfdalkjhertuoiyw548kij3878o7',
        })

        reject({})
      }, 500)
    })
  }
}
