import axios, { AxiosError } from 'axios'

// From https://github.com/axios/axios/issues/3612#issuecomment-770224236
export function extractApiError(error: Error | AxiosError | any): Error {
  if (axios.isAxiosError(error)) {
    // Access to config, request, and response
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const statusCode = error.response.status
      const symfonyErrorMessage = error.response.data.message
      return Error(`${statusCode} - ${symfonyErrorMessage}`)
    } else {
      return error
    }
  } else if (error instanceof Error) {
    // Just a stock error
    return error
  } else {
    // We must return an Error object, otherwise doing `if (result instanceof Error)` fails
    return Error(`${error}`)
  }
}
